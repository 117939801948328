import React from 'react';
import "./Login.css";
import axios from 'axios';
import { useFormik } from 'formik';
import { LoginSchema } from '../../schema/Index';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            userName: "",
            password: ""
        },
        validationSchema: LoginSchema,
        onSubmit: async (values, { resetForm }) => {
           
            const data = {
                email: values.userName,
                password: values.password
            };

            try {
                const res = await axios.post(`http://143.110.184.168:8089/kmAstroapp/auth/login`, data);
               
                if(res?.data?.length !== 0){
                    localStorage.setItem("accessToken",res.data.accessToken); 
                    navigate('/Home');
                }else{
                    toast.error("Login failed");
                    navigate('/login');
                }
                
            } catch (error) {
                toast.error("Login failed");
            }
            resetForm();
        },
    })

    return (
        <section className='section-login flex align-center justify-center'>
            <div className='login-container'>
                <form className='form' style={{ margin: "0px" }} onSubmit={handleSubmit}>
                    <div className='input-field flex flex-col'>
                        <label htmlFor="userName">User Name</label>
                        <input id='userName' type="text" name="userName" value={values.userName} placeholder='enter your userName' onChange={handleChange} onBlur={handleBlur} />
                        {touched.userName && errors.userName && <span className='error'>{errors.userName}</span>}
                    </div>
                    <div className='input-field flex flex-col'>
                        <label htmlFor="password">Password</label>
                        <input id='password' type="password" value={values.password} name="password" placeholder='enter your password' onChange={handleChange} onBlur={handleBlur} />
                        {touched.password && errors.password && <span className='error'>{errors.password}</span>}
                    </div>
                    <div className='buttons'>
                        <button type="submit" id='btn' className='btn'>Login</button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default Login;
