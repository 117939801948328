// export const API_END_POINT = 'http://143.110.184.168:8089/kmAstroapp/api/v1/'
export const API_END_POINT = 'http://astophase2.springdev.in/api/v1/'
// export const API_END_POINT = 'http://astophase2.springdev.in/api/v1/'

// server token
// let TOKEN = 'eyJhbGciOiJIUzI1NiJ9.eyJST0xFIjoiUk9MRV9VU0VSIiwiTE9HSU5fVFlQRSI6ImxvY2FsIiwiVVNFUk5BTUUiOiJLTVNAYXN0cm8iLCJVU0VSX0lEIjozNzYsInN1YiI6IjM3NiIsImlhdCI6MTcyMjU4NTYyMSwiZXhwIjoxNzg1NjU3NjIxfQ.h8OQbYDViQeaAPhht-q0RzrUivsHl_bVFeCrvAolK5c';

// local token
let TOKEN = 'eyJhbGciOiJIUzI1NiJ9.eyJST0xFIjoiUk9MRV9VU0VSIiwiTE9HSU5fVFlQRSI6ImxvY2FsIiwiVVNFUk5BTUUiOiI4NDU5MjQwNDI4IiwiVVNFUl9JRCI6NTMwNiwic3ViIjoiNTMwNiIsImlhdCI6MTcyNTM0NTgwOCwiZXhwIjoxNzg4NDE3ODA4fQ.1Lz98R2Wyu-2Q708JvFfeoTHMduhsk2CSgr1wtGCwIk';

export const getToken = () => {
    TOKEN = localStorage.getItem("accessToken");
};

export { TOKEN };