import React, { useState, useEffect } from 'react'
import "./CoursesConfiguration.css"
import CommonTable from '../../components/Table/CommonTable';
import axios from 'axios';
import { API_END_POINT, TOKEN } from '../../constant';
import { useFormik } from 'formik';
import { coursesConfigurationSchema } from '../../schema/Index';
import { toast } from 'react-toastify';
import Confirmation from '../../components/ConfirmationPopup/Confirmation';

const CoursesConfiguration = () => {
    const [initialValues, setInitialValues] = useState({
        courseSessionTitleEnglish: "",
        courseSessionTitleHindi: "",
        courseSessionTitleMarathi: "",
        courseSessionTitleGujarati: "",
        videoStreamLink: ""
    })

    const [btnName, setBtnName] = useState("Add");
    const [showForm, setShowForm] = useState(false);
    const [allCourses, setAllCourses] = useState([]);
    const [courseSessionListByCourseId, setCourseSessionListByCourseId] = useState([]);
    const [selectedCourseId, setSelectedCourseId] = useState(1);
    const [confirmUpdate, setConfirmUpdate] = useState(0);
    const [isConfirmationEdit, setIsConfirmationEdit] = useState(false);
    const [isConfirmationDelete, setIsConfirmationDelete] = useState(false);
    const [deleteContentId, setDeleteContentId] = useState(0)

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: coursesConfigurationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (btnName === "Save") {
                setIsConfirmationEdit(true)
            } else if (btnName === "Add") {
                await createCourseSession(values) 
                setShowForm(false);
                resetForm();
            }
            
        },
        enableReinitialize: true
    })

    const columns = [
        {
            name: <h3>Course Session Title In English</h3>,
            selector: (row) => <span title={row?.courseSessionTitle}>{row?.courseSessionTitle}</span>,
        },
        {
            name: <h3>Course Session Title In Hindi</h3>,
            selector: (row) => <span title={row?.courseSessionTitleInHindi}>{row?.courseSessionTitleInHindi}</span>,
        },
        {
            name: <h3>Course Session Title In Marathi</h3>,
            selector: (row) => <span title={row?.courseSessionTitleInMarathi}>{row?.courseSessionTitleInMarathi}</span>,
        },
        {
            name: <h3>Course Session Title In Gujarati</h3>,
            selector: (row) => <span title={row?.courseSessionTitleInGujarathi}>{row?.courseSessionTitleInGujarathi}</span>,
        },
        {
            name: <h3>Video Stream Link</h3>,
            selector: (row) => {
                return row?.videoStreamLink === null ? "-" : <button className='copy-url-btn' onClick={() => {
                    navigator.clipboard.writeText(row?.videoStreamLink)
                        .then(() => toast.success("URL copied to clipboard"))
                        .catch(() => toast.error("Failed to copy URL"));
                }} title={row?.videoStreamLink}>copy url</button>
            },
        },
        {
            name: 'Actions',
            cell: (row) => (
                <div className='flex align-center gap-10'>
                    <button className='flex align-center edit-btn' onClick={() => updateCourseSessions(row.id)}>
                        <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m7 17.011 4.413-.015 9.632-9.54c.378-.378.586-.88.586-1.414 0-.534-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.581v4.43ZM18.045 4.456l1.589 1.583-1.597 1.582-1.586-1.585 1.594-1.58ZM9 13.416l6.03-5.974 1.586 1.586L10.587 15 9 15.004v-1.589Z"></path>
                            <path d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2Z"></path>
                        </svg>
                    </button>
                    <button className='flex align-center delete-btn-icon' onClick={()=>deleteCourse(row.id)}>
                        <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2v12ZM9 4h6v2H9V4ZM8 8h9v12H7V8h1Z"></path>
                            <path d="M9 10h2v8H9v-8Zm4 0h2v8h-2v-8Z"></path>
                        </svg>
                    </button>
                </div>
            ),
        },

    ]

    // add new course
    const addNewCourse = () => {
        btnName === "Save" && setBtnName("Add");
        setShowForm(!showForm);
        setInitialValues({
            courseSessionTitle: "",
            videoStreamLink: ""
        })
    }

    // handle on change
    const handleOnChange = async (event) => {
        const selectedOptionId = event.target.options[event.target.selectedIndex].id;
        setSelectedCourseId(Number(selectedOptionId));
        setCourseSessionListByCourseId(await getCourseSessionListByCourseId(Number(selectedOptionId)));
    }

    // create new Course session mwthod 
    const createCourseSession = async (values) => {
        try {

            // Set request headers
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`
            };

            // Define request body
            const data = {
                actionInfo: {
                    actionId: "String"
                },
                formData: {
                    courseSessionTitle: values?.courseSessionTitleEnglish,
                    courseSessionTitleInHindi: values?.courseSessionTitleHindi,
                    courseSessionTitleInMarathi: values?.courseSessionTitleMarathi,
                    courseSessionTitleInGujarathi: values?.courseSessionTitleGujarati,
                    courseSessionDescription: "",
                    onlineDuration: 0,
                    offlineDuration: 0,
                    courseSessionVideoLength: 0,
                    videoSessionLink: values?.videoStreamLink,
                    courseId: selectedCourseId,
                },
                sessionData: {
                    userDetails: {
                        isAdmin: 0,
                        isAllAccess: 0,
                        langCode: "String",
                        userEmailId: "String",
                        userId: 0,
                        userName: "String"
                    }
                }
            };

            // Make POST request using Axios
            await axios.post(`${API_END_POINT}courseSession/create`, data, {
                headers
            });
            setCourseSessionListByCourseId(await getCourseSessionListByCourseId(selectedCourseId))
            toast.success("Course Added successfully");
        } catch (error) {
            toast.error("Unable To Add Course");
        }
    };


    // Updates a magazine by its ID.
    const updateCourseSessions = async (id) => {
        setBtnName("Save");
        let previousData = courseSessionListByCourseId.find((data) => data.id === id);
        setInitialValues({
            courseSessionTitleEnglish: previousData?.courseSessionTitle,
            courseSessionTitleHindi: previousData?.courseSessionTitleInHindi,
            courseSessionTitleMarathi: previousData?.courseSessionTitleInMarathi,
            courseSessionTitleGujarati: previousData?.courseSessionTitleInGujarathi,
            videoStreamLink: previousData?.videoStreamLink
        })
        setShowForm(true);
        setConfirmUpdate(id);
    };

    // update Courses sessions by id here
    const confirmUpdateCourseSessions = async (info) => {
        try {

            // Set request headers
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`
            };

            // Define request body
            const data = {
                actionInfo: {
                    actionId: "String"
                },
                formData: {
                    courseSessionTitle: info?.courseSessionTitleEnglish,
                    courseSessionTitleInHindi: info?.courseSessionTitleHindi,
                    courseSessionTitleInMarathi: info?.courseSessionTitleMarathi,
                    courseSessionTitleInGujarathi: info?.courseSessionTitleGujarati,
                    courseSessionDescription: "",
                    videoSessionLink: info?.videoStreamLink                    
                },
                sessionData: {
                    userDetails: {
                        isAdmin: 0,
                        isAllAccess: 0,
                        langCode: "String",
                        userEmailId: "String",
                        userId: 0,
                        userName: "String"
                    }
                }
            };

            // Make PUT request using Axios
           await axios.put(`${API_END_POINT}courseSession/updateCourseSessions?courseSessionId=${confirmUpdate}`, data, {
                headers
            });

            setInitialValues({
                courseSessionTitleEnglish: "",
                courseSessionTitleHindi: "",
                courseSessionTitleMarathi: "",
                courseSessionTitleGujarati: "",
                videoStreamLink: ""
            })
            setShowForm(false);
            setCourseSessionListByCourseId(await getCourseSessionListByCourseId(selectedCourseId))
            toast.success("Course Updated successfully");

        } catch (error) {
            toast.error("Unable To Update Course");
        }
    };

    // get all courses for dropdown
    const getCourses = async () => {
        try {
            // Set request headers
            const headers = {
                Authorization: `Bearer ${TOKEN}`
            };

            // Make GET request using Axios
            const response = await axios.get(`${API_END_POINT}course/getCourses`, { headers });

            return response?.data?.data;
        } catch (error) {
            console.error(error); // Log any errors that occur
        }
    };

    // get course session list by courseid
    const getCourseSessionListByCourseId = async (id) => {
        try {
            // Set request headers
            const headers = {
                Authorization: `Bearer ${TOKEN}`
            };

            // Make GET request using Axios
            const response = await axios.get(`${API_END_POINT}courseSession/getCourseSessionListByCourseIdInAdmin?courseId=${id}&pageSize=2000&pageNo=0`, { headers });

            return response?.data?.data // Log the response data
        } catch (error) {
            console.error(error); // Log any errors that occur
        }

    };

    // ----- this is for close confirm Edit popup -----
    const closeConfirmEditPopUp = ()=>{
        setIsConfirmationEdit(false)
    }

    // Deletes a magazine by its ID.
    const deleteCourse = async (id) => {
        setIsConfirmationDelete(true);
        setDeleteContentId(id);
    };

    // Delete Action confirm popup
    const confirmActionDelete = async (data)=>{
        try {
            // Define request headers
            const headers = {
                Authorization: `Bearer ${TOKEN}`
            };

            // Make DELETE request using Axios
            await axios.delete(`${API_END_POINT}courseSession/session/delete/${deleteContentId}`, {
                headers
            });
            setCourseSessionListByCourseId(await getCourseSessionListByCourseId(selectedCourseId))
            toast.success("Course Deleted successfully");

        } catch (error) {
            toast.error("Unable To Delete Course");
        }
    }

     // ----- this is for close confirm delete popup -----
     const closeConfirmDeletePopUp = () => {
        setIsConfirmationDelete(false)
    }

    useEffect(() => {
        async function fetchData() {
            setAllCourses(await getCourses());
            setCourseSessionListByCourseId(await getCourseSessionListByCourseId(1))
        }
        fetchData();

    }, [])
    return (
        <main className='main'>
            <section className='section'>
                <h3 className='heding' style={{ marginBottom: "10px" }}>Courses Configuration</h3>
                <div style={{ marginTop: "10px" }} className='flex flex-wrap gap-5 align-center justify-between'>
                    <div className='form'>
                        <div className='input-field flex'>
                            <select name="dropdown" onChange={handleOnChange}>
                                {
                                    allCourses?.map((course, index) => {
                                        return (
                                            <option key={index} id={course.id} value={course?.courseTitle}>{course?.courseTitle}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <button onClick={addNewCourse} className='common-btn flex align-center'>
                        {!showForm ? <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6v-2Z"></path>
                        </svg> :
                            <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242-1.414-1.414Z"></path>
                            </svg>}
                    </button>
                </div>
                {showForm && (
                    <form onSubmit={handleSubmit} className='flex flex-wrap aign-center gap-5 gemstoneConfigurationForm form'>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="courseSessionTitleEnglish">Course Title in English</label>
                            <input
                                id='courseSessionTitleEnglish'
                                type="text"
                                name="courseSessionTitleEnglish"
                                value={values.courseSessionTitleEnglish}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                lang='en'
                                placeholder='Enter course title in English'
                                className={`${(errors.courseSessionTitleEnglish && touched.courseSessionTitleEnglish) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="courseSessionTitleHindi">Course Title in Hindi</label>
                            <input
                                id='courseSessionTitleHindi'
                                type="text"
                                name="courseSessionTitleHindi"
                                value={values.courseSessionTitleHindi}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                lang='hi'
                                placeholder='Enter course title in Hindi'
                                className={`${(errors.courseSessionTitleHindi && touched.courseSessionTitleHindi) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="courseSessionTitleMarathi">Course Title in Marathi</label>
                            <input
                                id='courseSessionTitleMarathi'
                                type="text"
                                name="courseSessionTitleMarathi"
                                value={values.courseSessionTitleMarathi}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                lang='mr'
                                placeholder='Enter course title in Marathi'
                                className={`${(errors.courseSessionTitleMarathi && touched.courseSessionTitleMarathi) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="courseSessionTitleGujarati">Course Title in Gujarati</label>
                            <input
                                id='courseSessionTitleGujarati'
                                type="text"
                                name="courseSessionTitleGujarati"
                                value={values.courseSessionTitleGujarati}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                lang='gu'
                                placeholder='Enter course Title in Gujarati'
                                className={`${(errors.courseSessionTitleGujarati && touched.courseSessionTitleGujarati) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="videoStreamLink">Video Stream Link</label>
                            <input
                                id='videoStreamLink'
                                type="text"
                                name="videoStreamLink"
                                value={values.videoStreamLink}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                placeholder='paste video stream link here'
                                className={`${(errors.videoStreamLink && touched.videoStreamLink) && 'error'}`}
                            />
                        </div>
                        <div className='buttons flex flex-col' style={{ alignItems: "flex-end" }}>
                            <label htmlFor="btn" style={{ visibility: "hidden", marginBottom: "5px" }}>hidden</label>
                            <button type="submit" className='btn' id='btn' style={{ width: "100%" }}>{btnName}</button>
                        </div>
                    </form>
                )}
                <div style={{ marginTop: "10px" }}>
                    <CommonTable data={courseSessionListByCourseId} columns={columns} />
                </div>
            </section>


             {/* ----- confirmation Delete popup --- */}
            <Confirmation text={"Do you want to delete this Course"} confirmAction={confirmActionDelete} closeConfirmPopUp={closeConfirmDeletePopUp} isConfirmationOpen={isConfirmationDelete} />

            {/* ----- confirmation Edit popup --- */}
            <Confirmation text={"Do you want to Save this Courses"} confirmAction={confirmUpdateCourseSessions} closeConfirmPopUp={closeConfirmEditPopUp} isConfirmationOpen={isConfirmationEdit} data={values}/>
        </main>
    )
}

export default CoursesConfiguration