import React, { useEffect, useState } from 'react'
import './Analytics.css'
import BoxAnalytics from '../../components/BoxAnalytics/BoxAnalytics.jsx'
import { API_END_POINT, TOKEN } from '../../constant/index.jsx'
import axios from 'axios'
import Loader from '../../components/Loader/Loader.jsx'
import BirthChartTable from '../../components/BirthChart/BirthChartTable.jsx'

function Analytics() {
  const [dataBirthChart, setDataBirthChart] = useState(null);
  const [dataConsultation, setDataConsultation] = useState(null);
  const [dataPersonalizedReport, setDataPersonalizedReport] = useState(null);
  const [dataAstroClock, setDataAstroClock] = useState(null);
  const [dataKundali, setKundali] = useState(null);
  const [dataYoga, setYoga] = useState(null);
  const [dataMaleficYoga, setMaleficYoga] = useState(null);
  const [dataTimeIndicator, setTimeIndicator] = useState(null);
  const [dataGoodTime, setGoodTime] = useState(null);
  const [dataChallengingTime, setChallengingTime] = useState(null);
  const [dataLearnAstro, setLearnAstro] = useState(null);
  const [dataAstroShop, setAstroShop] = useState(null);
  const [dataTransit, setTransit] = useState(null);
  const [dataPanchang, setPanchang] = useState(null);
  const [dataPoojaModule, setPoojaModule] = useState(null);
  const [dataFullReport, setDataFullReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showBirthChartTable, setShowBirthChartTable] = useState(false);
  const [showConsultationTable, setShowConsultationTable] = useState(false);
  const [showPersonalizedTable, setShowPersonalizedTable] = useState(false);
  const [showAstroClockTable, setShowAstroClockTable] = useState(false);
  const [showKundaliTable, setShowKundaliTable] = useState(false);
  const [showYogaTable, setShowYogaTable] = useState(false);
  const [showMaleficYogaTable, setShowMaleficYogaTable] = useState(false);
  const [showTimeIndicatorTable, setShowTimeIndicatorTable] = useState(false);
  const [showGoodTimeTable, setShowGoodTimeTable] = useState(false);
  const [showChallengingTimeTable, setShowChallengingTimeTable] = useState(false);
  const [showLearnAstroTable, setShowLearnAstroTable] = useState(false);
  const [showAstroShopTable, setShowAstroShopTable] = useState(false);
  const [showTransitTable, setShowTransitTable] = useState(false);
  const [showPanchangTable, setShowPanchangTable] = useState(false);
  const [showPoojaModuleTable, setShowPoojaModuleTable] = useState(false);
  const [showFullReportTable, setShowFullReportTable] = useState(false);
  const [selectedItem, setSelctedItem] = useState(null)

  const fetchData = async () => {
    const headers = {
      Authorization: `Bearer ${TOKEN}`,
    }
    try {
      setLoading(true)
      const response1 = await axios.get(`${API_END_POINT}userMenuSubMenuMapper/getCountByMenu?menuId=1`, { headers })
      setDataConsultation(response1?.data?.data);
      const response2 = await axios.get(`${API_END_POINT}userMenuSubMenuMapper/getCountByMenu?menuId=2`, { headers })
      setDataPersonalizedReport(response2?.data?.data)
      const response3 = await axios.get(`${API_END_POINT}userMenuSubMenuMapper/getCountByMenu?menuId=3`, { headers })
      setDataFullReport(response3?.data?.data)
      const response4 = await axios.get(`${API_END_POINT}userMenuSubMenuMapper/getCountByMenu?menuId=4`, { headers })
      setDataBirthChart(response4?.data?.data);
      const response5 = await axios.get(`${API_END_POINT}userMenuSubMenuMapper/getCountByMenu?menuId=5`, { headers })
      setDataAstroClock(response5?.data?.data)
      const response6 = await axios.get(`${API_END_POINT}userMenuSubMenuMapper/getCountByMenu?menuId=6`, { headers })
      setKundali(response6?.data?.data)
      const response7 = await axios.get(`${API_END_POINT}userMenuSubMenuMapper/getCountByMenu?menuId=7`, { headers })
      setYoga(response7?.data?.data)
      const response8 = await axios.get(`${API_END_POINT}userMenuSubMenuMapper/getCountByMenu?menuId=8`, { headers })
      setMaleficYoga(response8?.data?.data)
      const response9 = await axios.get(`${API_END_POINT}userMenuSubMenuMapper/getCountByMenu?menuId=9`, { headers })
      setTimeIndicator(response9?.data?.data)
      const response10 = await axios.get(`${API_END_POINT}userMenuSubMenuMapper/getCountByMenu?menuId=10`, { headers })
      setGoodTime(response10?.data?.data)
      const response11 = await axios.get(`${API_END_POINT}userMenuSubMenuMapper/getCountByMenu?menuId=11`, { headers })
      setChallengingTime(response11?.data?.data)
      const response12 = await axios.get(`${API_END_POINT}userMenuSubMenuMapper/getCountByMenu?menuId=12`, { headers })
      setLearnAstro(response12?.data?.data)
      const response13 = await axios.get(`${API_END_POINT}userMenuSubMenuMapper/getCountByMenu?menuId=13`, { headers })
      setAstroShop(response13?.data?.data)
      const response14 = await axios.get(`${API_END_POINT}userMenuSubMenuMapper/getCountByMenu?menuId=14`, { headers })
      setTransit(response14?.data?.data)
      const response15 = await axios.get(`${API_END_POINT}userMenuSubMenuMapper/getCountByMenu?menuId=15`, { headers })
      setPanchang(response15?.data?.data)
      const response16 = await axios.get(`${API_END_POINT}userMenuSubMenuMapper/getCountByMenu?menuId=16`, { headers })
      setPoojaModule(response16?.data?.data)
      setLoading(false)
    }
    catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const viewYourBirthChartList = (viewListText, setViewListText, subMenuName) => {
    console.log(subMenuName, "dfkjdfgkdfjk");
    setSelctedItem(subMenuName)
    viewListText === "View List" ? setShowBirthChartTable(true) : setShowBirthChartTable(false)
    viewListText === "View List" ? setViewListText("Close List") : setViewListText("View List")
  }

  const viewConsultationList = (viewListText, setViewListText, subMenuName) => {
    console.log(subMenuName, "dfkjdfgkdfjk");
    setSelctedItem(subMenuName)
    viewListText === "View List" ? setShowConsultationTable(true) : setShowConsultationTable(false)
    viewListText === "View List" ? setViewListText("Close List") : setViewListText("View List")
  }

  const viewPersonalizedReportList = (viewListText, setViewListText, subMenuName) => {
    console.log(subMenuName, "dfkjdfgkdfjk");
    setSelctedItem(subMenuName)
    viewListText === "View List" ? setShowPersonalizedTable(true) : setShowPersonalizedTable(false)
    viewListText === "View List" ? setViewListText("Close List") : setViewListText("View List")
  }

  const viewAstroClockList = (viewListText, setViewListText, subMenuName) => {
    console.log(subMenuName, "dfkjdfgkdfjk");
    setSelctedItem(subMenuName)
    viewListText === "View List" ? setShowAstroClockTable(true) : setShowAstroClockTable(false)
    viewListText === "View List" ? setViewListText("Close List") : setViewListText("View List")
  }

  const viewKundaliList = (viewListText, setViewListText, subMenuName) => {
    console.log(subMenuName, "dfkjdfgkdfjk");
    setSelctedItem(subMenuName)
    viewListText === "View List" ? setShowKundaliTable(true) : setShowKundaliTable(false)
    viewListText === "View List" ? setViewListText("Close List") : setViewListText("View List")
  }

  const viewYogaList = (viewListText, setViewListText, subMenuName) => {
    console.log(subMenuName, "dfkjdfgkdfjk");
    setSelctedItem(subMenuName)
    viewListText === "View List" ? setShowYogaTable(true) : setShowYogaTable(false)
    viewListText === "View List" ? setViewListText("Close List") : setViewListText("View List")
  }

  const viewMaleficYogaList = (viewListText, setViewListText, subMenuName) => {
    console.log(subMenuName, "dfkjdfgkdfjk");
    setSelctedItem(subMenuName)
    viewListText === "View List" ? setShowMaleficYogaTable(true) : setShowMaleficYogaTable(false)
    viewListText === "View List" ? setViewListText("Close List") : setViewListText("View List")
  }

  const viewTimeIndicatorYogaList = (viewListText, setViewListText, subMenuName) => {
    console.log(subMenuName, "dfkjdfgkdfjk");
    setSelctedItem(subMenuName)
    viewListText === "View List" ? setShowTimeIndicatorTable(true) : setShowTimeIndicatorTable(false)
    viewListText === "View List" ? setViewListText("Close List") : setViewListText("View List")
  }

  const viewGoodTimeList = (viewListText, setViewListText, subMenuName) => {
    console.log(subMenuName, "dfkjdfgkdfjk");
    setSelctedItem(subMenuName)
    viewListText === "View List" ? setShowGoodTimeTable(true) : setShowGoodTimeTable(false)
    viewListText === "View List" ? setViewListText("Close List") : setViewListText("View List")
  }

  const viewChallengingTimeList = (viewListText, setViewListText, subMenuName) => {
    console.log(subMenuName, "dfkjdfgkdfjk");
    setSelctedItem(subMenuName)
    viewListText === "View List" ? setShowChallengingTimeTable(true) : setShowChallengingTimeTable(false)
    viewListText === "View List" ? setViewListText("Close List") : setViewListText("View List")
  }

  const viewLearnAstroList = (viewListText, setViewListText, subMenuName) => {
    console.log(subMenuName, "dfkjdfgkdfjk");
    setSelctedItem(subMenuName)
    viewListText === "View List" ? setShowLearnAstroTable(true) : setShowLearnAstroTable(false)
    viewListText === "View List" ? setViewListText("Close List") : setViewListText("View List")
  }

  const viewAstroShopList = (viewListText, setViewListText, subMenuName) => {
    console.log(subMenuName, "dfkjdfgkdfjk");
    setSelctedItem(subMenuName)
    viewListText === "View List" ? setShowAstroShopTable(true) : setShowAstroShopTable(false)
    viewListText === "View List" ? setViewListText("Close List") : setViewListText("View List")
  }

  const viewTransitList = (viewListText, setViewListText, subMenuName) => {
    console.log(subMenuName, "dfkjdfgkdfjk");
    setSelctedItem(subMenuName)
    viewListText === "View List" ? setShowTransitTable(true) : setShowTransitTable(false)
    viewListText === "View List" ? setViewListText("Close List") : setViewListText("View List")
  }
  const viewPanchangList = (viewListText, setViewListText, subMenuName) => {
    console.log(subMenuName, "dfkjdfgkdfjk");
    setSelctedItem(subMenuName)
    viewListText === "View List" ? setShowPanchangTable(true) : setShowPanchangTable(false)
    viewListText === "View List" ? setViewListText("Close List") : setViewListText("View List")
  }
  const viewPoojaModuleList = (viewListText, setViewListText, subMenuName) => {
    console.log(subMenuName, "dfkjdfgkdfjk");
    setSelctedItem(subMenuName)
    viewListText === "View List" ? setShowPoojaModuleTable(true) : setShowPoojaModuleTable(false)
    viewListText === "View List" ? setViewListText("Close List") : setViewListText("View List")
  }

  const viewFullReportList = (viewListText, setViewListText, subMenuName) => {
    console.log(subMenuName, "dfkjdfgkdfjk");
    setSelctedItem(subMenuName)
    viewListText === "View List" ? setShowFullReportTable(true) : setShowFullReportTable(false)
    viewListText === "View List" ? setViewListText("Close List") : setViewListText("View List")
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <main className='main'>
      {
        !loading && dataBirthChart ?
          <div className='flex flex-col'>
            <div className='first-section'>
              <h3 className='heding'>{dataConsultation?.menuName}</h3>
              <div className='main-box content'>
                {
                  dataConsultation?.subMenuAnalytics.map((item, key) => (
                    <div key={key} className="common-container">
                      <BoxAnalytics
                        subMenuName={item?.subMenuName}
                        totalCount={item?.totalCount}
                        todayCount={item?.todayCount}
                        viewList={viewConsultationList}
                      />
                      {
                        showConsultationTable && (item?.subMenuName === selectedItem) && (
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ marginBottom: "6px" }}>
                              <h3 className='heding'>{item?.subMenuName} List</h3>
                            </div>
                            <BirthChartTable data={item?.userList} />
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>


            </div>
            <div className='first-section'>
              <h3 className='heding'>{dataPersonalizedReport?.menuName}</h3>
              <div className='main-box content'>
                {
                  dataPersonalizedReport?.subMenuAnalytics.map((item, key) => (
                    <div key={key}>
                      <BoxAnalytics
                        subMenuName={item?.subMenuName}
                        totalCount={item?.totalCount}
                        todayCount={item?.todayCount}
                        viewList={viewPersonalizedReportList}
                      />
                      {
                        showPersonalizedTable && (item?.subMenuName === selectedItem) && (
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ marginBottom: "6px" }}>
                              <h3 className='heding'>{item?.subMenuName} List</h3>
                            </div>
                            <BirthChartTable data={item?.userList} />
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>

            </div>
            <div className='first-section'>
              <h3 className='heding'>{dataFullReport?.menuName}</h3>
              <div className='main-box content'>
                {
                  dataFullReport?.subMenuAnalytics.map((item, key) => (
                    <div key={key}>
                      <BoxAnalytics
                        subMenuName={item?.subMenuName}
                        totalCount={item?.totalCount}
                        todayCount={item?.todayCount}
                        viewList={viewFullReportList}
                      />
                      {
                        showFullReportTable && (item?.subMenuName === selectedItem) && (
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ marginBottom: "6px" }}>
                              <h3 className='heding'>{item?.subMenuName} List</h3>
                            </div>
                            <BirthChartTable data={item?.userList} />
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
            <div className='first-section'>
              <h3 className='heding'>{dataBirthChart?.menuName}</h3>
              <div className='main-box content'>
                {
                  dataBirthChart?.subMenuAnalytics.map((item, key) => (
                    <div key={key}>
                      <BoxAnalytics
                        subMenuName={item?.subMenuName}
                        totalCount={item?.totalCount}
                        todayCount={item?.todayCount}
                        viewList={viewYourBirthChartList}
                      />
                      {
                        showBirthChartTable && (item?.subMenuName === selectedItem) && (
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ marginBottom: "6px" }}>
                              <h3 className='heding'>{item?.subMenuName} List</h3>
                            </div>
                            <BirthChartTable data={item?.userList} />
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
            <div className='first-section'>
              <h3 className='heding'>{dataAstroClock?.menuName}</h3>
              <div className='main-box content'>
                {
                  dataAstroClock?.subMenuAnalytics.map((item, key) => (
                    <div key={key}>
                      <BoxAnalytics
                        subMenuName={item?.subMenuName}
                        totalCount={item?.totalCount}
                        todayCount={item?.todayCount}
                        viewList={viewAstroClockList}
                      />
                      {
                        showAstroClockTable && (item?.subMenuName === selectedItem) && (
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ marginBottom: "6px" }}>
                              <h3 className='heding'>{item?.subMenuName} List</h3>
                            </div>
                            <BirthChartTable data={item?.userList} />
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
            <div className='first-section'>
              <h3 className='heding'>{dataKundali?.menuName}</h3>
              <div className='main-box content'>
                {
                  dataKundali?.subMenuAnalytics.map((item, key) => (
                    <div key={key}>
                      <BoxAnalytics
                        subMenuName={item?.subMenuName}
                        totalCount={item?.totalCount}
                        todayCount={item?.todayCount}
                        viewList={viewKundaliList}
                      />
                      {
                        showKundaliTable && (item?.subMenuName === selectedItem) && (
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ marginBottom: "6px" }}>
                              <h3 className='heding'>{item?.subMenuName} List</h3>
                            </div>
                            <BirthChartTable data={item?.userList} />
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
            <div className='first-section'>
              <h3 className='heding'>{dataYoga?.menuName}</h3>
              <div className='main-box content'>
                {
                  dataYoga?.subMenuAnalytics.map((item, key) => (
                    <div key={key}>
                      <BoxAnalytics
                        subMenuName={item?.subMenuName}
                        totalCount={item?.totalCount}
                        todayCount={item?.todayCount}
                        viewList={viewYogaList}
                      />
                      {
                        showYogaTable && (item?.subMenuName === selectedItem) && (
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ marginBottom: "6px" }}>
                              <h3 className='heding'>{item?.subMenuName} List</h3>
                            </div>
                            <BirthChartTable data={item?.userList} />
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
            <div className='first-section'>
              <h3 className='heding'>{dataMaleficYoga?.menuName}</h3>
              <div className='main-box content'>
                {
                  dataMaleficYoga?.subMenuAnalytics.map((item, key) => (
                    <div key={key} >
                      <BoxAnalytics
                        subMenuName={item?.subMenuName}
                        totalCount={item?.totalCount}
                        todayCount={item?.todayCount}
                        viewList={viewMaleficYogaList}
                        className="full-width"
                      />
                      {
                        showMaleficYogaTable && (item?.subMenuName === selectedItem) && (
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ marginBottom: "6px" }}>
                              <h3 className='heding'>{item?.subMenuName} List</h3>
                            </div>
                            <BirthChartTable data={item?.userList} className="full-width" />
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
            <div className='first-section'>
              <h3 className='heding'>{dataTimeIndicator?.menuName}</h3>
              <div className='main-box content'>
                {
                  dataTimeIndicator?.subMenuAnalytics.map((item, key) => (
                    <div key={key}>
                      <BoxAnalytics
                        subMenuName={item?.subMenuName}
                        totalCount={item?.totalCount}
                        todayCount={item?.todayCount}
                        viewList={viewTimeIndicatorYogaList}
                      />
                      {
                        showTimeIndicatorTable && (item?.subMenuName === selectedItem) && (
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ marginBottom: "6px" }}>
                              <h3 className='heding'>{item?.subMenuName} List</h3>
                            </div>
                            <BirthChartTable data={item?.userList} />
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
            <div className='first-section'>
              <h3 className='heding'>{dataGoodTime?.menuName}</h3>
              <div className='main-box content'>
                {
                  dataGoodTime?.subMenuAnalytics.map((item, key) => (
                    <div key={key}>
                      <BoxAnalytics
                        subMenuName={item?.subMenuName}
                        totalCount={item?.totalCount}
                        todayCount={item?.todayCount}
                        viewList={viewGoodTimeList}
                      />
                      {
                        showGoodTimeTable && (item?.subMenuName === selectedItem) && (
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ marginBottom: "6px" }}>
                              <h3 className='heding'>{item?.subMenuName} List</h3>
                            </div>
                            <BirthChartTable data={item?.userList} />
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
            <div className='first-section'>
              <h3 className='heding'>{dataChallengingTime?.menuName}</h3>
              <div className='main-box content'>
                {
                  dataChallengingTime?.subMenuAnalytics.map((item, key) => (
                    <div key={key}>
                      <BoxAnalytics
                        subMenuName={item?.subMenuName}
                        totalCount={item?.totalCount}
                        todayCount={item?.todayCount}
                        viewList={viewChallengingTimeList}
                      />
                      {
                        showChallengingTimeTable && (item?.subMenuName === selectedItem) && (
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ marginBottom: "6px" }}>
                              <h3 className='heding'>{item?.subMenuName} List</h3>
                            </div>
                            <BirthChartTable data={item?.userList} />
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
            <div className='first-section'>
              <h3 className='heding'>{dataLearnAstro?.menuName}</h3>
              <div className='main-box content'>
                {
                  dataLearnAstro?.subMenuAnalytics.map((item, key) => (
                    <div key={key}>
                      <BoxAnalytics
                        subMenuName={item?.subMenuName}
                        totalCount={item?.totalCount}
                        todayCount={item?.todayCount}
                        viewList={viewLearnAstroList}
                      />
                      {
                        showLearnAstroTable && (item?.subMenuName === selectedItem) && (
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ marginBottom: "6px" }}>
                              <h3 className='heding'>{item?.subMenuName} List</h3>
                            </div>
                            <BirthChartTable data={item?.userList} />
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
            <div className='first-section'>
              <h3 className='heding'>{dataAstroShop?.menuName}</h3>
              <div className='main-box content'>
                {
                  dataAstroShop?.subMenuAnalytics.map((item, key) => (
                    <div key={key}>
                      <BoxAnalytics
                        subMenuName={item?.subMenuName}
                        totalCount={item?.totalCount}
                        todayCount={item?.todayCount}
                        viewList={viewAstroShopList}
                      />
                      {
                        showAstroShopTable && (item?.subMenuName === selectedItem) && (
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ marginBottom: "6px" }}>
                              <h3 className='heding'>{item?.subMenuName} List</h3>
                            </div>
                            <BirthChartTable data={item?.userList} />
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
            <div className='first-section'>
              <h3 className='heding'>{dataTransit?.menuName}</h3>
              <div className='main-box content'>
                {
                  dataTransit?.subMenuAnalytics.map((item, key) => (
                    <div key={key}>
                      <BoxAnalytics
                        subMenuName={item?.subMenuName}
                        totalCount={item?.totalCount}
                        todayCount={item?.todayCount}
                        viewList={viewTransitList}
                      />
                      {
                        showTransitTable && (item?.subMenuName === selectedItem) && (
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ marginBottom: "6px" }}>
                              <h3 className='heding'>{item?.subMenuName} List</h3>
                            </div>
                            <BirthChartTable data={item?.userList} />
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
            <div className='first-section'>
              <h3 className='heding'>{dataPanchang?.menuName}</h3>
              <div className='main-box content'>
                {
                  dataPanchang?.subMenuAnalytics.map((item, key) => (
                    <div key={key}>
                      <BoxAnalytics
                        subMenuName={item?.subMenuName}
                        totalCount={item?.totalCount}
                        todayCount={item?.todayCount}
                        viewList={viewPanchangList}
                      />
                      {
                        showPanchangTable && (item?.subMenuName === selectedItem) && (
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ marginBottom: "6px" }}>
                              <h3 className='heding'>{item?.subMenuName} List</h3>
                            </div>
                            <BirthChartTable data={item?.userList} />
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
            <div className='last-section'>
              <h3 className='heding'>{dataPoojaModule?.menuName}</h3>
              <div className='main-box content'>
                {
                  dataPoojaModule?.subMenuAnalytics.map((item, key) => (
                    <div key={key}>
                      <BoxAnalytics
                        subMenuName={item?.subMenuName}
                        totalCount={item?.totalCount}
                        todayCount={item?.todayCount}
                        viewList={viewPoojaModuleList}
                      />
                      {
                        showPoojaModuleTable && (item?.subMenuName === selectedItem) && (
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ marginBottom: "6px" }}>
                              <h3 className='heding'>{item?.subMenuName} List</h3>
                            </div>
                            <BirthChartTable data={item?.userList} />
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
          </div> : null
      }
      {loading && <Loader />}
    </main >
  )
}

export default Analytics

