import React, { useEffect, useState } from 'react'
import "./Sidebar.css"
import { NavLink } from 'react-router-dom'
import logo from "../../assets/sidebar_assets/AppLogoUnitGrayTheme.svg"
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const Sidebar = () => {
  const [isMenuOpen, setisMenuOpen] = useState(false)
  const [isMenuOpen2, setisMenuOpen2] = useState(false)

  const closeSideBar = () => {
    const body = document.querySelector('body');
    body.classList.remove("handleHamburger")
  }

  const logOut = () => {
    localStorage.clear();
  }

  const toogleMenu = () => {
    setisMenuOpen(!isMenuOpen)
  }

  const toogleMenu2 = () => {
    setisMenuOpen2(!isMenuOpen2)
  }


  return (
    <div className='sidebar flex flex-col'>
      <div className="logo flex align-center">
        <img src={logo} alt="logo" />
      </div>
      <ul className='sidebar-menu-list'>
        <li>
          <NavLink to="/home" className="flex align-center gap-5" onClick={closeSideBar}>
            <svg width="20" height="20" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 10v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V10"></path>
              <path d="M9 21a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1"></path>
              <path d="m3 12 2-2 7-7 7 7 2 2"></path>
            </svg>
            <span>Home</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/analytics" className="flex align-center gap-5" onClick={closeSideBar}>
            <svg width="22" height="22" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 4H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1Z"></path>
              <path d="M7 20h10"></path>
              <path d="M9 16v4"></path>
              <path d="M15 16v4"></path>
              <path d="m8 12 3-3 2 2 3-3"></path>
            </svg>
            <span>Analytics</span>
          </NavLink>
        </li>
        <li><NavLink to="/report" className="flex align-center gap-5" onClick={closeSideBar}>
          <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
            <path d="M14 2v6h6"></path>
            <path d="M16 13H8"></path>
            <path d="M16 17H8"></path>
            <path d="M10 9H8"></path>
          </svg><span>Report</span></NavLink></li>
        <li>
          <NavLink to="/transaction" className="flex align-center gap-5" onClick={closeSideBar}>
            <svg width="25" height="25" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 9V7a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2"></path>
              <path d="M9 19h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2Zm0 0h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H9a2 2 0 0 0-1.414.586"></path>
              <path d="M15.414 15.414a2 2 0 1 0-2.828-2.828 2 2 0 0 0 2.828 2.828Z"></path>
            </svg>
            <span>Purchase Transaction</span>
          </NavLink>
        </li>
        <li><NavLink to='/bulkNotifucations' className="flex align-center gap-5" onClick={closeSideBar}><svg width="20" height="20" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 17h5l-1.405-1.405A2.032 2.032 0 0 1 18 14.158V11a6.001 6.001 0 0 0-4-5.659V5a2 2 0 1 0-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0H9m6 0v1a3 3 0 0 1-6 0v-1"></path>
        </svg> <span>Bulk Notifications </span></NavLink></li>
        <li><NavLink to="/bannerUpload" className="flex align-center gap-5" onClick={closeSideBar}>
          <svg width="20" height="20" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M3.6 20.4a1.2 1.2 0 0 1 1.2-1.2h14.4a1.2 1.2 0 1 1 0 2.4H4.8a1.2 1.2 0 0 1-1.2-1.2ZM7.552 8.048a1.2 1.2 0 0 1 0-1.696l3.6-3.6a1.2 1.2 0 0 1 1.696 0l3.6 3.6a1.2 1.2 0 0 1-1.696 1.696L13.2 6.497V15.6a1.2 1.2 0 1 1-2.4 0V6.497L9.248 8.048a1.2 1.2 0 0 1-1.696 0Z" clipRule="evenodd"></path>
          </svg><span>Banner Upload</span></NavLink></li>
        <li>
          <NavLink to="/setupdoubtsession" className="flex align-center gap-5" onClick={closeSideBar}>
            <svg width="20" height="20" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.675 4.317c-.426-1.756-2.924-1.756-3.35 0a1.723 1.723 0 0 1-2.572 1.065c-1.544-.94-3.31.827-2.37 2.37a1.724 1.724 0 0 1-1.066 2.573c-1.756.426-1.756 2.924 0 3.35a1.725 1.725 0 0 1 1.065 2.572c-.94 1.544.827 3.31 2.37 2.37a1.724 1.724 0 0 1 2.573 1.066c.426 1.756 2.924 1.756 3.35 0a1.725 1.725 0 0 1 2.572-1.065c1.544.94 3.31-.827 2.37-2.37a1.725 1.725 0 0 1 1.066-2.573c1.756-.426 1.756-2.924 0-3.35a1.724 1.724 0 0 1-1.065-2.572c.94-1.544-.827-3.31-2.37-2.37a1.724 1.724 0 0 1-2.573-1.066Z"></path>
              <path d="M14.121 14.121A3 3 0 1 0 9.88 9.88a3 3 0 0 0 4.242 4.242Z"></path>
            </svg>
            <span>Setup doubt session</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/gemstoneconfiguration" className="flex align-center gap-5" onClick={() => { closeSideBar() }}>
            <svg width="20" height="20" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.675 4.317c-.426-1.756-2.924-1.756-3.35 0a1.723 1.723 0 0 1-2.572 1.065c-1.544-.94-3.31.827-2.37 2.37a1.724 1.724 0 0 1-1.066 2.573c-1.756.426-1.756 2.924 0 3.35a1.725 1.725 0 0 1 1.065 2.572c-.94 1.544.827 3.31 2.37 2.37a1.724 1.724 0 0 1 2.573 1.066c.426 1.756 2.924 1.756 3.35 0a1.725 1.725 0 0 1 2.572-1.065c1.544.94 3.31-.827 2.37-2.37a1.725 1.725 0 0 1 1.066-2.573c1.756-.426 1.756-2.924 0-3.35a1.724 1.724 0 0 1-1.065-2.572c.94-1.544-.827-3.31-2.37-2.37a1.724 1.724 0 0 1-2.573-1.066Z"></path>
              <path d="M14.121 14.121A3 3 0 1 0 9.88 9.88a3 3 0 0 0 4.242 4.242Z"></path>
            </svg>
            <span>Gemstone Configuration</span>
          </NavLink>
        </li>
        <li >
          <NavLink to="/magazinesConfiguration" className="flex align-center gap-5 dropdown" onClick={() => { closeSideBar(); }}>
            <svg width="20" height="20" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.675 4.317c-.426-1.756-2.924-1.756-3.35 0a1.723 1.723 0 0 1-2.572 1.065c-1.544-.94-3.31.827-2.37 2.37a1.724 1.724 0 0 1-1.066 2.573c-1.756.426-1.756 2.924 0 3.35a1.725 1.725 0 0 1 1.065 2.572c-.94 1.544.827 3.31 2.37 2.37a1.724 1.724 0 0 1 2.573 1.066c.426 1.756 2.924 1.756 3.35 0a1.725 1.725 0 0 1 2.572-1.065c1.544.94 3.31-.827 2.37-2.37a1.725 1.725 0 0 1 1.066-2.573c1.756-.426 1.756-2.924 0-3.35a1.724 1.724 0 0 1-1.065-2.572c.94-1.544-.827-3.31-2.37-2.37a1.724 1.724 0 0 1-2.573-1.066Z"></path>
              <path d="M14.121 14.121A3 3 0 1 0 9.88 9.88a3 3 0 0 0 4.242 4.242Z"></path>
            </svg>
            <span>Magazines Configuration</span>
          </NavLink>
        </li>
        <li>
          <li>
            <NavLink to="/coursesCodeConfiguration" className="flex align-center gap-5" onClick={closeSideBar}>
              <svg width="20" height="20" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.675 4.317c-.426-1.756-2.924-1.756-3.35 0a1.723 1.723 0 0 1-2.572 1.065c-1.544-.94-3.31.827-2.37 2.37a1.724 1.724 0 0 1-1.066 2.573c-1.756.426-1.756 2.924 0 3.35a1.725 1.725 0 0 1 1.065 2.572c-.94 1.544.827 3.31 2.37 2.37a1.724 1.724 0 0 1 2.573 1.066c.426 1.756 2.924 1.756 3.35 0a1.725 1.725 0 0 1 2.572-1.065c1.544.94 3.31-.827 2.37-2.37a1.725 1.725 0 0 1 1.066-2.573c1.756-.426 1.756-2.924 0-3.35a1.724 1.724 0 0 1-1.065-2.572c.94-1.544-.827-3.31-2.37-2.37a1.724 1.724 0 0 1-2.573-1.066Z"></path>
                <path d="M14.121 14.121A3 3 0 1 0 9.88 9.88a3 3 0 0 0 4.242 4.242Z"></path>
              </svg>
              <span>Courses Configuration</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/consultation"
              className="flex align-center gap-5"
              onClick={() => { toogleMenu2(); }} style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}
            >
              <div className="flex align-center gap-5">
                <svg
                  width="20" height="20"
                  fill="none" stroke="currentColor" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="2"
                  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.675 4.317c-.426-1.756-2.924-1.756-3.35 0a1.723 1.723 0 0 1-2.572 1.065c-1.544-.94-3.31.827-2.37 2.37a1.724 1.724 0 0 1-1.066 2.573c-1.756.426-1.756 2.924 0 3.35a1.725 1.725 0 0 1 1.065 2.572c-.94 1.544.827 3.31 2.37 2.37a1.724 1.724 0 0 1 2.573 1.066c.426 1.756 2.924 1.756 3.35 0a1.725 1.725 0 0 1 2.572-1.065c1.544.94 3.31-.827 2.37-2.37a1.725 1.725 0 0 1 1.066-2.573c1.756-.426 1.756-2.924 0-3.35a1.724 1.724 0 0 1-1.065-2.572c.94-1.544-.827-3.31-2.37-2.37a1.724 1.724 0 0 1-2.573-1.066Z"
                  />
                  <path
                    d="M14.121 14.121A3 3 0 1 0 9.88 9.88a3 3 0 0 0 4.242 4.242Z"
                  />
                </svg>
                <span>Consultation</span>
              </div>
              {isMenuOpen2 ?
                <IoIosArrowDown size={25} /> : <IoIosArrowForward size={25} />
              }
            </NavLink>
            {isMenuOpen2 && <div className={`subroute`}>
              <NavLink to="/banneruploadconsultation" className="flex align-center gap-5" onClick={closeSideBar}>
                <svg width="20" height="20" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M3.6 20.4a1.2 1.2 0 0 1 1.2-1.2h14.4a1.2 1.2 0 1 1 0 2.4H4.8a1.2 1.2 0 0 1-1.2-1.2ZM7.552 8.048a1.2 1.2 0 0 1 0-1.696l3.6-3.6a1.2 1.2 0 0 1 1.696 0l3.6 3.6a1.2 1.2 0 0 1-1.696 1.696L13.2 6.497V15.6a1.2 1.2 0 1 1-2.4 0V6.497L9.248 8.048a1.2 1.2 0 0 1-1.696 0Z" clipRule="evenodd"></path>
                </svg>
                <span>Banner Upload</span>
              </NavLink>
              <NavLink to="/iconuploadconsultation" className="flex align-center gap-5" onClick={closeSideBar}>
                <svg width="20" height="20" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M3.6 20.4a1.2 1.2 0 0 1 1.2-1.2h14.4a1.2 1.2 0 1 1 0 2.4H4.8a1.2 1.2 0 0 1-1.2-1.2ZM7.552 8.048a1.2 1.2 0 0 1 0-1.696l3.6-3.6a1.2 1.2 0 0 1 1.696 0l3.6 3.6a1.2 1.2 0 0 1-1.696 1.696L13.2 6.497V15.6a1.2 1.2 0 1 1-2.4 0V6.497L9.248 8.048a1.2 1.2 0 0 1-1.696 0Z" clipRule="evenodd"></path>
                </svg>
                <span>Icon Upload</span>
              </NavLink>
              <NavLink to="/session" className="flex align-center gap-5" onClick={closeSideBar}>
                <svg width="24" height="24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.504 12.75a7.5 7.5 0 1 0-14.999 0 7.5 7.5 0 0 0 14.999 0Z"></path>
                  <path d="M6.663 4.275A2.188 2.188 0 0 0 5.25 3.75l-.131.004C3.922 3.826 3 4.875 3.004 6.14c0 .619.218.908.51 1.276a.213.213 0 0 0 .152.083h.041a.151.151 0 0 0 .12-.061L6.674 4.64a.252.252 0 0 0-.012-.366v0Z"></path>
                  <path d="M17.339 4.275a2.187 2.187 0 0 1 1.412-.525l.13.004c1.198.072 2.12 1.121 2.116 2.387 0 .619-.218.908-.51 1.276a.212.212 0 0 1-.151.083h-.042a.152.152 0 0 1-.119-.062l-2.848-2.797a.251.251 0 0 1 .012-.366v0Z"></path>
                  <path d="M12.004 7.5v5.25h-3.75"></path>
                  <path d="m19.504 20.25-1.875-1.875"></path>
                  <path d="m4.504 20.25 1.875-1.875"></path>
                </svg>
                <span>Today's Session</span>
              </NavLink>

              <NavLink to="/consultationdatechange" className="flex align-center gap-5" onClick={closeSideBar}>
                <svg width="20" height="20" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M3.6 20.4a1.2 1.2 0 0 1 1.2-1.2h14.4a1.2 1.2 0 1 1 0 2.4H4.8a1.2 1.2 0 0 1-1.2-1.2ZM7.552 8.048a1.2 1.2 0 0 1 0-1.696l3.6-3.6a1.2 1.2 0 0 1 1.696 0l3.6 3.6a1.2 1.2 0 0 1-1.696 1.696L13.2 6.497V15.6a1.2 1.2 0 1 1-2.4 0V6.497L9.248 8.048a1.2 1.2 0 0 1-1.696 0Z" clipRule="evenodd"></path>
                </svg>
                <span>Consultation Date Change</span>
              </NavLink>
              <NavLink to="/ConsultationCalendar" className="flex align-center gap-5" onClick={closeSideBar}>
                <svg width="23" height="23" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.5 3.75h-15A2.25 2.25 0 0 0 2.25 6v13.5a2.25 2.25 0 0 0 2.25 2.25h15a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25Z"></path>
                  <path fill="currentColor" stroke="none" d="M13.875 12a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                  <path fill="currentColor" stroke="none" d="M17.625 12a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                  <path fill="currentColor" stroke="none" d="M13.875 15.75a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                  <path fill="currentColor" stroke="none" d="M17.625 15.75a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                  <path fill="currentColor" stroke="none" d="M6.375 15.75a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                  <path fill="currentColor" stroke="none" d="M10.125 15.75a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                  <path fill="currentColor" stroke="none" d="M6.375 19.5a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                  <path fill="currentColor" stroke="none" d="M10.125 19.5a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                  <path fill="currentColor" stroke="none" d="M13.875 19.5a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                  <path d="M6 2.25v1.5"></path>
                  <path d="M18 2.25v1.5"></path>
                  <path d="M21.75 7.5H2.25"></path>
                </svg>
                <span>Consultation Calendar</span>
              </NavLink>

            </div>}
          </li>
          <li>
            <NavLink
              to="/courseupload"
              className="flex align-center gap-5"
              onClick={closeSideBar}
            >
              <svg
                width="20" height="20"
                fill="none" stroke="currentColor" strokeLinecap="round"
                strokeLinejoin="round" strokeWidth="2"
                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.675 4.317c-.426-1.756-2.924-1.756-3.35 0a1.723 1.723 0 0 1-2.572 1.065c-1.544-.94-3.31.827-2.37 2.37a1.724 1.724 0 0 1-1.066 2.573c-1.756.426-1.756 2.924 0 3.35a1.725 1.725 0 0 1 1.065 2.572c-.94 1.544.827 3.31 2.37 2.37a1.724 1.724 0 0 1 2.573 1.066c.426 1.756 2.924 1.756 3.35 0a1.725 1.725 0 0 1 2.572-1.065c1.544.94 3.31-.827 2.37-2.37a1.725 1.725 0 0 1 1.066-2.573c1.756-.426 1.756-2.924 0-3.35a1.724 1.724 0 0 1-1.065-2.572c.94-1.544-.827-3.31-2.37-2.37a1.724 1.724 0 0 1-2.573-1.066Z"
                />
                <path
                  d="M14.121 14.121A3 3 0 1 0 9.88 9.88a3 3 0 0 0 4.242 4.242Z"
                />
              </svg>
              <span>Course Icon Upload</span>
            </NavLink>

          </li>
          <li>
            <NavLink to="/popup" className="flex align-center gap-5" onClick={closeSideBar}>
              <svg width="20" height="20" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M3.6 20.4a1.2 1.2 0 0 1 1.2-1.2h14.4a1.2 1.2 0 1 1 0 2.4H4.8a1.2 1.2 0 0 1-1.2-1.2ZM7.552 8.048a1.2 1.2 0 0 1 0-1.696l3.6-3.6a1.2 1.2 0 0 1 1.696 0l3.6 3.6a1.2 1.2 0 0 1-1.696 1.696L13.2 6.497V15.6a1.2 1.2 0 1 1-2.4 0V6.497L9.248 8.048a1.2 1.2 0 0 1-1.696 0Z" clipRule="evenodd"></path>
              </svg>
              <span>PopUp Upload</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/blog" className="flex align-center gap-5" onClick={closeSideBar}>
              <svg width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                <path d="M14 2v6h6"></path>
                <path d="M16 13H8"></path>
                <path d="M16 17H8"></path>
                <path d="M10 9H8"></path>
              </svg>
              <span>Blog</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/onlinepooja" className="flex align-center gap-5" onClick={() => { toogleMenu(); }} style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
              <div className="flex align-center gap-5">
                <svg width="20" height="20" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.675 4.317c-.426-1.756-2.924-1.756-3.35 0a1.723 1.723 0 0 1-2.572 1.065c-1.544-.94-3.31.827-2.37 2.37a1.724 1.724 0 0 1-1.066 2.573c-1.756.426-1.756 2.924 0 3.35a1.725 1.725 0 0 1 1.065 2.572c-.94 1.544.827 3.31 2.37 2.37a1.724 1.724 0 0 1 2.573 1.066c.426 1.756 2.924 1.756 3.35 0a1.725 1.725 0 0 1 2.572-1.065c1.544.94 3.31-.827 2.37-2.37a1.725 1.725 0 0 1 1.066-2.573c1.756-.426 1.756-2.924 0-3.35a1.724 1.724 0 0 1-1.065-2.572c.94-1.544-.827-3.31-2.37-2.37a1.724 1.724 0 0 1-2.573-1.066Z"></path>
                  <path d="M14.121 14.121A3 3 0 1 0 9.88 9.88a3 3 0 0 0 4.242 4.242Z"></path>
                </svg>
                <span>Online Pooja</span>
              </div>
              {isMenuOpen ?
                <IoIosArrowDown size={25} /> : <IoIosArrowForward size={25} />
              }
            </NavLink>
            {isMenuOpen && <div className={`subroute`}>
              <NavLink to="/banneruploadpooja" className="flex align-center gap-5" onClick={closeSideBar}>
                <svg width="20" height="20" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M3.6 20.4a1.2 1.2 0 0 1 1.2-1.2h14.4a1.2 1.2 0 1 1 0 2.4H4.8a1.2 1.2 0 0 1-1.2-1.2ZM7.552 8.048a1.2 1.2 0 0 1 0-1.696l3.6-3.6a1.2 1.2 0 0 1 1.696 0l3.6 3.6a1.2 1.2 0 0 1-1.696 1.696L13.2 6.497V15.6a1.2 1.2 0 1 1-2.4 0V6.497L9.248 8.048a1.2 1.2 0 0 1-1.696 0Z" clipRule="evenodd"></path>
                </svg>
                <span>Banner Upload</span>
              </NavLink>
              <NavLink to="/iconuploadpooja" className="flex align-center gap-5" onClick={closeSideBar}>
                <svg width="20" height="20" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M3.6 20.4a1.2 1.2 0 0 1 1.2-1.2h14.4a1.2 1.2 0 1 1 0 2.4H4.8a1.2 1.2 0 0 1-1.2-1.2ZM7.552 8.048a1.2 1.2 0 0 1 0-1.696l3.6-3.6a1.2 1.2 0 0 1 1.696 0l3.6 3.6a1.2 1.2 0 0 1-1.696 1.696L13.2 6.497V15.6a1.2 1.2 0 1 1-2.4 0V6.497L9.248 8.048a1.2 1.2 0 0 1-1.696 0Z" clipRule="evenodd"></path>
                </svg>
                <span>Icon Upload</span>
              </NavLink>
              <NavLink to="/gettodayspooja" className="flex align-center gap-5" onClick={closeSideBar}>
                <svg width="20" height="20" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M3.6 20.4a1.2 1.2 0 0 1 1.2-1.2h14.4a1.2 1.2 0 1 1 0 2.4H4.8a1.2 1.2 0 0 1-1.2-1.2ZM7.552 8.048a1.2 1.2 0 0 1 0-1.696l3.6-3.6a1.2 1.2 0 0 1 1.696 0l3.6 3.6a1.2 1.2 0 0 1-1.696 1.696L13.2 6.497V15.6a1.2 1.2 0 1 1-2.4 0V6.497L9.248 8.048a1.2 1.2 0 0 1-1.696 0Z" clipRule="evenodd"></path>
                </svg>
                <span>Todays Pooja</span>
              </NavLink>
              <NavLink to="/datechangerequest" className="flex align-center gap-5" onClick={closeSideBar}>
                <svg width="20" height="20" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M3.6 20.4a1.2 1.2 0 0 1 1.2-1.2h14.4a1.2 1.2 0 1 1 0 2.4H4.8a1.2 1.2 0 0 1-1.2-1.2ZM7.552 8.048a1.2 1.2 0 0 1 0-1.696l3.6-3.6a1.2 1.2 0 0 1 1.696 0l3.6 3.6a1.2 1.2 0 0 1-1.696 1.696L13.2 6.497V15.6a1.2 1.2 0 1 1-2.4 0V6.497L9.248 8.048a1.2 1.2 0 0 1-1.696 0Z" clipRule="evenodd"></path>
                </svg>
                <span>Change Date Request</span>
              </NavLink>
              <NavLink to="/poojaCalendar" className="flex align-center gap-5" onClick={closeSideBar}>
                <svg width="23" height="23" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.5 3.75h-15A2.25 2.25 0 0 0 2.25 6v13.5a2.25 2.25 0 0 0 2.25 2.25h15a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25Z"></path>
                  <path fill="currentColor" stroke="none" d="M13.875 12a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                  <path fill="currentColor" stroke="none" d="M17.625 12a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                  <path fill="currentColor" stroke="none" d="M13.875 15.75a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                  <path fill="currentColor" stroke="none" d="M17.625 15.75a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                  <path fill="currentColor" stroke="none" d="M6.375 15.75a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                  <path fill="currentColor" stroke="none" d="M10.125 15.75a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                  <path fill="currentColor" stroke="none" d="M6.375 19.5a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                  <path fill="currentColor" stroke="none" d="M10.125 19.5a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                  <path fill="currentColor" stroke="none" d="M13.875 19.5a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"></path>
                  <path d="M6 2.25v1.5"></path>
                  <path d="M18 2.25v1.5"></path>
                  <path d="M21.75 7.5H2.25"></path>
                </svg>
                <span>Pooja Calendar</span>
              </NavLink>
              {/* <NavLink to="/sessionPooja" className="flex align-center gap-5" onClick={closeSideBar}>
                <svg width="24" height="24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.504 12.75a7.5 7.5 0 1 0-14.999 0 7.5 7.5 0 0 0 14.999 0Z"></path>
                  <path d="M6.663 4.275A2.188 2.188 0 0 0 5.25 3.75l-.131.004C3.922 3.826 3 4.875 3.004 6.14c0 .619.218.908.51 1.276a.213.213 0 0 0 .152.083h.041a.151.151 0 0 0 .12-.061L6.674 4.64a.252.252 0 0 0-.012-.366v0Z"></path>
                  <path d="M17.339 4.275a2.187 2.187 0 0 1 1.412-.525l.13.004c1.198.072 2.12 1.121 2.116 2.387 0 .619-.218.908-.51 1.276a.212.212 0 0 1-.151.083h-.042a.152.152 0 0 1-.119-.062l-2.848-2.797a.251.251 0 0 1 .012-.366v0Z"></path>
                  <path d="M12.004 7.5v5.25h-3.75"></path>
                  <path d="m19.504 20.25-1.875-1.875"></path>
                  <path d="m4.504 20.25 1.875-1.875"></path>
                </svg>
                <span>Today's Session</span>
              </NavLink> */}
            </div>}
          </li>
          <NavLink to="/couponCodeConfiguration" className="flex align-center gap-5" onClick={closeSideBar}>
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 5H3a1 1 0 0 0-1 1v4h.893c.996 0 1.92.681 2.08 1.664A2 2 0 0 1 3 14H2v4a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-4h-1a2.002 2.002 0 0 1-1.973-2.336c.16-.983 1.084-1.664 2.08-1.664H22V6a1 1 0 0 0-1-1ZM11 17H9v-2h2v2Zm0-4H9v-2h2v2Zm0-4H9V7h2v2Z"></path>
            </svg>
            <span>Coupon code</span>
          </NavLink>
        </li>
      </ul>
      <div className='logout'>
        <NavLink to="/" onClick={logOut} className="flex align-center gap-5"><svg width="20" height="20" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="m17 16 4-4m0 0-4-4m4 4H7m6 4v1a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1"></path>
        </svg><span>logout</span></NavLink>
      </div>
    </div>
  )
}

export default Sidebar