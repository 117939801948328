import React, { useRef} from 'react'
import "./SetupDoubtSession.css"
import { setupDoubtSessionSchema } from '../../schema/Index'
import { useFormik } from 'formik';
import { toast } from "react-toastify";
import { API_END_POINT,TOKEN} from '../../constant';
import axios from 'axios';


const SetupDoubtSession = () => {
    const spinnerRef = useRef(null);

    // ------ here i'm using useFormik npm packages ---
    const initialValues = {
        price: "",
        doubtSessionLink: ""
    };

    const setDoubtSession = async (Data) => {
        try {
            
            const formData = {
                "formData": {
                    "price": Data?.price,
                    "startTime": "",
                    "doubtSessionId": 1,
                    "doubtSessionTitle": "",
                    "doubtSessionLink": Data?.doubtSessionLink
                }
            }
            const response = await axios.put(`${API_END_POINT}DoubtSession/updateDoubtSessionById`, formData, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                },
                params: {
                    DoubtSessionId: 1,
                },
            });
            toast.success("Session Set Up Successfully!");
            return response.data;
        } catch (error) {
            throw new Error(`Error fetching data from API: ${error.message}`);
        }
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
        initialValues: initialValues,
        validationSchema: setupDoubtSessionSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                spinnerRef.current.style.display = "inline-block"
                const response = await setDoubtSession(values);
                spinnerRef.current.style.display = "none"
                resetForm();
            } catch (error) {
                toast.error("Unable To Set Session.");
                spinnerRef.current.style.display = "none"
                resetForm();
            }
        }
    })

    return (
        <main className='main'>
            <section className='section'>
                <h3 className='heding'>Setup Doubt Session</h3>
                <div style={{ marginTop: "6px" }}>
                    <form className='form' onSubmit={handleSubmit}> 
                        <div className="input-field flex flex-col">
                            <label htmlFor="SessionPrice">Session Price</label>
                            <input type="number" id='SessionPrice' name='price' placeholder='enter session price' value={values.price} onBlur={handleBlur} onChange={handleChange} />
                            {errors.price && touched.price && (
                                <div className="error">{errors.price}</div>
                            )}
                        </div>
                        <div className="input-field flex flex-col">
                            <label htmlFor="MessageTitle">Meeting Link</label>
                            <input type="text" id='MessageTitle' placeholder='https://example.google.com/rgp-brey-qbk' name='doubtSessionLink' value={values.doubtSessionLink} onBlur={handleBlur} onChange={handleChange} />
                            {errors.doubtSessionLink && touched.doubtSessionLink && (
                                <div className="error">{errors.doubtSessionLink}</div>
                            )}
                        </div>
                        <div className='buttons flex flex-wrap align-center gap-5'>
                            <button className='reset' type='reset' onClick={() => resetForm()}>Reset</button>
                            <button type='submit'>Setup Session <span className="spinner" id="spinner" ref={spinnerRef}></span></button>
                        </div>
                    </form>
                </div>
            </section>
        </main>
    )
}

export default SetupDoubtSession