import React, { useEffect, useState } from 'react'
import { API_END_POINT, TOKEN } from '../../constant';
import axios from 'axios';
import CommonTable from '../../components/Table/CommonTable';
import './Transaction.css'

function Transaction() {
    const [data, setData] = useState();
    const [moduleName, setModuleName] = useState([]);
    const [totalRows, setTotalRows] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [dropDownId, setDropDownId] = useState(1);

    const getPayments = (id, page) => {
        console.log("Default page :", currentPage)
        const headers = {
            "Authorization": `Bearer ${TOKEN}`,
        }

        axios.get(`${API_END_POINT}payments/getPurchasePeople/${id}?page=${page - 1}&size=10`, { headers })
            .then((response) => {
                setData(response?.data?.data?.webHookResponseList)
                setTotalRows(response?.data?.data?.totalRecords)
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const getModuleName = () => {
        const headers = {
            "Authorization": `Bearer ${TOKEN}`,
        }

        axios.get(`${API_END_POINT}CouponCodeController/getCouponCodModuleList`, { headers })
            .then((response) => {
                setModuleName(response.data)
                // console.log("Response in getModuleName :", response)
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const columns = [
        {
            name: <h3>UserName</h3>,
            cell: (row) => row?.userName,
        },
        {
            name: <h3>Payment Id</h3>,
            cell: (row) => row?.paymentId,

        },
        {
            name: <h3>Razorpay Order Id</h3>,
            cell: (row) => row?.razorpayOrderId,

        },
        // {
        //     name: <h3>Name</h3>,
        //     cell: (row) => row?.userName,

        // },
        // {
        //     name: <h3>D.O.B</h3>,
        //     cell: (row) => row?.userDOB,
        // },
        // {
        //     name: <h3>Contact</h3>,
        //     cell: (row) => row?.userMobileNo,
        // },
        // {
        //     name: <h3>Email</h3>,
        //     cell: (row) => row?.userEmail,
        // },
        {
            name: <h3>Amount</h3>,
            cell: (row) => row?.amount,
        },
        {
            name: <h3>Created At</h3>,
            cell: (row) => row?.createdAt,
        },
        {
            name: <h3>Contact</h3>,
            cell: (row) => row?.mobileNo,
        },
        {
            name: <h3>Status</h3>,
            cell: (row) => row?.eventStatus === 'order.paid' ?
                <div className='status-paid'>
                    Paid
                </div>
                : <div className='status-notpaid'>
                    Failed
                </div>,
        },
    ]

    function handleModuleChange(event) {
        getPayments(event.target.value, currentPage)
        setDropDownId(event.target.value)
    }

    useEffect(() => {
        if (dropDownId) {
            getPayments(dropDownId, currentPage);
        }
        getModuleName()
    }, [currentPage, dropDownId]);


    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <main className='main'>
            <section className='section'>
                <h3 className='heding' style={{ marginBottom: "10px" }}>Transaction</h3>
                <div className='form'>
                    <div className='input-field flex flex-col' style={{ maxWidth: "350px", width: "100%" }}>
                        <label htmlFor="dropdown">Select Module Name</label>
                        <select name="dropdown" id='dropdown' onChange={handleModuleChange}>
                            <option value="" disabled>Select a module</option>
                            {
                                moduleName.length > 0 ? moduleName.map((item) => (
                                    <option key={item.paymentSourceModuleId} value={item.paymentSourceModuleId}>
                                        {item.paymentSourceModuleName}
                                    </option>
                                )) : <option>Loading...</option>
                            }
                        </select>
                    </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                    <CommonTable data={data} columns={columns} totalRows={totalRows} handlePageChange={handlePageChange} value={true} />
                </div>
            </section>

        </main>
    )
}

export default Transaction