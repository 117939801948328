import React, { useEffect, useRef, useState } from 'react'
import "./BannerUpload.css"
import axios from 'axios';
import { toast } from "react-toastify";
import { API_END_POINT, TOKEN } from '../../constant';
import CommonTable from '../../components/Table/CommonTable';

const BannerUpload = () => {
    const [data, setData] = useState([{}]);
    const fileInputs = useRef([...Array(10)].map(() => React.createRef()));
    const [isChecked, setIsChecked] = useState({});
    const [bannerId, setBannerId] = useState(1);

    // Handle the checkbox toggle
    const handleCheckboxChange = async (id) => {
        try {
            await axios.get(`${API_END_POINT}bannerFile/updateBannerById?bannerImageId=${id}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                }
            });

            getBannerListingByLanguage(bannerId)
        } catch (err) {

        }

    };

    const uploadBanner = async (e, id) => {
        const spinner = document.getElementById(`spinner${id}`);
        try {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            formData.append('content', 'content');
            formData.append('title', 'title');
            formData.append('bannerImageId', id);
            spinner.style.display = "inline-block"
            const response = await axios.put(`${API_END_POINT}bannerFile/updateBannerImage`, formData, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                },
            });
            spinner.style.display = "none"
            toast.success(response?.data?.response?.responseMessage);
            return response.data;
        } catch (error) {
            spinner.style.display = "none"
            toast.error("Unable To Upload File.");
        }
    }

    const handleChange = (e) => {
        let id = 0;
        if (e.target.value.toLowerCase() === "english") {
            id = 1;
        } else if (e.target.value.toLowerCase() === "hindi") {
            id = 2;
        } else if (e.target.value.toLowerCase() === "marathi") {
            id = 3;
        } else if (e.target.value.toLowerCase() === "gujarati") {
            id = 4;
        }
        setBannerId(id);
        getBannerListingByLanguage(id);
    }

    // ------- get list ----
    const getBannerListingByLanguage = (id) => {
        const headers = {
            "Authorization": `Bearer ${TOKEN}`,
        }

        axios.get(`${API_END_POINT}bannerFile/getBannerListingByLanguage?langId=${id}`, { headers })
            .then((response) => {
                setData(response?.data?.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const columns = [
        {
            name: <h3>Banner Number</h3>,
            selector: (row, index) => index + 1,
        },
        {
            name: <h3>Action</h3>,
            selector: (row, index) =>
            (<button disabled={!row?.active} style={{ opacity: `${!row?.active ? 0.7 : 1}`, cursor: `${!row?.active ? "not-allowed" : "pointer"}` }} onClick={() => fileInputs.current[index].current.click()} className='uploadbtn flex align-center gap-5'>
                <svg width="16" height="16" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M3.6 20.4a1.2 1.2 0 0 1 1.2-1.2h14.4a1.2 1.2 0 1 1 0 2.4H4.8a1.2 1.2 0 0 1-1.2-1.2ZM7.552 8.048a1.2 1.2 0 0 1 0-1.696l3.6-3.6a1.2 1.2 0 0 1 1.696 0l3.6 3.6a1.2 1.2 0 0 1-1.696 1.696L13.2 6.497V15.6a1.2 1.2 0 1 1-2.4 0V6.497L9.248 8.048a1.2 1.2 0 0 1-1.696 0Z" clipRule="evenodd"></path>
                </svg>
                <span>Upload</span>
                <span className="spinner" id={`spinner${row?.id}`}></span>
                <input style={{ display: "none" }} type="file" onChange={(e) => uploadBanner(e, row?.id)} ref={fileInputs.current[index]} />
            </button>
            )

        },
        {
            name: "Status",
            selector: (row, index) => {
                isChecked[row?.id] = row?.active
                // console.log(isChecked[row?.id], 'and', row?.active)
                return (
                    <div className="button r" id="button-1">
                        <input type="checkbox" className="checkbox" checked={isChecked[row?.id]} onChange={(e) => (handleCheckboxChange(row?.id))} />
                        <div className="knobs"></div>
                        <div className="layer"></div>
                    </div>
                )

            }
        }
    ]

    useEffect(() => {
        getBannerListingByLanguage(1);
    }, []);

    return (
        <main className='main'>
            <section className='section'>
                <h3 className='heding' style={{ marginBottom: "10px" }}>Banner Upload</h3>
                <div className='form'>
                    <div className='input-field flex flex-col' style={{ maxWidth: "350px", width: "100%" }}>
                        <label htmlFor="dropdown">Select Language for Banner Upload</label>
                        <select name="dropdown" id='dropdown' onChange={handleChange}>
                            <option value="English">English</option>
                            <option value="Hindi">Hindi</option>
                            <option value="Marathi">Marathi</option>
                            <option value="Gujarati">Gujarati</option>
                        </select>
                    </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                    <CommonTable data={data} columns={columns} />
                </div>
            </section>
        </main>
    )
}

export default BannerUpload