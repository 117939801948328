import React, { useState } from 'react'
import './BoxAnalytics.css'

function BoxAnalytics({ subMenuName, totalCount, viewList, todayCount }) {
    const [viewListText, setViewListText] = useState("View List")

    const viewListData = () => {
        viewList(viewListText, setViewListText, subMenuName);
    }
    return (
        <div className='box1'>
            {
                subMenuName !== "" && <h5 className='title flex align-center flex-wrap gap-5 justify-between'>
                    <span>{subMenuName}</span>
                    {
                        viewList !== "" && <span className='listLink' onClick={viewListData}>{viewListText}</span>
                    }
                </h5>
            }
            {
                totalCount !== null && <div className='flex justify-between ali' style={{ padding: ".5rem 1rem" }} >
                    <div className='flex flex-col align-center'>
                        <span>Today Count</span>
                        <span>{todayCount}</span>
                    </div>
                    <div className='flex flex-col align-center'>
                        <span>Total Count</span>
                        <span>{totalCount}</span>
                    </div>
                </div>
            }
        </div>
    )
}

export default BoxAnalytics