import React, { useState, useEffect, useRef } from 'react'
import "./consultation.css"
import CommonTable from '../../components/Table/CommonTable'
import { consultationSchema } from '../../schema/Index';
import { useFormik } from 'formik';
import { toast } from "react-toastify";
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import { TOKEN, API_END_POINT } from '../../constant';
import Popup from 'reactjs-popup';
import Confirmation from '../../components/ConfirmationPopup/Confirmation';

const Consultation = () => {
    const [initialValues, setInitialValues] = useState({
        consultationName: "",
        noOfSessions: "",
        consultationInfo: "",
        image: "",
        planValidity: "",
        planPrice: "",
        perSessionTime: ""
    })
    const [showForm, setShowForm] = useState(false);
    const [btnName, setBtnName] = useState("Add");
    const [magazinesList, setMagazinesList] = useState(null);
    // const [consultationList, setConsultationList]=useState(null);
    // const [consultationCategories, setConsultationCategories] = useState([]);
    const [confirmUpdate, setConfirmUpdate] = useState(0);
    const [isImagePreviewPopUpOpen, setIsImagePreviewPopUpOpen] = useState(false);
    const [isInfoPreviewPopUpOpen, setIsInfoPreviewPopUpOpen] = useState(false);
    const [previewImageUrl, setPreviewImageUrl] = useState("");
    const [previewInfoUrl, setPreviewInfoUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [isConfirmationDelete, setIsConfirmationDelete] = useState(false);
    const [deleteContentId, setDeleteContentId] = useState(0)
    const [isConfirmationEdit, setIsConfirmationEdit] = useState(false);
    const fileInputRef = useRef(null);

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        validationSchema: consultationSchema,
        onSubmit: async (values, { resetForm }) => {
            console.log(btnName)
            console.log(values);
            if (btnName === "Save") {
                setIsConfirmationEdit(true)
            } else if (btnName === "Add") {
                await addNewMagazines(values);
                setShowForm(false);
                resetForm();
            }
        },
        enableReinitialize: true,
    });



    const columns = [
        {
            name: 'Consultation Name',
            cell: (row) => row?.consultationName,
        },
        {
            name: "No. Of Sessions",
            cell: (row) => row?.noOfSessions,
        },
        {
            name: "Consultation Info.",
            cell: (row) => (
                <div>
                    {row?.consultationInfo?.slice(0, 100)}
                    {row?.consultationInfo?.length > 100 && (
                        <>
                            ... <button onClick={() => showInfoPreviewPopUp(row?.consultationInfo)}>Show More</button>
                        </>
                    )}
                </div>
            ),
        },

        {
            name: 'Image',
            cell: (row) => <img title='Click to see Image' onClick={() => showImagePreviewPopUp(row?.imageLink[0])} src={`${API_END_POINT}${row?.imageLink[0]}`} width={"auto"} height={"70vh"} alt='magazines' />,
        },
        {
            name: "Plan Validity",
            cell: (row) => row?.planValidity,
        },
        {
            name: ' Plan Price',
            cell: (row) => row?.planPrice,
        },
        {
            name: ' Per Session Time',
            cell: (row) => row?.perSessionTime,
        },
        {
            name: 'Actions',
            cell: (row, index) => (
                <div className='flex align-center gap-10'>
                    <button className='flex align-center edit-btn' onClick={() => updateConsultation(row.consultationSessionId)}>
                        <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m7 17.011 4.413-.015 9.632-9.54c.378-.378.586-.88.586-1.414 0-.534-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.581v4.43ZM18.045 4.456l1.589 1.583-1.597 1.582-1.586-1.585 1.594-1.58ZM9 13.416l6.03-5.974 1.586 1.586L10.587 15 9 15.004v-1.589Z"></path>
                            <path d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2Z"></path>
                        </svg>
                    </button>
                    <button className='flex align-center delete-btn-icon' onClick={() => deleteMagazines(row.consultationSessionId)}>
                        <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2v12ZM9 4h6v2H9V4ZM8 8h9v12H7V8h1Z"></path>
                            <path d="M9 10h2v8H9v-8Zm4 0h2v8h-2v-8Z"></path>
                        </svg>
                    </button>
                </div>
            ),
        },
    ];



    const addRow = () => {
        btnName === "Save" && setBtnName("Add");
        setInitialValues({
            consultationName: "",
            noOfSessions: "",
            consultationInfo: "",
            image: "",
            planValidity: "",
            planPrice: "",
            perSessionTime: ""
        })
        setShowForm(!showForm);
    };

    // //Retrieves the list of magazines.
    // const getMagazinesList = async () => {
    const getConsultationList = async () => {
        try {
            setLoading(true)
            // Define request headers
            const headers = {
                Authorization: `Bearer ${TOKEN}`
            };

            // Make GET request using Axios
            // const response = await axios.get(`${API_END_POINT}magazines/getMagazineListAdmin`, { headers });
            const response = await axios.get(`${API_END_POINT}consultationSession/getConsultationList`, { headers });
            setLoading(false);
            console.log();
            return response?.data?.data;
        } catch (error) {
            setMagazinesList([])
            setLoading(false);
        }
    };

    // Deletes a magazine by its ID.
    const deleteMagazines = async (id) => {
        console.log("jjjjjj", id);
        setIsConfirmationDelete(true);
        setDeleteContentId(id);
    };

    // Delete Action confirm popup
    const confirmActionDelete = async (data) => {
        try {
            // Define request headers
            const headers = {
                Authorization: `Bearer ${TOKEN}`
            };

            // Make DELETE request using Axios
            await axios.delete(`${API_END_POINT}consultationSession/delete/${deleteContentId}`, {
                headers
            });
            setMagazinesList(await getConsultationList())
            toast.success("Consultation Deleted successfully");

        } catch (error) {
            toast.error("Unable To Delete Consultation");
        }
    }

    // Adds a new magazine.
    const addNewMagazines = async (info) => {
        // const categoryId = magazineCategories.find((data) => data?.magazineCategoryName.toLowerCase() === info?.dropdown?.toLowerCase());
        try {
            // Define request headers
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`
            };

            // Make POST request using Axios
            let response = await axios.post(`${API_END_POINT}consultationSession/save`, {
                consultationInfo: info?.consultationInfo,
                consultationName: info?.consultationName,
                noOfSessions: info?.noOfSessions, // Assuming noOfSessions is a number in your form
                // image: info?.image,
                planValidity: info?.planValidity,
                planPrice: info?.planPrice,
                perSessionTime: info?.perSessionTime
            }, {
                headers
            });

            fileInputRef.current.files[0] && await uploadConsultationFile(response?.data?.data?.consultationSessionId);
            setMagazinesList(await getConsultationList());
            toast.success("Consultation Added Successfully!");
        } catch (error) {
            console.log(error);
            toast.error("Unable To Add Consultation");
        }
    };

    const uploadConsultationFile = async (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${TOKEN}`);

        const formdata = new FormData();
        console.log(id);
        console.log(fileInputRef.current.files[0]);
        formdata.append("File", fileInputRef.current.files[0], fileInputRef.current.files[0].name);
        formdata.append("consultationId", id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        try {
            await fetch(`${API_END_POINT}consultationSession/upload`, requestOptions);
        } catch (error) {
            console.log(error)
        }
    }

    const uploadImage = async (imageFile) => {
        const formData = new FormData();
        formData.append('file', imageFile); // 'file' is the key expected by your server

        try {
            const headers = {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${TOKEN}`
            };

            const response = await axios.post(`${API_END_POINT}consultationSession/upload`, formData, { headers });
            return response.data.imageUrl; // Assuming the response includes the URL of the uploaded image
        } catch (error) {
            console.error('Error uploading image:', error);
            throw new Error('Failed to upload image');
        }
    };


    const updateConsultation = async (id) => {
        setBtnName("Save");

        // Find the magazine in the list based on its ID
        let previousData = magazinesList.find((data) => data.consultationSessionId === id);
        // Set initial form values for updating
        setInitialValues({
            consultationName: previousData?.consultationName,
            noOfSessions: previousData?.noOfSessions, // Assuming noOfSessions is a string in your data
            consultationInfo: previousData?.consultationInfo,
            image: previousData?.imageLink[0], // Clear image if needed or keep as previousData?.imageLink[0] depending on your logic
            planValidity: previousData?.planValidity,
            planPrice: previousData?.planPrice,
            perSessionTime: previousData?.perSessionTime
        });

        // Show the form for editing
        setShowForm(true);

        // Set the ID of the item being updated
        setConfirmUpdate(id);

    };


    // confirmupdate 
    const ConfirmUpdateMagazine = async (info) => {
        try {
            // Define request headers
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`
            };

            // Define request body
            const data = {
                consultationName: info?.consultationName,
                noOfSessions: info?.noOfSessions,
                consultationInfo: info?.consultationInfo,
                image: info?.image,
                planValidity: info?.planValidity,
                planPrice: info?.planPrice,
                perSessionTime: info?.perSessionTime
                // actionInfo: {
                //     actionId: "String" // Replace with actual action ID if necessary
                // },
                // formData: {

                // },

            };

            // Make PUT request using Axios
            let response = await axios.put(`${API_END_POINT}consultationSession/update/${confirmUpdate}`, data, {
                headers
            });

            console.log("response in ConfirmUpdateMagazine is ", response);
            fileInputRef.current.files[0] && uploadMagazineFile(confirmUpdate);
            console.log(confirmUpdate)
            setShowForm(false);
            toast.success("Magazines Updated successfully");
            setMagazinesList(await getConsultationList());
            setMagazinesList(await getConsultationList());
        } catch (error) {
            toast.error("Unable To Update Magazines");
        }
    }

    // this function for close image preview popup
    const closePopup = () => {
        setIsImagePreviewPopUpOpen(false);
    }

    // this function for close information preview popup
    const closePopup1 = () => {
        setIsInfoPreviewPopUpOpen(false);
    }

    // this function fro show image preview
    const showImagePreviewPopUp = (imageUrl) => {
        setPreviewImageUrl(`${API_END_POINT}${imageUrl}`);
        setIsImagePreviewPopUpOpen(true)
    }

    // this function fro show information preview
    const showInfoPreviewPopUp = (infoUrl) => {
        setPreviewInfoUrl(infoUrl);
        setIsInfoPreviewPopUpOpen(true)
    }


    // ----- this is for close confirm delete popup -----
    const closeConfirmDeletePopUp = () => {
        setIsConfirmationDelete(false)
    }

    // ----- this is for close confirm Edit popup -----
    const closeConfirmEditPopUp = () => {
        setIsConfirmationEdit(false)
    }

    const uploadMagazineFile = async (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${TOKEN}`);

        const formdata = new FormData();
        console.log("Id in consultation.jsx is", id);
        console.log("file in consultation.jsx is ", fileInputRef.current.files[0]);
        formdata.append("File", fileInputRef.current.files[0], fileInputRef.current.files[0].name);
        formdata.append("consultationId", id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };
        try {
            await fetch(`${API_END_POINT}consultationSession/upload`, requestOptions);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        async function fetchData() {
            setMagazinesList(await getConsultationList())

        }
        fetchData();

    }, [])


    return (
        <main className='main'>
            {magazinesList && <section className='section'>
                <h3 className='heding'>Consultation</h3>
                <div style={{ marginTop: "10px" }} className='flex align-center justify-end'>
                    <button onClick={addRow} className='common-btn flex align-center'>
                        {!showForm ? <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6v-2Z"></path>
                        </svg> :
                            <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242-1.414-1.414Z"></path>
                            </svg>}
                    </button>
                </div>
                {showForm && (
                    <form onSubmit={handleSubmit} className='flex flex-wrap aign-center gap-5 magazinesConfigurationForm form'>

                        <div className='input-field flex flex-col'>
                            <label htmlFor="name">Consultation Name</label>
                            <input
                                id='consultationName'
                                type="text"
                                name="consultationName"
                                value={values.consultationName}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                placeholder="enter Consultation name"
                                className={`${(errors.consultationName && touched.consultationName) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="noOfSessions">No. of Sessions</label>
                            <input
                                id='noOfSessions'
                                type="number"
                                name="noOfSessions"
                                value={values.noOfSessions}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                placeholder="enter no of sessions"
                                className={`${(errors.noOfSessions && touched.noOfSessions) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="consultationInfo">Consultation Info.</label>
                            <input
                                id='consultationInfo'
                                type="text"
                                name="consultationInfo"
                                value={values.consultationInfo}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                className={`${(errors.consultationInfo && touched.consultationInfo) && 'error'}`}
                                placeholder="enter Consultation information"
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="image">Select Image</label>
                            {/* Hidden file input */}
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => {
                                    setFieldValue("image", e?.target?.files[0]?.name);
                                }}
                                ref={fileInputRef}
                            />

                            <input
                                id='image'
                                type="text"
                                name="image"
                                value={values.image}
                                onClick={() => {
                                    fileInputRef.current.click();
                                }}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                placeholder='select Image'
                                readOnly
                                className={`${(errors.image && touched.image) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="planValidity">Plan Validity</label>
                            <input
                                id='planValidity'
                                type="number"
                                name="planValidity"
                                value={values.planValidity}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                placeholder='Enter Plan Validity'
                                className={`${(errors.planValidity && touched.planValidity) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="planPrice">Plan Price</label>
                            <input
                                id='planPrice'
                                type="number"
                                name="planPrice"
                                value={values.planPrice}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                placeholder='Enter Plan Price'
                                className={`${(errors.planPrice && touched.planPrice) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="perSessionTime">Per Session Time</label>
                            <input
                                id='perSessionTime'
                                type="number"
                                name="perSessionTime"
                                value={values.perSessionTime}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                placeholder='Enter Plan Validity'
                                className={`${(errors.perSessionTime && touched.perSessionTime) && 'error'}`}
                            />
                        </div>
                        <div className='buttons flex flex-col' style={{ alignItems: "flex-end" }}>
                            <label htmlFor="btn" style={{ visibility: "hidden", marginBottom: "5px" }}>hidden</label>
                            <button type="submit" id='btn' className='btn' style={{ width: "100%" }}>{btnName}</button>
                        </div>
                    </form>
                )}
                <div style={{ marginTop: "10px" }}>
                    <CommonTable columns={columns} data={magazinesList} />
                </div>
            </section>}

            {/* ----- confirmation Delete popup --- */}
            <Confirmation text={"Do you want to delete this Consultation"} confirmAction={confirmActionDelete} closeConfirmPopUp={closeConfirmDeletePopUp} isConfirmationOpen={isConfirmationDelete} />

            {/* ----- confirmation Edit popup --- */}
            <Confirmation text={"Do you want to Save this Consultation"} confirmAction={ConfirmUpdateMagazine} closeConfirmPopUp={closeConfirmEditPopUp} isConfirmationOpen={isConfirmationEdit} data={values} />

            {/* ------ popup for image preview --------- */}
            <Popup open={isImagePreviewPopUpOpen} onClose={closePopup} modal>
                <div>
                    <div className="flex align-center justify-between gap-5 image-preview-popup-header">
                        <span>Preview</span>
                        <svg onClick={closePopup} width="30" height="30" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242-1.414-1.414Z"></path>
                        </svg>
                    </div>
                    <div className="image-preview-popup-content">
                        <img src={previewImageUrl} alt="Magazine/Book" />
                    </div>
                </div>
            </Popup>
            {/* ------ popup for image preview --------- */}
            <Popup open={isInfoPreviewPopUpOpen} onClose={closePopup1} modal>
                <div>
                    <div className="flex align-center justify-between gap-5 image-preview-popup-header">
                        <span>Information</span>
                        <svg onClick={closePopup1} width="30" height="30" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242-1.414-1.414Z"></path>
                        </svg>
                    </div>
                    <div className="image-preview-popup-content">
                        {previewInfoUrl}
                    </div>
                </div>
            </Popup>

            {/* ------- loader here ------- */}
            {loading && <Loader />}
        </main>
    )
}

export default Consultation


