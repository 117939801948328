import React, { useState, useEffect } from 'react'
import "./CoursesDoubtSession.css"
import CommonTable from '../Table/CommonTable'
import axios from 'axios';
import { API_END_POINT, TOKEN } from '../../constant';


const CoursesDoubtSession = () => {
    const [data, setData] = useState(null);

    const fetchDataFromAPI = async (id) => {
        try {
            const response = await axios.get(`${API_END_POINT}DoubtSession/userListPerDoubtSession?slotId=${id}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                },
            });
            return response.data;
        } catch (error) {
            throw new Error(`Error fetching data from API: ${error.message}`);
        }
    }

    const handleChange = async (e) => {
        let id = 1;
        console.log(e.target.value)
        if (e.target.value === "Sunday Slot") {
            id = 2;
        }
        const fetchedData = await fetchDataFromAPI(id);
        setData(fetchedData);

    }

    const columns = [
        {
            name: <h3>Name</h3>,
            selector: (row) => <span title={row?.userName === null ? "-" : row?.userName}>{row?.userName === null ? "-" : row?.userName}</span>,
        },
        {
            name: <h3>Mobile Number</h3>,
            selector: (row) => <span title={row?.mobile === null ? "-" : row?.mobile}>{row?.mobile === null ? "-" : row?.mobile}</span>
        },
        {
            name: <h3>Email</h3>,
            selector: (row) => <span title={row?.email === null ? "-" : row?.email}>{row?.email === null ? "-" : row?.email}</span>
        },
    ]

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedData = await fetchDataFromAPI(1);
                setData(fetchedData);
            } catch (error) {
                console.log(error)
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <div className='form' style={{ marginBottom: "10px", maxWidth: "300px", width: "100%" }}>
                <div className='input-field flex'>
                    <select name="dropdown" id='SlotWaseSession' onChange={handleChange}>
                        <option value="Saturday Slot">Saturday Slot</option>
                        <option value="Sunday Slot">Sunday Slot</option>
                    </select>
                </div>
            </div>
            <CommonTable data={data !== null ? data : ""} columns={columns} />
        </>
    )
}

export default CoursesDoubtSession