import React from 'react'
import "./Confirmation.css"
import Popup from 'reactjs-popup';

const Confirmation = ({text,confirmAction,closeConfirmPopUp,isConfirmationOpen,data}) => {

    const cancelOperation = () => {
        closeConfirmPopUp();
    }

    const confirmOperation = async () => {
        closeConfirmPopUp();
        await confirmAction(data);
    }

    const closePopUp  = ()=>{
       closeConfirmPopUp();
    }
   

    return (
        
        <Popup open={isConfirmationOpen} onClose={closePopUp} modal> 
            <div id='confirmation'>
                <div className="confirmation-header flex align-center gap-5 justify-between">
                    <b>Confirmation</b>
                    <svg onClick={closePopUp} width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242-1.414-1.414Z"></path>
                    </svg>
                </div>

                <div className="confirmation-content align-center flex gap-5">
                    <svg width="30" height="30" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 12c0-4.969-4.031-9-9-9s-9 4.031-9 9 4.031 9 9 9 9-4.031 9-9Z"></path>
                        <path d="m11.73 7.781.27 5.717.268-5.717a.267.267 0 0 0-.164-.26.269.269 0 0 0-.107-.021v0a.27.27 0 0 0-.266.281v0Z"></path>
                        <path fill="currentColor" stroke="none" d="M12 17.242a.937.937 0 1 1 0-1.874.937.937 0 0 1 0 1.874Z"></path>
                    </svg>
                    <p>{text}</p>
                </div>

                <div className="confirmation-footer flex align-center">
                    <button type='button' id='no' onClick={cancelOperation}>No</button>
                    <button type='button' id='yes' onClick={confirmOperation}>Yes</button>
                </div>
            </div>
        </Popup>
    )
}

export default Confirmation