import React, { useState, useEffect, useRef } from 'react'
import "./Session.css"
import CommonTable from '../../components/Table/CommonTable'
import { toast } from "react-toastify";
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import { TOKEN, API_END_POINT } from '../../constant';


const Session = () => {
    const [initialValues, setInitialValues] = useState({
        userName: "",
        userId: "",
        userEmail: "",
        usermobile: "",
        sessionDate: "",
        allottedSessionId: "",
        sessionNumber: "",
        sessionCompleted: "",
    });
    const [magazinesList, setMagazinesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [btnName, setBtnName] = useState(false);

    let decodedDate;
    //DATE 
    // Create a date variable
    // Function to format the date as yyyy-mm-dd hh:mm:ss
    function formatDateTime(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    // Function to update and log the current date
    function updateDate() {
        let currentDate = new Date();
        let formattedCurrentDate = formatDateTime(currentDate);

        // Encode the formatted date string
        let encodedDateString = encodeURIComponent(formattedCurrentDate);

        // Decode the date string
        decodedDate = decodeURIComponent(encodedDateString);

        console.log('Current date:', currentDate); // Log the date object
        console.log('Formatted current date:', formattedCurrentDate); // Log the formatted date
        console.log('Encoded date string:', encodedDateString); // Log the encoded date string
        console.log('Decoded date:', decodedDate); // Log the decoded date
    }

    // Function to calculate the time until the next midnight
    function getTimeUntilMidnight() {
        let now = new Date();
        let nextMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        return nextMidnight - now;
    }

    // Schedule the first update
    updateDate();
    let timeUntilNextUpdate = getTimeUntilMidnight();
    setTimeout(function scheduleNextUpdate() {
        updateDate();
        timeUntilNextUpdate = getTimeUntilMidnight();
        setTimeout(scheduleNextUpdate, timeUntilNextUpdate);
    }, timeUntilNextUpdate);

    const columns = [
        {
            name: 'Session Info',
            cell: (row) => row?.sessionInfo,
        },
        {
            name: "Consultation Name",
            cell: (row) => row?.consultationName,
        },
        {
            name: "User Email",
            cell: (row) => row?.userEmail,
        },
        {
            name: "Contact",
            cell: (row) => row?.contact,
        },
        {
            name: "Session Date",
            cell: (row) => row?.sessionDate,
        },
        {
            name: 'Session Number',
            cell: (row) => row?.sessionNumber,
        },
        {
            name: 'Case Study Upload',
            cell: (row) => (
                <div >
                    {
                        row?.caseStudyLink != null ?
                            <div className='btnFileUpload' >
                                <button className='pdfBtn'><a href={`${API_END_POINT}${row?.caseStudyLink}`} target='_blank' rel="noopener noreferrer">View</a></button>
                                <div className="custom-file-input">
                                    <input
                                        type="file"
                                        id="fileInput"
                                        accept="application/pdf"
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleFileUpload(e, row?.allottedSessionId, getConsultationList())}
                                    />
                                    <button className='uploadFile'>
                                        <label htmlFor="fileInput">
                                            Upload file
                                        </label>
                                    </button>
                                </div>

                            </div> : <div className="custom-file-input buttonend" >
                                <input
                                    type="file"
                                    id="fileInput"
                                    accept="application/pdf"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileUpload(e, row?.allottedSessionId, getConsultationList())}
                                />
                                <button className='uploadFile'>
                                    <label htmlFor="fileInput">
                                        Upload file
                                    </label>
                                </button>
                            </div>

                    }
                </div>
            ),
        },
        {
            name: 'Is Session Completed?',
            cell: (row) => (
                <div className='flex align-center gap-10'>
                    {row?.caseStudyLink != null ? <span className='span'>YES</span> : <span className='span'>NO</span>}
                </div>
            ),
        },
    ];

    const handleFileUpload = (event, allottedSessionId) => {
        const file = event.target.files[0];
        if (file) {

            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${TOKEN}`);

            const formData = new FormData();
            formData.append('File', file);
            formData.append('sessionId', allottedSessionId);
            console.log("This is id : " + allottedSessionId)

            fetch(`${API_END_POINT}consultationDateUserMapper/uploadCaseStudy?File=null&mapperSessionId=${allottedSessionId}`, {
                method: 'POST',
                headers: myHeaders,
                body: formData,
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Success:', data.data);
                    toast.success("File Uploaded Successfully!");
                    getConsultationList();

                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    };

    const handleSaveRow = (session, rowId, pdfLink) => {
        console.log(session);
        // session = true;
        getConsultationList();
        if (pdfLink == null) {
            toast.error(" Upload File !");
        }
        else {
            setBtnName(true);
            toast.success("Status : Session Completed");
            getConsultationList();
        }

    };

    const getConsultationList = async () => {
        try {
            setLoading(true);
            const headers = {
                Authorization: `Bearer ${TOKEN}`
            };

            // const response = await axios.get(`${API_END_POINT}consultationDateUserMapper/todaySessions?page=0&size=100&sessionDate=${decodedDate}%2019%3A00%3A00`, { headers });
            const response = await axios.get(`${API_END_POINT}consultationDateUserMapper/getSessions?page=0&size=100`, { headers });
            setLoading(false);
            setMagazinesList(response?.data?.data)
        } catch (error) {
            setMagazinesList([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        // async function fetchData() {
        //     setMagazinesList(await getConsultationList())
        // }
        // fetchData();
        getConsultationList()
    }, []);

    return (
        <main className='main'>
            {magazinesList && (
                <section className='section'>
                    <h3 className='heading'>Session</h3>
                    <div style={{ marginTop: "10px" }}>
                        <CommonTable columns={columns} data={magazinesList} />
                    </div>
                </section>
            )}
            {loading && <Loader />}
        </main>
    )
}

export default Session;
