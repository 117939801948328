import React, { useState, useEffect, useRef } from 'react'
// import "./Session.css"
import CommonTable from '../../components/Table/CommonTable'
import { toast } from "react-toastify";
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import { TOKEN, API_END_POINT } from '../../constant';

const SessionPooja = () => {
    const [magazinesList, setMagazinesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('No')
    const [completedSessions, setCompletedSessions] = useState(new Set());

    const columns = [
        {
            name: 'User Name',
            cell: (row) => row?.userName,
        },
        {
            name: "User Id",
            cell: (row) => row?.userId,
        },
        {
            name: "User Email",
            cell: (row) => row?.userEmail,
        },
        {
            name: "User Mobile",
            cell: (row) => row?.contact,
        },
        {
            name: "Pooja Date",
            cell: (row) => row?.poojaDate,
        },
        {
            name: "Pooja Session Id",
            cell: (row) => row?.poojaSessionId,
        },
        {
            name: 'Pooja Completed',
            cell: (row) => (
                <div className='btn'>
                    <button style={{ padding: '7px 15px' }} onClick={() => {
                        updatePoojaStatus(row?.poojaSessionId)
                    }}>
                        {completedSessions.has(row?.poojaSessionId) ? "Yes" : "No"}
                    </button>
                </div>
            ),
        },
    ];

    const updatePoojaStatus = async (id) => {
        const headers = {
            Authorization: `Bearer ${TOKEN}`
        };
        try {
            const resp = await axios.put(`${API_END_POINT}userPoojaMapper/updatePoojaStatus`, {
                'mapperId': id,
                'isPoojaCompleted': true
            }, { headers })
            console.log("Resp in updatepoojastatus :", resp)
            // setStatus('yes')
            toast.success("Pooja Status successfully");
            setCompletedSessions(prev => new Set(prev).add(id));
        } catch (error) {
            console.log(error)
            toast.error("Unable To Update Pooja Status");
        }
    }

    const getConsultationList = async () => {
        try {
            setLoading(true);
            const headers = {
                Authorization: `Bearer ${TOKEN}`
            };
            const response = await axios.get(`${API_END_POINT}userPoojaMapper/getPresentDayPooja`, { headers });
            // console.log('Res from todays pooja is', response)
            setLoading(false);
            setMagazinesList(response?.data?.data)

        } catch (error) {
            setMagazinesList([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        getConsultationList()
    }, []);

    return (
        <main className='main'>
            {magazinesList && (
                <section className='section'>
                    <h3 className='heading'>Session</h3>
                    <div style={{ marginTop: "10px" }}>
                        <CommonTable columns={columns} data={magazinesList} />
                    </div>
                </section>
            )}
            {loading && <Loader />}
        </main>
    )
}

export default SessionPooja;
