
import React, { useState, useEffect } from 'react';
import './ConsultationDateChange.css';
import CommonTable from '../../components/Table/CommonTable';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import { TOKEN, API_END_POINT } from '../../constant';


const ConsultationDateChange = () => {
    const [poojaList, setPoojaList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [suggestedDate, setSuggestedDate] = useState({});
    const [showCalendar, setShowCalendar] = useState({});
    const [inactiveSuggestButton, setInactiveSuggestButton] = useState({});

    const getPoojaList = async () => {
        try {
            setLoading(true);
            const headers = {
                Authorization: `Bearer ${TOKEN}`
            };
            const response = await axios.get(`${API_END_POINT}userConsultationMapper/approvalList`, { headers });
            setLoading(false);
            setPoojaList(response?.data?.data || []);
        } catch (error) {
            console.error('Error fetching Consultation list:', error);
            setPoojaList([]);
            setLoading(false);
        }
    };

    const updateDateChange = async (mapperId, approve, statusId) => {
        try {
            const headers = {
                Authorization: `Bearer ${TOKEN}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.put(
                `${API_END_POINT}userConsultationMapper/approveConsultationDate`,
                { mapperId, value: approve, statusId: statusId },
                { headers }
            );

            if (response.status === 200) {
                toast.success(`Requested Consultation Date ${approve ? 'Approved' : 'Rejected'} Successfully!`);
                setPoojaList(prevList =>
                    prevList.map(item =>
                        item.consultationDateMapperId === mapperId ? { ...item, statusId: statusId } : item
                    )
                );
                console.log(`Status updated for Pooja Mapper ID ${mapperId} to ${statusId}`);
            }
        } catch (error) {
            console.error('Error updating Consultation day status:', error);
            toast.error('Error updating Consultation day status.');
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const suggestDate = async (mapperId) => {
        const selectedDate = suggestedDate[mapperId];
        if (!selectedDate) {
            toast.error('Please select a date before submitting.');
            return;
        }
        try {
            const formattedDate = formatDate(selectedDate);
            const headers = {
                Authorization: `Bearer ${TOKEN}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.put(
                `${API_END_POINT}userConsultationMapper/approveConsultationDate`,
                { mapperId, value: false, statusId: 4, suggestedDate: formattedDate },
                { headers }
            );

            if (response.status === 200) {
                toast.success('Suggested date submitted successfully!');
                setShowCalendar(prev => ({ ...prev, [mapperId]: false }));
                setInactiveSuggestButton(prev => ({ ...prev, [mapperId]: true }));
                setPoojaList(prevList =>
                    prevList.map(item =>
                        item.poojaMapperId === mapperId ? { ...item, statusId: 4 } : item
                    )
                );
            }
        } catch (error) {
            console.error('Error suggesting date:', error);
            toast.error('Error suggesting date.');
        }
    };

    useEffect(() => {
        getPoojaList();
    }, []);

    const getStatusText = (statusId) => {
        switch (statusId) {
            case 1:
                return 'Pending';
            case 2:
                return 'Approved';
            case 3:
                return 'Rejected';
            case 4:
                return 'New Date Suggested';
            default:
                return 'Unknown';
        }
    };

    const getStatusColor = (statusId) => {
        switch (statusId) {
            case 2:
                return 'green';
            case 3:
                return 'red';
            case 4:
                return 'blue';
            default:
                return 'black';
        }
    };

    const columns = [
        {
            name: 'User Name',
            cell: (row) => row?.userName,
        },
        {
            name: 'Contact No.',
            cell: (row) => row?.userMobileNo,
        },
        {
            name: 'Consultation Id',
            cell: (row) => row?.consultationDateMapperId,
        },
        {
            name: 'Consultation Name',
            cell: (row) => row?.consultationName,
        },
        {
            name: 'Current Consultation Date',
            cell: (row) => row?.oldConsultationDate,
        },
        {
            name: 'Requested Consultation Date',
            cell: (row) => row?.newConsultationDate,
        },
        {
            name: 'Suggest Another Date',
            cell: (row) => (
                showCalendar[row?.consultationDateMapperId] ? (
                    <div className='ButtonDivider'>
                        <input
                            type="date"
                            className="date-picker"
                            onChange={(e) => setSuggestedDate({ ...suggestedDate, [row?.consultationDateMapperId]: e.target.value })}
                        />
                        <button
                            className="submit-date-button"
                            onClick={() => suggestDate(row?.consultationDateMapperId)}
                        >
                            Submit Date
                        </button>
                    </div>
                ) : (
                    <button
                        className={`suggest-date-button ${inactiveSuggestButton[row?.consultationDateMapperId] || row?.statusId !== 1 ? 'inactive' : ''}`}
                        onClick={() => setShowCalendar({ ...showCalendar, [row?.consultationDateMapperId]: true })}
                        disabled={row?.statusId !== 1}
                    >
                        Suggest Date
                    </button>
                )
            ),
        },
        {
            name: 'Accept Requested Date',
            cell: (row) => (
                <>
                    <button
                        className="approve-button"
                        onClick={() => updateDateChange(row?.consultationDateMapperId, true, 2)}
                        disabled={row?.statusId !== 1}
                    >
                        ✅
                    </button>
                    {/* <button
                        className="reject-button"
                        onClick={() => updateDateChange(row?.consultationDateMapperId, false, 3)}
                        disabled={row?.statusId !== 1}
                    >
                        ❌
                    </button> */}
                </>
            ),
        },
        {
            name: 'Status',
            cell: (row) => (
                <span style={{ color: getStatusColor(row?.statusId) }}>
                    {getStatusText(row?.statusId)}
                </span>
            ),
        }
    ];

    // return (
    //     <main className='main'>
    //         {poojaList.length > 0 && (
    //             <section className='section'>
    //                 <h3 className='heading'> Consultation Date Change Requests</h3>
    //                 <div style={{ marginTop: '10px' }}>
    //                     <CommonTable columns={columns} data={poojaList} />
    //                 </div>
    //             </section>
    //         )}
    //         {loading && <Loader />}
    //     </main>
    // );


    return (
        <>

            <main className='main'>
                <section className='section'>
                    {
                        poojaList.length === 0 ? <div className="NoConsultation"><p className='NoConsultation'>Currently there is no Consultation Available</p></div> : <>
                            <h3 className='heading'>Consultation Date Change Requests</h3>
                            <div style={{ marginTop: '10px' }}>
                                <CommonTable columns={columns} data={poojaList} />
                            </div>
                        </>
                    }
                </section>
                {loading && <Loader />}
            </main>

        </>
    );
};

export default ConsultationDateChange;
