import React from 'react'
import "./Navbar.css"

const Navbar = () => {

  const handleHamburger = ()=>{
    const body = document.querySelector('body');
    body.classList.toggle("handleHamburger")
  }
  
  return (
    <header>
        <nav className='navbar flex align-center justify-between'>
            <div className='hamburger flex align-center'>
                <svg width="35" height="35" fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" onClick={handleHamburger}>
                  <path d="M4 6h16v2H4V6Zm0 5h16v2H4v-2Zm0 5h16v2H4v-2Z"></path>
                </svg>
            </div>
        </nav>
    </header>
  )
}

export default Navbar