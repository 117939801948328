import React, { useState, useEffect } from 'react';
import CommonTable from '../Table/CommonTable';
import "./GemstoneTable.css"
import axios from 'axios';
import { API_END_POINT, TOKEN } from '../../constant';
import moment from 'moment';
import { FaPaperPlane, FaEdit } from 'react-icons/fa';
import { toast } from "react-toastify";


const GemstoneTable = () => {
    const [data, setData] = useState(null);
    const [showTrackingIdInput,setShowTrackingIdInput] = useState({});
    const [trackingIdValue,setTrackingIdValue] = useState({});
    
    const fetchDataFromAPI = async () => {
        try {
            const response = await axios.get(`${API_END_POINT}gemstonePurchased/getGemstonePurchasedData`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                },
            });

            response?.data?.data?.map((data)=>{
                showTrackingIdInput[data.id] = data?.paymentDetailsId;
               return <></>
            })
            setShowTrackingIdInput({...showTrackingIdInput})
            return response.data;
        } catch (error) {
            throw new Error(`Error fetching data from API: ${error.message}`);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedData = await fetchDataFromAPI();
                setData(fetchedData?.data);
            } catch (error) {
                console.log(error)
            }
        };

        fetchData();
    }, []);

    const handleEditOrSubmit = (e,id,value,trackingIdInputid) => {
        if(e?.target?.title === "Add"){
            if(value === null || value === ""){
                showTrackingIdInput[trackingIdInputid] = null;
            }else{
                showTrackingIdInput[trackingIdInputid] = trackingIdValue[id];
            }
            updateGemstoneTrackingId(id,trackingIdValue[id])
            trackingIdValue[id] = "";
        }else if(e?.target?.title === "Edit"){
            showTrackingIdInput[trackingIdInputid] = null;
            trackingIdValue[id] = value;
        }
        setTrackingIdValue({...trackingIdValue})
        setShowTrackingIdInput({...showTrackingIdInput});
    };

    const updateGemstoneTrackingId = (id,TrackingId) => {
        const myHeaders = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${TOKEN}`,
        };

        const raw = JSON.stringify({
            "actionInfo": {
                "actionId": "String"
            },
            "formData": {
                "gemstoneTrackingId": TrackingId
            },
            "sessionData": {
                "userDetails": {
                    "isAdmin": 0,
                    "isAllAccess": 0,
                    "langCode": "String",
                    "userEmailId": "String",
                    "userId": 0,
                    "userName": "String"
                }
            }
        });

        const requestOptions = {
            headers: myHeaders
        };

        axios.put(`${API_END_POINT}gemstonePurchased/updateGemstoneTrackingId?gemStoneId=${id}`, raw, requestOptions)
            .then(async() => {
                toast.success("Gemstone Tracking Id Updated Successfully!");
                const fetchedData = await fetchDataFromAPI();
                setData(fetchedData?.data);
            })
            .catch(() => {
                toast.error("Unable To update Gemstone Tracking Id");
            });
    };
    
    
    const columns = [
        {
            name: <h3>Gemstone Name</h3>,
            selector: (row) => <span title={row?.gemstone?.gemstoneName === null ? "-" : row?.gemstone?.gemstoneName}>{row?.gemstone?.gemstoneName === null ? "-" : row?.gemstone?.gemstoneName}</span>,
        },
        {
            name: <h3>OrderedDate</h3>,
            selector: (row) => <span title={row?.orderDate === null ? "-" : moment(row?.orderDate).format('DD-MM-YYYY')}>{row?.orderDate === null ? "-" : moment(row?.orderDate).format('DD-MM-YYYY')}</span>,
        },
        {
            name: <h3>Tracking Id</h3>,
            selector: (row,index) => (
                <div className="tracking-container">
                    {showTrackingIdInput[row.id] ? (
                        <div className="tracking-display">
                            <span className="tracking-id">{row?.paymentDetailsId}</span>
                            <button onClick={(e) => handleEditOrSubmit(e,row?.gemstone?.id,row?.paymentDetailsId,row.id)} title='Edit' className="edit-button flex align-center justify-center">
                                <FaEdit className="tracking-icon"/>
                            </button>
                        </div>
                    ) : (
                        <div className='InputNsend flex align-center justify-center'>
                            <input type="text" id={`trackingIdInput`} value={trackingIdValue[row?.gemstone?.id]}  onChange={(e)=>{
                                trackingIdValue[row?.gemstone?.id] = e.target.value;
                                setTrackingIdValue({...trackingIdValue})
                            }} className="tracking-input" placeholder="Enter Tracking ID" />
                            <button onClick={(e) => handleEditOrSubmit(e,row?.gemstone?.id,row?.paymentDetailsId,row.id)} title='Add' className="tracking-button flex align-center justify-center">
                                <FaPaperPlane className="tracking-icon" />
                            </button>
                        </div>
                    )}
                </div>
            )
        },
        {
            name: <h3>Name</h3>,
            selector: (row) => <span title={row?.user?.userName === null ? "-" : row?.user?.userName}>{row?.user?.userName === null ? "-" : row?.user?.userName}</span>,
        },
        {
            name: <h3>Mobile Number</h3>,
            selector: (row) => <span title={row?.user?.mobile === null ? "-" : row?.user?.mobile}>{row?.user?.mobile === null ? "-" : row?.user?.mobile}</span>,
        },
        {
            name: <h3>Email</h3>,
            selector: (row) => <span title={row?.user?.email === null ? "-" : row?.user?.email}>{row?.user?.email === null ? "-" : row?.user?.email}</span>,
        },
        {
            name: <h3>Address</h3>,
            selector: (row) => <span title={row?.user?.address === null ? "-" : row?.user?.address}>{row?.user?.address === null ? "-" : row?.user?.address}</span>
        }
    ];

    return (
        <>
            {data && (
                <CommonTable data={data} columns={columns} />
            )}
        </>
    )
}

export default GemstoneTable;
