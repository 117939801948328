import React, { useEffect, useRef, useState } from 'react';
import { TOKEN } from '../../constant';
import axios from 'axios';
import { API_END_POINT } from '../../constant';
import { useFormik } from 'formik';
import { blogSchema } from '../../schema/Index';
import CommonTable from '../../components/Table/CommonTable';
import { toast } from 'react-toastify';
import Confirmation from '../../components/ConfirmationPopup/Confirmation';
import Loader from '../../components/Loader/Loader';

function Blog() {
  const [initialValues, setInitialValues] = useState({
    blogName: "",
    blogLink: "",
    imageLink: ""
  });

  const [showForm, setShowForm] = useState(false);
  const [blogsList, setBlogsList] = useState(null);
  const [btnName, setBtnName] = useState("Add");
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [isConfirmationEdit, setIsConfirmationEdit] = useState(false);
  const [deleteContentId, setDeleteContentId] = useState(0)
  const [isConfirmationDelete, setIsConfirmationDelete] = useState(false);
  const [confirmUpdate, setConfirmUpdate] = useState(0);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    validationSchema: blogSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log(btnName);
      console.log(values);
      if (btnName === "Save") {
        setIsConfirmationEdit(true)
      } else if (btnName === "Add") {
        // await addNewBlogs(values);
        let fileId = '';
        if (fileInputRef.current.files[0]) {
          const imageResponse = await uploadBlogFile(fileInputRef.current.files[0]);
          fileId = imageResponse?.data?.fileId; // Adjust based on your API response
          console.log('fileId:', fileId)
        }
        await addNewBlogs(values, fileId);
        setShowForm(false);
        resetForm();
      }
    },
    enableReinitialize: true,
  });

  const addRow = () => {
    if (btnName === "Save") {
      setBtnName("Add");
    }
    setInitialValues({
      blogName: "",
      blogLink: "",
      imageLink: ""
    });
    setShowForm(!showForm);
  };

  const columns = [
    {
      name: 'Sr. No.',
      cell: (row, index) => index + 1,
    },
    {
      name: "Blog Name",
      cell: (row) => row?.blogName,
    },
    {
      name: "Blog Link",
      cell: (row) => row?.blogLink,
    },
    {
      name: "Image",
      cell: (row) => <img
        title='Click to see Image'
        src={`${API_END_POINT}${row?.imagesLink}`}
        width={"auto"}
        height={"70"}
        alt="Blog Image"
      />
    },
    {
      name: 'Actions',
      cell: (row, index) => (
        <div className='flex align-center gap-10'>
          <button className='flex align-center edit-btn' onClick={() => updateBlog(row.id)}>
            <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="m7 17.011 4.413-.015 9.632-9.54c.378-.378.586-.88.586-1.414 0-.534-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.581v4.43ZM18.045 4.456l1.589 1.583-1.597 1.582-1.586-1.585 1.594-1.58ZM9 13.416l6.03-5.974 1.586 1.586L10.587 15 9 15.004v-1.589Z"></path>
              <path d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2Z"></path>
            </svg>
          </button>
          <button className='flex align-center delete-btn-icon' onClick={() => deleteblog(row.id)}>
            <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2v12ZM9 4h6v2H9V4ZM8 8h9v12H7V8h1Z"></path>
              <path d="M9 10h2v8H9v-8Zm4 0h2v8h-2v-8Z"></path>
            </svg>
          </button>
        </div>
      ),
    },
  ];

  //Retrieves the list of blogs.
  const getBlogList = async () => {
    try {
      setLoading(true);
      // Define request headers
      const headers = {
        Authorization: `Bearer ${TOKEN}`
      };
      const response = await axios.get(`${API_END_POINT}blogs/getBlogs`, { headers });
      setLoading(false);
      console.log("Response through blog.jsx is :", response)
      return response?.data.data;
    } catch (error) {
      setBlogsList([]);
      setLoading(false);
    }
  };

  const uploadBlogFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post(`${API_END_POINT}fileAttachment/uploadImages`, formData, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log("Response from image upload in Blog.jsx is", response)
      return response.data;
    } catch (error) {
      throw new Error(`Error uploading image: ${error.message}`);
    }
  }

  // Adds a new blog.
  const addNewBlogs = async (values, fileId) => {
    try {
      await axios.post(`${API_END_POINT}blogs/save`, {
        link: values.blogLink,
        title: values.blogName,
        fileId: fileId
      }, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      });

      setBlogsList(await getBlogList());
      toast.success("Blog Added Successfully!");
    } catch (error) {
      console.log(error);
      toast.error("Unable To Add Blog");
    }
  };

  // Deletes a blog by its ID.
  const deleteblog = async (id) => {
    setIsConfirmationDelete(true);
    setDeleteContentId(id);
  };

  // Delete Action confirm popup
  const confirmActionDelete = async (data) => {
    try {
      // Define request headers
      const headers = {
        Authorization: `Bearer ${TOKEN}`
      };

      // Make DELETE request using Axios
      await axios.delete(`${API_END_POINT}blogs/delete/${deleteContentId}`, {
        headers
      });
      setBlogsList(await getBlogList())
      toast.success("Blog Deleted successfully");

    } catch (error) {
      toast.error("Unable To Delete Blog");
    }
  }

  // ----- this is for close confirm delete popup -----
  const closeConfirmDeletePopUp = () => {
    setIsConfirmationDelete(false)
  }


  const updateBlog = async (id) => {
    setBtnName("Save");

    // Find the magazine in the list based on its ID
    let previousData = blogsList.find((data) => data.id === id);
    // Set initial form values for updating
    setInitialValues({
      blogName: previousData?.blogName,
      blogLink: previousData?.blogLink,
      imageLink: previousData?.imagesLink
    });

    // Show the form for editing
    setShowForm(true);

    // Set the ID of the item being updated
    setConfirmUpdate(id);
  };

  // ----- this is for close confirm Edit popup -----
  const closeConfirmEditPopUp = () => {
    setIsConfirmationEdit(false)
  }

  // confirmupdate 
  const ConfirmUpdateBlog = async (info) => {
    try {
      // Define request headers
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`
      };

      // Define request body
      const data = {
        title: info?.blogName,
        link: info?.blogLink,
      };

      // Make PUT request using Axios
      let response = await axios.put(`${API_END_POINT}blogs/update/${confirmUpdate}`, data, {
        headers
      });
      // console.log("ConfirmUpdateBlog:", response?.data);
      fileInputRef.current.files[0] && updateBlogFile(response?.data?.data?.id);
      setShowForm(false);
      toast.success("Blog Updated successfully");
      setBlogsList(await getBlogList());
      setBlogsList(await getBlogList());
    } catch (error) {
      toast.error("Unable To Update Blog");
    }
  }

  const updateBlogFile = async (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${TOKEN}`);

    const formdata = new FormData();
    console.log("Id in Blog.jsx is", id);
    console.log("file in blog.jsx is ", fileInputRef.current.files[0]);
    formdata.append("File", fileInputRef.current.files[0], fileInputRef.current.files[0].name);
    formdata.append("blogId", id);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow"
    };

    try {
      await fetch(`${API_END_POINT}blogs/upload`, requestOptions);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    async function fetchData() {
      setBlogsList(await getBlogList());
    }
    fetchData();
  }, []);

  return (
    <main className='main'>
      {
        blogsList && <section>
          <h3 className='heding'>Blog</h3>
          <div style={{ marginTop: "10px" }} className='flex align-center justify-end '>
            <button onClick={addRow} className='common-btn flex align-center'>
              {!showForm ? <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6v-2Z"></path>
              </svg> :
                <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242-1.414-1.414Z"></path>
                </svg>}
            </button>
          </div>
          {showForm && (
            <form onSubmit={handleSubmit} className='flex flex-wrap aign-center gap-5 magazinesConfigurationForm form '>
              <div className='input-field flex flex-col'>
                <label htmlFor="blogName">Blog Name</label>
                <input
                  id='blogName'
                  type="text"
                  name="blogName"
                  value={values.blogName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="enter blog name"
                  className={`${(errors.blogName && touched.blogName) && 'error'}`}
                />
              </div>
              <div className='input-field flex flex-col'>
                <label htmlFor="blogLink">Blog Link</label>
                <input
                  id='blogLink'
                  type="text"
                  name="blogLink"
                  value={values.blogLink}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className={`${(errors.blogLink && touched.blogLink) && 'error'}`}
                  placeholder="enter blog link"
                />
              </div>
              <div className='input-field flex flex-col'>
                <label htmlFor="imageLink">Select Image</label>
                {/* Hidden file input */}
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={(e) => {
                    setFieldValue("imageLink", e?.target?.files[0]?.name);
                    console.log("File :", e?.target?.files[0]?.name)
                  }}
                />
                <input
                  id='imageLink'
                  type="text"
                  name="imageLink"
                  value={values.imageLink}
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='select Image'
                  readOnly
                  className={`${(errors.imageLink && touched.imageLink) && 'error'}`}
                />
              </div>
              <div className='buttons flex flex-col' style={{ alignItems: "flex-end" }}>
                <label htmlFor="btn" style={{ visibility: "hidden", marginBottom: "5px" }}>hidden</label>
                <button type="submit" id='btn' className='btn' style={{ width: "100%" }}>{btnName}</button>
              </div>
            </form>
          )}
          <div style={{ marginTop: "10px" }}>
            <CommonTable columns={columns} data={blogsList} value={false} />
          </div>
        </section>}

      {/* ----- confirmation Delete popup --- */}
      <Confirmation text={"Do you want to delete this Blog"} confirmAction={confirmActionDelete} closeConfirmPopUp={closeConfirmDeletePopUp} isConfirmationOpen={isConfirmationDelete} />

      {/* ----- confirmation Edit popup --- */}
      <Confirmation text={"Do you want to Save this Blog"} confirmAction={ConfirmUpdateBlog} closeConfirmPopUp={closeConfirmEditPopUp} isConfirmationOpen={isConfirmationEdit} data={values} />


      {/* ------- loader here ------- */}
      {loading && <Loader />}
    </main>
  );
}

export default Blog;
