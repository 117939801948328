import * as Yup from 'yup';

export const messageSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  body: Yup.string().required('Body is required'),
  // file: Yup.mixed().required('Image is required'),
});

export const setupDoubtSessionSchema = Yup.object().shape({
  price: Yup.number().positive('Session Price must be a positive number').required('Session Price is required'),
  doubtSessionLink: Yup.string().url('Enter a valid URL for Meeting Link').required('Meeting Link is required'),
});

export const gemstoneConfigurationSchema = Yup.object().shape({
  dropdown: Yup.string().required('Category is required'),
  name: Yup.string().required('Gemstone name is required'),
  IndianName: Yup.string().required('Gemstone indian name is required'),
  size: Yup.number().required('Size is required'),
  price: Yup.number().required('Price is required'),
  discription: Yup.string().required('Gemstone discription is required'),
  purpose: Yup.string().required('Gemstone purpose is required'),
  howToWear: Yup.string().required('How To Wear Gemstone is required'),
  symbolizes: Yup.string().required('Gemstone Symbolizes is required'),
  // image: Yup.mixed().required('Image is required'),
});

export const magazinesConfigurationSchema = Yup.object().shape({
  dropdown: Yup.string().required('Category is required'),
  name: Yup.string().required('Gemstone name is required'),
  // image: Yup.mixed().required('Image is required'),
  pdf: Yup.mixed().required('pdf is required'),
  price: Yup.number().required('Price is required'),
});

export const couponCodeSchema = Yup.object().shape({
  couponName: Yup.string().required('Coupon code name is required'),
  couponCode: Yup.string().required('Coupon code is required'),
  couponAmount: Yup.number().required('Coupon amount is required'), // Added couponAmount validation
  couponCodeLimit: Yup.number().required('Coupon code limit is required'),
  expiryDate: Yup.date().required('Expiry date is required'),
  module: Yup.string().required('Module is required'),
  subModule: Yup.string().required('Sub module is required'),
});

export const coursesConfigurationSchema = Yup.object().shape({
  courseSessionTitleEnglish: Yup.string().required('course session title in English is required'),
  courseSessionTitleHindi: Yup.string().required('course session title in Hindi is required'),
  courseSessionTitleMarathi: Yup.string().required('course session title in Marathi is required'),
  courseSessionTitleGujarati: Yup.string().required('course session title in Gujarati is required'),
  videoStreamLink: Yup.mixed().required('video stream link is required')
});


export const LoginSchema = Yup.object().shape({
  userName: Yup.string()
    .required('Username is required')
    .min(3, 'Username must be at least 3 characters long'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters long')
});

export const consultationSchema = Yup.object().shape({
  // dropdown: Yup.string().required('Category is required'),
  consultationName: Yup.string().required('Consultation name is required'),
  image: Yup.mixed().required('Image is required'),
  noOfSessions: Yup.number().required('No. Of Sessions is required'),
  planPrice: Yup.number().required('Price is required'),
  planValidity: Yup.number().required('Plan Validity is required'),
  perSessionTime: Yup.number().required('per Session Time is required'),
  consultationInfo: Yup.string().required('Consultation Info is required'),
  // consultationSessionId: Yup.number().required('Consultation Session Id is required'),
});

export const blogSchema = Yup.object().shape({
  blogName: Yup.string().required('Blog name is required'),
  blogLink: Yup.string().required('Blog link is required'),
  imageLink: Yup.mixed().required('Image is required'),
});

export const popupSchema = Yup.object().shape({
  popupMessage: Yup.string().required('Blog name is required'),
  uploadFile: Yup.mixed().required('Image is required'),
});

export const poojaSchema = Yup.object().shape({
  poojaName: Yup.string().required('Pooja name is required'),
  process: Yup.string().required('Process link is required'),
  benefits: Yup.string().required('Benefits is required'),
  poojaInfo: Yup.string().required('Blog info is required'),
  imageLink: Yup.mixed().required('Image is required'),
  price: Yup.mixed().required('Pooja price is required'),
  poojaTime: Yup.mixed().required('Pooja time is required'),
});

