import React from 'react'
import "./ApiFails.css"
import { ShowWhenAPIFail, sectionName } from "../../HardCodedData"
import Box from '../Box/Box'
const ApiFails = () => {
 
   return (
      <>
         {
         Object.values(ShowWhenAPIFail[0]).map((objectData, key) => {
               return (
                  <section className='section' key={key}>
                     <h3 className='heding'>{sectionName[key]}</h3>
                     <div className='content grid'>
                        {objectData.map((innerData, innerKey) => {
                           return (<Box title={innerData?.name} todaysCount={innerData?.today} allCount={innerData?.total} viewList={""} dateTime= {null} key={innerKey} />)
                        })}
                     </div>

                  </section>
               )
         })
         }
      </>
   )
}

export default ApiFails