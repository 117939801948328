import React, { useState, useEffect } from 'react'
import "./Home.css"
import Box from '../../components/Box/Box'
import axios from 'axios';
import Loader from '../../components/Loader/Loader';
import ApiFails from '../../components/APIFails/ApiFails';
import { API_END_POINT, TOKEN, getToken } from '../../constant';
import { sectionName } from '../../HardCodedData';
import CoursesDoubtSession from '../../components/CoursesDoubtSessionTable/CoursesDoubtSession';
import GemstoneTable from '../../components/GemstoneTable/GemstoneTable';
import MagazineTable from '../../components/MagazineTable/MagazineTable';

const Home = () => {
   const [data, setData] = useState(null);
   const [error, setError] = useState(null);
   const [loading, setLoading] = useState(false);
   const [showBookedDoubtSessionTable, setShowBookedDoubtSessionTable] = useState(false);
   const [showPurchasedGemstoneTable, setShowPurchasedGemstoneTable] = useState(false);

   const viewBookedDoubtSessionList = (viewListText, setViewListText) => {
      viewListText === "View List" ? setShowBookedDoubtSessionTable(true) : setShowBookedDoubtSessionTable(false)
      viewListText === "View List" ? setViewListText("Close List") : setViewListText("View List")
   }

   const viewPurchasedGemstoneList = (viewListText, setViewListText) => {
      viewListText === "View List" ? setShowPurchasedGemstoneTable(true) : setShowPurchasedGemstoneTable(false)
      viewListText === "View List" ? setViewListText("Close List") : setViewListText("View List")
   }

   const fetchDataFromAPI = async () => {
      try {
         setLoading(true);
         const response = await axios.get(`${API_END_POINT}ReportTransactionSummary/ReportSummary`, {
            headers: {
               Authorization: `Bearer ${TOKEN}`,
            },
         });
         setLoading(false)
         return response?.data;
      } catch (error) {
         setLoading(false)
         throw new Error(`Error fetching data from API: ${error.message}`);
      }
   }

   useEffect(() => {
      const fetchData = async () => {
         try {
            const fetchedData = await fetchDataFromAPI();
            setError(null)
            if (fetchedData === null || fetchedData === undefined || (Array.isArray(fetchedData) && (fetchedData.length === 0))) {
               setData(null)
               setError("error")
            } else {
               setData(fetchedData)
            }
         } catch (error) {
            setError(error);
         }
      };

      fetchData();
      getToken();
   }, []);

   return (
      <main className='main'>
         {
            data && Object.values(data[0]).map((objectData, key) => {
               return (
                  <section className='section' key={key}>
                     <h3 className='heding'>{sectionName[key]}</h3>
                     <div className='content grid'>
                        {sectionName[key].toLowerCase() !== "magazines" && objectData.map((innerData, innerKey) => {
                           return <>
                              <Box
                                 title={innerData?.name}
                                 todaysCount={innerData?.today}
                                 allCount={innerData?.total}
                                 viewList={sectionName[key] === "doubt Session Booking summary" ? viewBookedDoubtSessionList : sectionName[key] === "gemstone" ? viewPurchasedGemstoneList : ""}
                                 dateTime={sectionName[key] === "doubt Session Booking summary" ? innerData?.sessionStartDate : null}
                                 key={innerKey} />
                           </>
                        })}
                     </div>

                     {sectionName[key].toLowerCase() === "magazines" && <MagazineTable data={objectData} />}

                     {/* ----------- doubt Session booking summary table here ------ */}
                     {(sectionName[key] === "doubt Session Booking summary" && showBookedDoubtSessionTable) && <div style={{ marginTop: "6px" }}>
                        <div style={{ marginBottom: "6px" }}>
                           <h3 className='heding'>Booked Doubt Sessions List</h3>
                        </div>
                        <CoursesDoubtSession />
                     </div>}

                     {/* -------------- Gemstone table here -------- */}
                     {(sectionName[key] === "gemstone" && showPurchasedGemstoneTable) && <div style={{ marginTop: "6px" }}>
                        <div style={{ marginBottom: "6px" }}>
                           <h3 className='heding'>Purchased Gemstone List</h3>
                        </div>
                        <GemstoneTable />
                     </div>}

                  </section>
               )
            })
         }

         {(error !== null) && <ApiFails />}
         {loading && <Loader />}

      </main>
   )
}

export default Home