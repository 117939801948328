import React, { useEffect, useRef, useState } from 'react'
import "./BannerUploadPooja.css"
import axios from 'axios';
import { toast } from "react-toastify";
import { API_END_POINT, TOKEN } from '../../constant';
import CommonTable from '../../components/Table/CommonTable';

const BannerUploadPooja = () => {
  const [data, setData] = useState([{}]);
  const fileInputs = useRef([...Array(10)].map(() => React.createRef()));

  const uploadBanner = async (e, id, name1) => {
    const spinner = document.getElementById(`spinner${id}`);
    try {
      const formData = new FormData();
      formData.append('imageLinks', e.target.files[0]);
      formData.append('courseId', id);
      formData.append('courseName', name1);
      spinner.style.display = "inline-block"
      const response = await axios.post(`${API_END_POINT}pooja/uploadBanners/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      });
      spinner.style.display = "none"
      toast.success(response?.data?.response?.responseMessage);
      return response.data;
    } catch (error) {
      spinner.style.display = "none"
      toast.error("Unable To Upload File.");
    }
  }

  const handleChange = (e) => {
    let id = "hi";
    if (e.target.value.toLowerCase() === "english") {
      id = "en";
    } else if (e.target.value.toLowerCase() === "hindi") {
      id = "hi";
    } else if (e.target.value.toLowerCase() === "marathi") {
      id = "mr";
    } else if (e.target.value.toLowerCase() === "gujarati") {
      id = "gu";
    }
    // setBannerId(id);
    getBannerListingByLanguage(id);
  }

  // ------- get list ----
  const getBannerListingByLanguage = (id) => {
    const headers = {
      "Authorization": `Bearer ${TOKEN}`,
    }

    axios.get(`${API_END_POINT}pooja/getPoojaBannerImages/${id}`, { headers })
      .then((response) => {
        setData(response?.data)
        console.log(response)
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = [
    {
      name: <h3>Course Number</h3>,
      cell: (row) => row?.id,

    },
    {
      name: "Course Name",
      cell: (row) => row?.levelName,
    },
    {
      name: <h3>Action</h3>,
      selector: (row, index) =>
      (<button onClick={() => fileInputs.current[index].current.click()} className='uploadbtn flex align-center gap-5'>
        <svg width="16" height="16" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M3.6 20.4a1.2 1.2 0 0 1 1.2-1.2h14.4a1.2 1.2 0 1 1 0 2.4H4.8a1.2 1.2 0 0 1-1.2-1.2ZM7.552 8.048a1.2 1.2 0 0 1 0-1.696l3.6-3.6a1.2 1.2 0 0 1 1.696 0l3.6 3.6a1.2 1.2 0 0 1-1.696 1.696L13.2 6.497V15.6a1.2 1.2 0 1 1-2.4 0V6.497L9.248 8.048a1.2 1.2 0 0 1-1.696 0Z" clipRule="evenodd"></path>
        </svg>
        <span>Upload Icon</span>
        <span className="spinner" id={`spinner${row?.id}`}></span>
        <input style={{ display: "none" }} type="file" onChange={(e) => uploadBanner(e, row?.id, row?.levelName)} ref={fileInputs.current[index]} />
      </button>
      )
    },
  ]
  useEffect(() => {
    getBannerListingByLanguage("en");
  }, []);
  return (
    <main className='main'>
      <section className='section'>
        <h3 className='heding' style={{ marginBottom: "10px" }}>Pooja Banner Upload</h3>
        <div className='form'>
          <div className='input-field flex flex-col' style={{ maxWidth: "350px", width: "100%" }}>
            <label htmlFor="dropdown">Select Language for Course Icon Upload</label>
            <select name="dropdown" id='dropdown' onChange={handleChange}>
              <option value="English">English</option>
              <option value="Hindi">Hindi</option>
              <option value="Marathi">Marathi</option>
              <option value="Gujarati">Gujarati</option>
            </select>
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          <CommonTable data={data} columns={columns} />
        </div>
      </section>
    </main>
  )
}

export default BannerUploadPooja;