import React from 'react'
import CommonTable from '../Table/CommonTable';

const MagazineTable = ({data}) => {
    const columns = [
        {
            name: <h3>Magazine Name</h3>,
            selector: (row) => row?.name,
        },
        {
            name: <h3>Purchased Today</h3>,
            selector: (row) => row?.today,
        },
        {
            name: <h3>Total</h3>,
            selector: (row) => row?.total
        },
    ];
  return (
    <CommonTable data={data} columns={columns} />
  )
}

export default MagazineTable