import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import Popup from 'reactjs-popup';

const CommonTable = ({ title = "", data, columns, handleSubidClick, hideTable, totalRows, handlePageChange, value }) => {
    const [dynamicHeight, setDynamicHeight] = useState(0);
    const [openPopup, setOpenPopup] = useState(false)

    // console.log("Total row :", totalRows, "Handle Change :");
    // const handlePageChange = () => {

    // }
    // -------- customStyles here --------
    const customStyles = {
        table: {
            style: {
                boxShadow: "0px 0.7758524417877197px 10.086081504821777px 0px #000000",
                whiteSpace: 'normal',
            }
        },
        head: {
            style: {
                fontSize: "0.8rem",
                fontWeight: "bold",
            },
        },
        headCells: {
            style: {
                background: "linear-gradient( 180deg, #530029 0%, #93054b 0.01%, #880445 54.5%, #77003b 100% )",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "15px",
                lineHeight: "21px",
                color: "#ffffff",
                justifyContent: 'center',
                maxWidth: "100% !important",
                minWidth: "200px !important",
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                marginTop: "1px",
                minHeight: "55px",
                height: "fit-content",
                whiteSpace: 'normal',
                backgroundColor: "#ffffff",
                "&:hover": {
                    cursor: "pointer",
                },

            }

        },
        cells: {
            style: {
                color: "#212324",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "19px",
                borderRight: '1px solid #ccc',
                justifyContent: 'center',
                textAlign: "center",
                padding: ".2rem",
                whiteSpace: 'normal',
            },
        },
    };

    useEffect(() => {
        // Calculate the height dynamically
        setDynamicHeight(`calc(100vh - 186px)`)
    }, [])

    const onRowClicked = (row) => {
        // console.log("Row clicked:", row); // Debug log
        console.log(hideTable)
        if (hideTable === false) {
            console.log(row?.subUserId)
            handleSubidClick(row?.subUserId);
        }
    }

    return (
        <div style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
            <DataTable
                title={title}
                columns={columns}
                data={data}
                customStyles={customStyles}
                pagination
                fixedHeader
                highlightOnHover
                theme="solarized"
                fixedHeaderScrollHeight={dynamicHeight}
                fixedHeaderScrollWidth="100px"
                responsive="scroll"
                onRowClicked={onRowClicked} // Ensure this is correctly set
                paginationServer={value} // Set this to true or false
                paginationTotalRows={totalRows} //Specify the total number of rows
                onChangePage={handlePageChange}  //Function to handle page changes
            // paginationRowsPerPageOptions={[1, 10, 15]}  // Dropdown options for rows per page
            />
        </div>
    )
}

export default CommonTable