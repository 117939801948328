import React, { useState, useEffect, useRef } from 'react'
import "./MagazinesConfiguration.css"
import CommonTable from '../../components/Table/CommonTable'
import { magazinesConfigurationSchema } from '../../schema/Index';
import { useFormik } from 'formik';
import { toast } from "react-toastify";
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import { TOKEN, API_END_POINT } from '../../constant';
import Popup from 'reactjs-popup';
import Confirmation from '../../components/ConfirmationPopup/Confirmation';

const MagazinesConfiguration = () => {
    const [initialValues, setInitialValues] = useState({
        dropdown: "",
        name: "",
        image: "",
        pdf: "",
        price: ""
    })
    const [showForm, setShowForm] = useState(false);
    const [btnName, setBtnName] = useState("Add");
    const [magazinesList, setMagazinesList] = useState(null);
    const [magazineCategories, setMagazineCategories] = useState([]);
    const [confirmUpdate, setConfirmUpdate] = useState(0);
    const [isImagePreviewPopUpOpen, setIsImagePreviewPopUpOpen] = useState(false);
    const [previewImageUrl, setPreviewImageUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [isConfirmationDelete, setIsConfirmationDelete] = useState(false);
    const [deleteContentId, setDeleteContentId] = useState(0)
    const [isConfirmationEdit, setIsConfirmationEdit] = useState(false);
    const fileInputRef = useRef(null)


    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        validationSchema: magazinesConfigurationSchema,
        onSubmit: async (values, { resetForm }) => {
            console.log("Indiaaaaaa");
            if (btnName === "Save") {
                setIsConfirmationEdit(true)
            } else if (btnName === "Add") {
                await addNewMagazines(values);
                setShowForm(false);
                resetForm();
            }
        },
        enableReinitialize: true,
    })

    const columns = [
        {
            name: 'Name',
            cell: (row) => row?.magazineTitle,
        },
        {
            name: "Category",
            cell: (row) => row?.magazineCategory,
        },
        {
            name: 'Image',
            cell: (row) => <img title='Click to see Image' onClick={() => showImagePreviewPopUp(row?.imageLink[0])} src={`${API_END_POINT}${row?.imageLink[0]}`} width={50} height={50} alt='magazines' />,
        },
        {
            name: 'Price',
            cell: (row) => row?.magazinePrice,
        },
        {
            name: 'Actions',
            cell: (row, index) => (
                <div className='flex align-center gap-10'>
                    <button className='flex align-center edit-btn' onClick={() => updateMagazines(row.id)}>
                        <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m7 17.011 4.413-.015 9.632-9.54c.378-.378.586-.88.586-1.414 0-.534-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.581v4.43ZM18.045 4.456l1.589 1.583-1.597 1.582-1.586-1.585 1.594-1.58ZM9 13.416l6.03-5.974 1.586 1.586L10.587 15 9 15.004v-1.589Z"></path>
                            <path d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2Z"></path>
                        </svg>
                    </button>
                    <button className='flex align-center delete-btn-icon' onClick={() => deleteMagazines(row.id)}>
                        <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2v12ZM9 4h6v2H9V4ZM8 8h9v12H7V8h1Z"></path>
                            <path d="M9 10h2v8H9v-8Zm4 0h2v8h-2v-8Z"></path>
                        </svg>
                    </button>
                </div>
            ),
        },
    ];

    const addRow = () => {
        btnName === "Save" && setBtnName("Add");
        setInitialValues({
            dropdown: "",
            name: "",
            image: "",
            pdf: "",
            price: ""
        })
        setShowForm(!showForm);
    };

    //Retrieves the list of magazines.
    const getMagazinesList = async () => {
        try {
            setLoading(true)
            // Define request headers
            const headers = {
                Authorization: `Bearer ${TOKEN}`
            };

            // Make GET request using Axios
            const response = await axios.get(`${API_END_POINT}magazines/getMagazineListAdmin`, { headers });
            setLoading(false);
            return response?.data?.data;
        } catch (error) {
            setMagazinesList([])
            setLoading(false);
        }
    };

    // Deletes a magazine by its ID.
    const deleteMagazines = async (id) => {
        setIsConfirmationDelete(true);
        setDeleteContentId(id);
    };

    // Delete Action confirm popup
    const confirmActionDelete = async (data) => {
        try {
            // Define request headers
            const headers = {
                Authorization: `Bearer ${TOKEN}`
            };

            // Make DELETE request using Axios
            await axios.delete(`${API_END_POINT}magazines/deleteMagazineById?magazineId=${deleteContentId}`, {
                headers
            });
            setMagazinesList(await getMagazinesList())
            toast.success("Magazine Deleted successfully");

        } catch (error) {
            toast.error("Unable To Delete MAgazine");
        }
    }

    // Adds a new magazine.
    const addNewMagazines = async (info) => {
        console.log("HHHHHHH")
        const categoryId = magazineCategories.find((data) => data?.magazineCategoryName.toLowerCase() === info?.dropdown?.toLowerCase());
        try {
            // Define request headers
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`
            };

            // Define request body
            const data = {
                actionInfo: {
                    actionId: "String"
                },
                formData: {
                    magazineTitle: info?.name,
                    categoryId: categoryId.id,
                    magazinePrice: info?.price,
                    tagId: 1,
                    pdfFileUrl: info?.pdf,
                },
                sessionData: {
                    userDetails: {
                        isAdmin: 0,
                        isAllAccess: 0,
                        langCode: "String",
                        userEmailId: "String",
                        userId: 0,
                        userName: "String"
                    }
                }
            };

            // Make POST request using Axios
            let response = await axios.post(`${API_END_POINT}magazines/create`, data, {
                headers
            });

            fileInputRef.current.files[0] && await uploadMagazineFile(response?.data?.data?.id);
            setMagazinesList(await getMagazinesList());
            toast.success("Magazine Added Successfully!");
        } catch (error) {
            toast.error("Unable To Add Magazine");
        }
    };

    // Updates a magazine by its ID.
    const updateMagazines = async (id) => {
        setBtnName("Save");
        let previousData = magazinesList.find((data) => data.id === id);
        setInitialValues({
            dropdown: previousData?.magazineCategory,
            name: previousData?.magazineTitle,
            image: "",
            pdf: previousData?.pdfLinks,
            price: previousData?.magazinePrice
        })
        setShowForm(true);
        setConfirmUpdate(id);
    };

    // confirmupdate 
    const ConfirmUpdateMagazine = async (info) => {
        const categoryId = magazineCategories.find((data) => data?.magazineCategoryName.toLowerCase() === info?.dropdown?.toLowerCase());
        try {
            // Define request headers
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`
            };

            // Define request body
            const data = {
                actionInfo: {
                    actionId: "String"
                },
                formData: {
                    magazineTitle: info?.name,
                    categoryId: categoryId.id,
                    magazinePrice: info?.price,
                    tagId: 1,
                    pdfFileUrl: info?.pdf,
                    imageUrl: info?.image
                },
                sessionData: {
                    userDetails: {
                        isAdmin: 0,
                        isAllAccess: 0,
                        langCode: "String",
                        userEmailId: "String",
                        userId: 0,
                        userName: "String"
                    }
                }
            };

            // Make PUT request using Axios
            let response = await axios.put(`${API_END_POINT}magazines/updateMagazineById?magazineId=${confirmUpdate}`, data, {
                headers
            });

            fileInputRef.current.files[0] && uploadMagazineFile(response?.data?.data?.id);
            setShowForm(false);
            toast.success("Magazines Updated successfully");
            setMagazinesList(await getMagazinesList());
        } catch (error) {
            toast.error("Unable To Update Magazines");
        }
    }

    // Retrieves the list of magazine categories.
    const getMagazineCategories = async () => {
        try {
            // Define request headers
            const headers = {
                Authorization: `Bearer ${TOKEN}`
            };

            // Make GET request using Axios
            const response = await axios.get(`${API_END_POINT}magazineCategories/getAllMagazineCategories`, {
                headers
            });
            return response?.data?.data;
        } catch (error) {
            console.error(error);
        }
    };

    // this function for close image preview popup
    const closePopup = () => {
        setIsImagePreviewPopUpOpen(false);
    }

    // this function fro show image preview
    const showImagePreviewPopUp = (imageUrl) => {
        setPreviewImageUrl(`${API_END_POINT}${imageUrl}`);
        setIsImagePreviewPopUpOpen(true)
    }

    // ----- this is for close confirm delete popup -----
    const closeConfirmDeletePopUp = () => {
        setIsConfirmationDelete(false)
    }

    // ----- this is for close confirm Edit popup -----
    const closeConfirmEditPopUp = () => {
        setIsConfirmationEdit(false)
    }

    const uploadMagazineFile = async (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${TOKEN}`);

        const formdata = new FormData();
        formdata.append("magazineImage", fileInputRef.current.files[0], fileInputRef.current.files[0].name);
        formdata.append("magazineId", id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        try {
            await fetch(`${API_END_POINT}magazineFileMapper/uploadMagazineFiles`, requestOptions);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        async function fetchData() {
            setMagazinesList(await getMagazinesList())
            setMagazineCategories(await getMagazineCategories());
        }
        fetchData();

    }, [])



    return (
        <main className='main'>
            {magazinesList && <section className='section'>
                <h3 className='heding'>Magazines Configuration</h3>
                <div style={{ marginTop: "10px" }} className='flex align-center justify-end'>
                    <button onClick={addRow} className='common-btn flex align-center'>
                        {!showForm ? <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6v-2Z"></path>
                        </svg> :
                            <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242-1.414-1.414Z"></path>
                            </svg>}
                    </button>
                </div>
                {showForm && (
                    <form onSubmit={handleSubmit} className='flex flex-wrap aign-center gap-5 magazinesConfigurationForm form'>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="dropdown">Select Category</label>
                            <select id='dropdown' name="dropdown"
                                value={values.dropdown}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }} className={`${(errors.dropdown && touched.dropdown) && 'error'}`}>
                                <option value="">Select category</option>
                                {
                                    magazineCategories?.map((data, index) => {
                                        return (
                                            <option value={data?.magazineCategoryName} key={index}>{data?.magazineCategoryName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="name">Name</label>
                            <input
                                id='name'
                                type="text"
                                name="name"
                                value={values.name}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                placeholder="enter magazines's name"
                                className={`${(errors.name && touched.name) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="price">Price</label>
                            <input
                                id='price'
                                type="number"
                                name="price"
                                value={values.price}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                className={`${(errors.price && touched.price) && 'error'}`}
                                placeholder="enter magazines's price"
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="image">Select Image</label>
                            {/* Hidden file input */}
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => {
                                    setFieldValue("image", e?.target?.files[0]?.name);
                                }}
                                ref={fileInputRef}
                            />

                            <input
                                id='image'
                                type="text"
                                name="image"
                                value={values.image}
                                onClick={() => {
                                    fileInputRef.current.click();
                                }}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                placeholder='select Image'
                                readOnly
                                className={`${(errors.image && touched.image) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="pdf">Paste Pdf Url</label>
                            <input
                                id='pdf'
                                type="text"
                                name="pdf"
                                value={values.pdf}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                placeholder='paste pdf url here'
                                className={`${(errors.pdf && touched.pdf) && 'error'}`}
                            />
                        </div>
                        <div className='buttons flex flex-col' style={{ alignItems: "flex-end" }}>
                            <label htmlFor="btn" style={{ visibility: "hidden", marginBottom: "5px" }}>hidden</label>
                            <button type="submit" id='btn' className='btn' style={{ width: "100%" }}>{btnName}</button>
                        </div>
                    </form>
                )}
                <div style={{ marginTop: "10px" }}>
                    <CommonTable columns={columns} data={magazinesList} />
                </div>
            </section>}

            {/* ----- confirmation Delete popup --- */}
            <Confirmation text={"Do you want to delete this Magazine"} confirmAction={confirmActionDelete} closeConfirmPopUp={closeConfirmDeletePopUp} isConfirmationOpen={isConfirmationDelete} />

            {/* ----- confirmation Edit popup --- */}
            <Confirmation text={"Do you want to Save this Magazine"} confirmAction={ConfirmUpdateMagazine} closeConfirmPopUp={closeConfirmEditPopUp} isConfirmationOpen={isConfirmationEdit} data={values} />

            {/* ------ popup for image preview --------- */}
            <Popup open={isImagePreviewPopUpOpen} onClose={closePopup} modal>
                <div>
                    <div className="flex align-center justify-between gap-5 image-preview-popup-header">
                        <span>Preview</span>
                        <svg onClick={closePopup} width="30" height="30" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242-1.414-1.414Z"></path>
                        </svg>
                    </div>
                    <div className="image-preview-popup-content">
                        <img src={previewImageUrl} alt="Magazine/Book" />
                    </div>
                </div>
            </Popup>

            {/* ------- loader here ------- */}
            {loading && <Loader />}
        </main>
    )
}

export default MagazinesConfiguration








