import "./App.css";
import Navbar from "./components/navbar/Navbar";
import Sidebar from "./components/sidebar/Sidebar";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import SetupDoubtSession from "./pages/SetupDoubtSession/SetupDoubtSession";
import GemstoneConfiguration from "./pages/GemstoneConfiguration/GemstoneConfiguration";
import BulkNotification from "./pages/BulkNotification/BulkNotification";
import MagazinesConfiguration from "./pages/MagazinesConfiguration/MagazinesConfiguration";
import Consultation from "./pages/Consultation/Consultation";
import Session from "./pages/SessionInfo/Session";
import CouponCodeConfiguration from "./pages/CouponCodeConfiguration/CouponCodeConfiguration";
import CoursesConfiguration from "./pages/CoursesConfiguration/CoursesConfiguration";
import CourseUpload from "./pages/CourseUpload/CourseUpload";
import BannerUpload from "./pages/BannerUpload/BannerUpload";
import PopUp from "./pages/PopUp/PopUp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Login/Login";
import { useEffect } from "react";
import Blog from "./pages/Blog/Blog";
import PoojaList from "./pages/PoojaList/PoojaList";
import BannerUploadpPooja from "./pages/BannerUploadPooja/BannerUploadpPooja";
import IconUploadPooja from "./pages/IconUploadPooja/IconUploadPooja";
import BanneruploadConsultation from "./pages/BannerUploadConsultation/BanneruploadConsultation";
import IconUploadConsultation from "./pages/IconUploadConsultation/IconUploadConsultation";
import GetTodaysPooja from "./pages/GetTodaysPooja/GetTodaysPooja";
import DateChangeRequest from "./pages/DateChangeRequest/DateChangeRequest";
import Report from "./pages/Report/Report";
import SessionPooja from "./pages/SessionPooja/SessionPooja";
import Analytics from "./pages/Analytics/Analytics";
import ConsultationDateChange from "./pages/ConsultationDateChange/ConsultationDateChange";
import Transaction from "./pages/Transaction/Transaction";
import PoojaCalendar from "./pages/PoojaCalender/PoojaCalendar";
import ConsultationCalendar from "./pages/ConsultationCalendar/ConsultationCalendar";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      navigate('/login');
    } else if (token && (window.location.pathname === "/login" || window.location.pathname === "/")) {
      navigate("/home")
    }
  }, [navigate]);

  return (
    <>
      {localStorage.getItem("accessToken") && (
        <>
          <Navbar />
          <Sidebar />
        </>
      )}

      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />

        {localStorage.getItem("accessToken") && (
          <>
            <Route path="/home" element={<Home />} />
            <Route path="/setupdoubtsession" element={<SetupDoubtSession />} />
            <Route
              path="/magazinesConfiguration"
              element={<MagazinesConfiguration />}
            />
            <Route
              path="/gemstoneconfiguration"
              element={<GemstoneConfiguration />}
            />
            <Route
              path="/couponCodeConfiguration"
              element={<CouponCodeConfiguration />}
            />
            <Route path="/bulkNotifucations" element={<BulkNotification />} />
            <Route path="/bannerUpload" element={<BannerUpload />} />
            <Route
              path="/coursesCodeConfiguration"
              element={<CoursesConfiguration />}
            />
            <Route
              path="/consultation"
              element={<Consultation />}
            />
            <Route
              path="/session"
              element={<Session />}
            />
            <Route
              path="/sessionPooja"
              element={<SessionPooja />}
            />
            <Route
              path="/courseupload"
              element={<CourseUpload />}
            />

            <Route
              path="/popup"
              element={<PopUp />}
            />

            <Route
              path="/blog"
              element={<Blog />}
            />

            <Route
              path="/onlinepooja"
              element={<PoojaList />}
            />

            <Route
              path="/banneruploadpooja"
              element={<BannerUploadpPooja />}
            />

            <Route
              path="/iconuploadpooja"
              element={<IconUploadPooja />}
            />

            <Route
              path="/banneruploadconsultation"
              element={<BanneruploadConsultation />}
            />
            <Route
              path="/iconuploadconsultation"
              element={<IconUploadConsultation />}
            />
            <Route
              path="/gettodayspooja"
              element={<GetTodaysPooja />}
            />
            <Route
              path="/datechangerequest"
              element={<DateChangeRequest />}
            />
            <Route
              path="/consultationdatechange"
              element={<ConsultationDateChange />}
            />

            <Route
              path="/report"
              element={<Report />}
            />

            <Route
              path="/analytics"
              element={<Analytics />}
            />

            <Route
              path="/transaction"
              element={<Transaction />}
            />

            <Route
              path="/poojaCalendar"
              element={<PoojaCalendar />}
            />

            <Route
              path="/ConsultationCalendar"
              element={<ConsultationCalendar />}
            />
          </>
        )}
      </Routes>

      {/* toaster container */}
      <ToastContainer />
    </>
  );
}

export default App;
