import React, { useState } from 'react'
import "./Box.css"

const Box = ({ title = "", todaysCount, allCount, viewList = "", dateTime = null }) => {
  const [viewListText, setViewListText] = useState("View List")
  function splitCamelCase(input) {
    return input.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  const viewListData = () => {
    viewList(viewListText, setViewListText);
  }

  return (
    <div className='box'>
      {title !== "" && <h5 className='title flex align-center flex-wrap gap-5 justify-between'>
        <span>{splitCamelCase(title)}</span>
        {viewList !== "" && <span className='listLink' onClick={viewListData}>{viewListText}</span>}
      </h5>}

      {dateTime !== null && <div className='flex align-center' style={{ padding: ".5rem 1rem" }}>
        <div className='flex flex-col'>
          <span className='dateTimelable'>Session will Start</span>
          <span className='dateTimeValue'>{dateTime}</span>
        </div>
      </div>}

      <div className="data flex flex-wrap gap-5 align-center justify-between">
        <div className="today-count flex flex-col">
          <span className='count-title'>Purchased Today</span>
          <span className='count-number'>{todaysCount}</span>
        </div>
        <div className="today-count flex flex-col">
          <span className='count-title'>Total</span>
          <span className='count-number'>{allCount}</span>
        </div>
      </div>
    </div>
  )
}

export default Box