import React from 'react'
import CommonTable from '../Table/CommonTable'

function BirthChartTable({ data }) {
    // console.log("Data in Table.jsx is", data)

    const columns = [
        {
            name: 'Name',
            selector: row => row.userName,
        },
        {
            name: 'Email',
            selector: row => row.userEmail,
        },
        {
            name: 'Contact',
            selector: row => row.userMobile
        }
    ];
    return (
        <main>
            <CommonTable data={data} columns={columns} value={false} />
        </main>
    )
}

export default BirthChartTable