import React, { useState } from 'react'
import { useFormik } from 'formik';
import axios from 'axios';
import { couponCodeSchema } from '../../schema/Index';
import { TOKEN, API_END_POINT } from '../../constant';
import CommonTable from '../../components/Table/CommonTable'
import "./CouponCodeConfiguration.css"
import { useEffect } from 'react';
import { toast } from "react-toastify";
import Loader from '../../components/Loader/Loader';
import Confirmation from '../../components/ConfirmationPopup/Confirmation';

import moment from 'moment';

const CouponCodeConfiguration = () => {

  const [couponCodeList, setCouponCodeList] = useState(null)
  const [couponCodeModuleList, setCouponCodeModuleList] = useState([]);
  const [couponCodSubModuleList, setCouponCodSubModuleList] = useState([]);
  const [btnName, setBtnName] = useState("Add");
  const [showForm, setShowForm] = useState(false);
  const [confirmUpdate, setConfirmUpdate] = useState(0);
  const [isConfirmationDelete, setIsConfirmationDelete] = useState(false);
  const [deleteContentId, setDeleteContentId] = useState(0)
  const [isConfirmationEdit, setIsConfirmationEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    couponName: "",
    couponCode: "",
    couponAmount: "",
    couponCodeLimit: "",
    expiryDate: "",
    module: "",
    subModule: ""
  })


  const { values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues,
    validationSchema: couponCodeSchema,
    onSubmit: async (values, { resetForm }) => {
      if (btnName === "Add") {
        await createCouponCode(values)
        setShowForm(false);
        resetForm();
        setCouponCodeList(await getCouponCodeList());
      } else if (btnName === "Save") {
        setIsConfirmationEdit(true)
      }
    },
    enableReinitialize: true,
  })

  const columns = [
    {
      name: "Coupon Name",
      cell: (row) => row?.couponCodeName === null ? "-" : row?.couponCodeName
    },
    {
      name: 'Coupon Code',
      cell: (row) => row?.couponCode,
    },
    {
      name: 'Amount',
      cell: (row) => row?.amount,
    },
    {
      name: 'Limit',
      cell: (row) => row?.couponCodeLimit,
    },
    {
      name: 'Expiry Date',
      cell: (row) => moment(row.expirDate)?.format("DD-MM-YYYY"),
    },
    {
      name: 'Module',
      cell: (row) => row?.paymentSourceModule?.paymentSourceModuleName,
    },
    {
      name: 'Sub Module',
      cell: (row) => row?.paymentSourceItem,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className='flex align-center gap-10'>
          <button className='flex align-center edit-btn' onClick={() => updatesCoupon(row.id)}>
            <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="m7 17.011 4.413-.015 9.632-9.54c.378-.378.586-.88.586-1.414 0-.534-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.581v4.43ZM18.045 4.456l1.589 1.583-1.597 1.582-1.586-1.585 1.594-1.58ZM9 13.416l6.03-5.974 1.586 1.586L10.587 15 9 15.004v-1.589Z"></path>
              <path d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2Z"></path>
            </svg>
          </button>
          <button className='flex align-center delete-btn-icon' onClick={() => deleteRow(row.id)}>
            <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2v12ZM9 4h6v2H9V4ZM8 8h9v12H7V8h1Z"></path>
              <path d="M9 10h2v8H9v-8Zm4 0h2v8h-2v-8Z"></path>
            </svg>
          </button>
        </div>
      ),
    },
  ];


  const handleInputChange = (e) => {
    const { value } = e.target;
    if (value !== "") {
      let id = couponCodeModuleList?.find((data) => {
        return data.paymentSourceModuleName === value
      })?.paymentSourceModuleId
      getCouponCodSubModuleList(id)
    } else {
      setCouponCodSubModuleList([])
    }
  };

  // Retrieves the list of modules associated with coupon codes.
  const getCouponCodeModuleList = async () => {
    try {
      const response = await axios.get(`${API_END_POINT}CouponCodeController/getCouponCodModuleList`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`
        }
      });
      return response.data
    } catch (error) {
      console.error(error);
    }
  }

  // Retrieves the list of coupon codes.
  const getCouponCodeList = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${API_END_POINT}CouponCodeController/getCouponCodeList`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`
        }
      });
      setLoading(false)
      return response?.data?.content
    } catch (error) {
      setLoading(false)
      setCouponCodeList([]);
    }
  };

  // Retrieves the list of sub-modules associated with a specific module.
  const getCouponCodSubModuleList = async (id) => {
    try {
      const response = await axios.get(`${API_END_POINT}CouponCodeController/getCouponCodSubModuleList?id=${id}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`
        }
      });
      setCouponCodSubModuleList(response.data)
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  // Adds a new coupon code to the list.
  const createCouponCode = async (data) => {
    let moduleId = couponCodeModuleList.find((item) => item.paymentSourceModuleName === data.module)?.paymentSourceModuleId;
    let subModuleId = couponCodSubModuleList.find((item) => item.name === data.subModule)?.id;

    try {
      await axios.post(`${API_END_POINT}CouponCodeController/createCouponCode`, {
        couponCodeName: data.couponName,
        couponCode: data.couponCode,
        expireDate: `${data.expiryDate} 00:00:00`,
        couponCodeLimit: data.couponCodeLimit,
        amount: data.couponAmount,
        couponCodeSourceModule: moduleId,
        couponCodeSourceModuleItem: subModuleId
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`
        }
      });
      toast.success("Coupon Added successfully");
    } catch (error) {
      toast.error("Unable To Add Coupon");
    }
  };

  // Deletes a coupon code with the specified ID.
  const deleteRow = async (id) => {
    setIsConfirmationDelete(true)
    setDeleteContentId(id)
  };

  // Function to handle confirmation of an action.
  const confirmActionDelete = async (data) => {
    try {
      await axios.delete(`${API_END_POINT}CouponCodeController/deleteCouponCode?id=${deleteContentId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`
        }
      });
      toast.success("Coupon Deleted successfully");
      setCouponCodeList(await getCouponCodeList());
    } catch (error) {
      toast.error("Unable To Delete Coupon");
    }
  }

  // ----- this is for close confirm delete popup -----
  const closeConfirmDeletePopUp = () => {
    setIsConfirmationDelete(false)
  }

  // Updates an existing coupon code with the provided data.
  const updatesCoupon = async (id) => {
    setBtnName("Save")

    let previousData = couponCodeList.find((data) => data.id === id);
    let couponModule = couponCodeModuleList.find((data) => data?.paymentSourceModuleId === previousData?.paymentSourceModule?.paymentSourceModuleId);
    let couponSubModleList = await getCouponCodSubModuleList(couponModule?.paymentSourceModuleId);
    couponSubModleList = couponSubModleList?.find((data) => data?.id === previousData?.paymentSourceItem)
    setInitialValues({
      couponName: previousData?.couponCodeName,
      couponCode: previousData?.couponCode,
      couponAmount: previousData?.amount,
      couponCodeLimit: previousData?.couponCodeLimit,
      expiryDate: moment(previousData?.expirDate).format("YYYY-MM-DD"),
      module: couponModule?.paymentSourceModuleName,
      subModule: couponSubModleList?.name
    })
    setShowForm(true)
    setConfirmUpdate(id)
  };

  // confirmupdate coupon 
  const ConfirmUpdateCoupon = async (data) => {
    let moduleId = couponCodeModuleList.find((item) => item.paymentSourceModuleName === data.module)?.paymentSourceModuleId;
    let subModuleId = couponCodSubModuleList.find((item) => item.name === data.subModule)?.id;
    try {
      await axios.put(`${API_END_POINT}CouponCodeController/updateCouponCode?id=${confirmUpdate}`, {
        couponCodeName: data.couponName,
        couponCode: data.couponCode,
        expireDate: `${data.expiryDate} 00:00:00`,
        couponCodeLimit: data.couponCodeLimit,
        amount: data.couponAmount,
        couponCodeSourceModule: moduleId,
        couponCodeSourceModuleItem: subModuleId
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`
        }
      });
      setShowForm(false);
      toast.success("Coupon Updated successfully");
      setCouponCodeList(await getCouponCodeList());
    } catch (error) {
      toast.error("Unable To Update Coupon");
    }
  }

   // ----- this is for close confirm Edit popup -----
   const closeConfirmEditPopUp = ()=>{
    setIsConfirmationEdit(false)
}

  const addNewCoupon = async () => {
    btnName === "Save" && setBtnName("Add");
    setInitialValues({
      couponName: "",
      couponCode: "",
      couponAmount: "",
      couponCodeLimit: "",
      expiryDate: "",
      module: "",
      subModule: ""
    })
    setShowForm(!showForm);
  }

  useEffect(() => {
    async function fetchData() {
      setCouponCodeList(await getCouponCodeList());
      setCouponCodeModuleList(await getCouponCodeModuleList());
    }
    fetchData();

  }, [])


  return (
    <>
      <main className='main'>

        {couponCodeList && <section className='section'>
          <h3 className='heding'>Coupon Code Configuration</h3>
          <div style={{ marginTop: "10px" }} className='flex align-center justify-end'>
            <button className='common-btn flex align-center' onClick={addNewCoupon}>
              {!showForm ? <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6v-2Z"></path>
              </svg> :
                <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242-1.414-1.414Z"></path>
                </svg>}
            </button>
          </div>
          {showForm && (
            <form className='flex flex-wrap aign-center gap-10 gemstoneConfigurationForm form' onSubmit={handleSubmit}>
              <div className="input-field flex flex-col">
                <label htmlFor="name">Coupon Name</label>
                <input type="text" placeholder='enter coupon name' id='name' name='couponName' value={values.couponName} onBlur={handleBlur} onChange={(e) => {
                  handleChange(e);
                }} className={`${(errors.couponName && touched.couponName) && 'error'}`} />
              </div>
              <div className="input-field flex flex-col">
                <label htmlFor="Code">Coupon Code</label>
                <input type="text" placeholder='enter coupon code' id='Code' name='couponCode' value={values.couponCode} onBlur={handleBlur} onChange={(e) => {
                  handleChange(e);
                }} className={`${(errors.couponCode && touched.couponCode) && 'error'}`} />
              </div>
              <div className="input-field flex flex-col">
                <label htmlFor="Code">Coupon Amount</label>
                <input type="number" placeholder='enter coupon amount' id='Amount' name='couponAmount' value={values.couponAmount} onBlur={handleBlur} onChange={(e) => {
                  handleChange(e);
                }} className={`${(errors.couponAmount && touched.couponAmount) && 'error'}`} />
              </div>

              <div className="input-field flex flex-col">
                <label htmlFor="Limit">Limit</label>
                <input type="number" placeholder='enter coupon code limit' id='Limit' name='couponCodeLimit' value={values.couponCodeLimit} onBlur={handleBlur} onChange={(e) => {
                  handleChange(e);
                }} className={`${(errors.couponCodeLimit && touched.couponCodeLimit) && 'error'}`} />
              </div>

              <div className="input-field flex flex-col">
                <label htmlFor="date">Expiry Date</label>
                <input type="date" id='date' name='expiryDate' value={values.expiryDate} onBlur={handleBlur} onChange={(e) => {
                  handleChange(e);
                }} className={`${(errors.expiryDate && touched.expiryDate) && 'error'}`} />
              </div>

              <div className="input-field flex flex-col">
                <label htmlFor="date">Module</label>
                <select name="module" value={values.module} onBlur={handleBlur} onChange={(e) => {
                  handleInputChange(e);
                  handleChange(e);
                }} className={`${(errors.module && touched.module) && 'error'}`}>
                  <option value="">Select Module</option>
                  {couponCodeModuleList?.map((data, index) => {
                    return (
                      <option value={data.paymentSourceModuleName} key={index}>{data.paymentSourceModuleName}</option>
                    )
                  })}
                </select>
              </div>

              <div className="input-field flex flex-col">
                <label htmlFor="date">Sub Module</label>
                <select name="subModule" value={values.subModule} onBlur={handleBlur} onChange={(e) => {
                  handleChange(e);
                }} className={`${(errors.subModule && touched.subModule) && 'error'}`}>
                  <option value="">Select Sub Module</option>
                  {couponCodSubModuleList?.map((data, index) => {
                    return (
                      <option value={data.name} key={index}>{data.name}</option>
                    )
                  })}
                </select>
              </div>

              <div className='buttons flex flex-col' style={{ alignItems: "flex-end" }}>
                <label htmlFor="btn" style={{ visibility: "hidden", marginBottom: "5px" }}>hidden</label>
                <button type="submit" className='btn' id='btn' style={{ width: "100%" }}>{btnName}</button>
              </div>
            </form>
          )}
          <div style={{ marginTop: "10px" }}>
            <CommonTable columns={columns} data={couponCodeList} />
          </div>
        </section>}

        {/* ----- confirmation Delete popup --- */}
        <Confirmation text={"Do you want to delete this Coupon"} confirmAction={confirmActionDelete} closeConfirmPopUp={closeConfirmDeletePopUp} isConfirmationOpen={isConfirmationDelete} />

         {/* ----- confirmation Edit popup --- */}
         <Confirmation text={"Do you want to Save this Coupon"} confirmAction={ConfirmUpdateCoupon} closeConfirmPopUp={closeConfirmEditPopUp} isConfirmationOpen={isConfirmationEdit} data={values}/>

         {/* ------- loader here ------- */}
         {loading && <Loader />}
      </main>
    </>
  )
}

export default CouponCodeConfiguration