import React, { useState, useEffect } from 'react';
import './GetTodaysPooja.css'
import CommonTable from '../../components/Table/CommonTable';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import { TOKEN, API_END_POINT } from '../../constant';
import { toast } from 'react-toastify';

const GetTodaysPooja = () => {
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [completedSessions, setCompletedSessions] = useState(new Set());

    // Columns configuration
    const columns = [
        {
            name: 'Pooja Session Info',
            cell: (row) => row?.sessionInfo,
        },
        {
            name: 'Pooja Date',
            cell: (row) => row?.poojaDate,
        },
        {
            name: 'Pooja Name',
            cell: (row) => row?.poojaName,
        },
        {
            name: 'User Name',
            cell: (row) => row?.userName,
        },
        {
            name: 'User Email',
            cell: (row) => row?.userEmail,
        },
        {
            name: 'Contact',
            cell: (row) => row?.contact,
        },
        {
            name: 'Pooja Completed',
            cell: (row) => (
                // <span>{row?.poojaCompleted ? 'YES' : 'NO'}</span>
                <button
                    className={row.poojaCompleted ? 'btn-yes' : 'btn-no'}
                    disabled={row.poojaCompleted} // Disable the button if the session is already completed
                    onClick={() => poojaCompleted(row.poojaSessionId)}>
                    {row.poojaCompleted ? "Yes" : "No"}
                </button >
            ),
        },
    ];

    const poojaCompleted = async (id) => {
        const headers = {
            Authorization: `Bearer ${TOKEN}`,
        };

        try {
            axios.put(`${API_END_POINT}userPoojaMapper/updatePoojaStatus`, {
                "mapperId": id
            }, {
                headers: headers
            })
            toast.success("Pooja completed successfully");
            setCompletedSessions(prev => new Set(prev).add(id));
            setTimeout(fetchPoojaData, 1000);
            // await fetchPoojaData();
        }
        catch (error) {
            console.log(error)
            toast.error("Unable To Update Pooja Status");
        }

    }

    // Fetch data from API
    const fetchPoojaData = async () => {
        try {
            setLoading(true);
            const headers = {
                Authorization: `Bearer ${TOKEN}`,
            };
            const response = await axios.get(`${API_END_POINT}userPoojaMapper/getPresentDayPooja?size=100&page=0`, { headers });
            setDataList(response?.data?.data || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching pooja data:', error);
            setDataList([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPoojaData();
    }, []);

    return (
        <main className='main'>
            {dataList.length > 0 && (
                <section className='section'>
                    <h3 className='heading'>Today's Pooja Sessions</h3>
                    <div style={{ marginTop: "10px" }}>
                        <CommonTable columns={columns} data={dataList} />
                    </div>
                </section>
            )}
            {loading && <Loader />}
        </main>
    );
};

export default GetTodaysPooja;

