import React, { useEffect, useRef, useState } from 'react';
import "./PopUp.css";
import axios from 'axios';
import { toast } from "react-toastify";
import { API_END_POINT, TOKEN } from '../../constant';
import CommonTable from '../../components/Table/CommonTable';
import Loader from '../../components/Loader/Loader';
import Confirmation from '../../components/ConfirmationPopup/Confirmation';
import { useFormik } from 'formik';
import { popupSchema } from '../../schema/Index';

const PopUp = () => {
    const [initialValues, setInitialValues] = useState({
        uploadFile: '',
        popupMessage: ''
    });

    const [rows, setRows] = useState([]);
    const fileInputRef = useRef(null);
    const [showForm, setShowForm] = useState(false);
    const [btnName, setBtnName] = useState("Add");
    const [isConfirmationEdit, setIsConfirmationEdit] = useState(false);
    const [isConfirmationDelete, setIsConfirmationDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteContentId, setDeleteContentId] = useState(0)
    const [confirmUpdate, setConfirmUpdate] = useState(0);

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        validationSchema: popupSchema,
        onSubmit: async (values, { resetForm }) => {
            console.log(btnName);
            console.log(values);
            if (btnName === "Save") {
                setIsConfirmationEdit(true)
            } else if (btnName === "Add") {
                await addNewPopup(values);
                setShowForm(false);
                resetForm();
            }
        },
        enableReinitialize: true,
    });

    const addRow = () => {
        if (btnName === "Save") {
            setBtnName("Add");
        }
        setInitialValues({
            uploadFile: '',
            popupMessage: ''
        });
        setShowForm(!showForm);
    };

    // Adds a new blog.
    const addNewPopup = async (info) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${TOKEN}`);

        const formdata = new FormData();
        formdata.append("File", fileInputRef.current.files[0]);
        formdata.append("message", info.popupMessage);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        try {
            await fetch(`${API_END_POINT}popUp/add`, requestOptions);
            toast.success("Popup Added Successfully!");
            setRows(await getBannerListingByLanguage())
        } catch (error) {
            console.log(error)
            toast.error("Unable To Add Popup");
        }
    };

    // ----- this is for close confirm Edit popup -----
    const closeConfirmEditPopUp = () => {
        setIsConfirmationEdit(false)
    }

    // Function to handle file uploads
    const uploadBanner = async (e, id) => {
        const spinner = document.getElementById(`spinner${id}`);
        const file = e.target.files[0];
        const message = rows.find(row => row.popUpId === id)?.popUpMessage || '';

        try {
            const formData = new FormData();
            formData.append('File', file);
            formData.append('message', message);

            spinner.style.display = "inline-block";
            const response = await axios.post(`${API_END_POINT}popUp/add`, formData, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                },
            });
            spinner.style.display = "none";
            toast.success(response.data.response.responseMessage);

            // Update the file link in the row
            const updatedRows = rows.map(row => {
                if (row.popUpId === id) {
                    return { ...row, popUpImgLink: URL.createObjectURL(file) };
                }
                return row;
            });
            setRows(updatedRows);

            // Clear the file input value to allow re-upload of the same file
            e.target.value = '';

            return response.data;
        } catch (error) {
            spinner.style.display = "none";
            toast.error("Unable to upload file.");
        }
    };

    // Function to handle user message input change
    const handleUserMessageChange = (e, id) => {
        const updatedRows = rows.map(row => {
            if (row.popUpId === id) {
                return { ...row, popUpMessage: e.target.value };
            }
            return row;
        });
        setRows(updatedRows);
    };

    // Function to get the banner listing by language
    const getBannerListingByLanguage = () => {
        const headers = {
            "Authorization": `Bearer ${TOKEN}`,
        };

        axios.get(`${API_END_POINT}popUp/getList`, { headers })
            .then((response) => {
                setRows(response?.data);
                // fileInputs.current = response?.data.map(() => React.createRef());
            })
            .catch((error) => {
                console.error(error);
            });
    };

    // Deletes a blog by its ID.
    const deletePopup = async (id) => {
        setIsConfirmationDelete(true);
        setDeleteContentId(id);
    };

    // Delete Action confirm popup
    const confirmActionDelete = async () => {
        try {
            // Define request headers
            const headers = {
                Authorization: `Bearer ${TOKEN}`
            };

            // Make DELETE request using Axios
            await axios.delete(`${API_END_POINT}popUp/delete?id=${deleteContentId}`, {
                headers
            });
            setRows(await getBannerListingByLanguage())
            toast.success("Popup Deleted successfully");
        } catch (error) {
            toast.error("Unable To Delete Popup");
        }
    }

    const updatePopup = (id) => {
        // Set the button name to "Save" to indicate edit mode
        setBtnName("Save");
        // Find the blog data using the popUpId
        const previousData = rows.find((data) => data.popUpId === id);
        // Set the initial values for the form using the previous data
        // console.log("previousData is ", previousData)
        setInitialValues({
            popupMessage: previousData?.popUpMessage,
            uploadFile: previousData?.popUpImgLink
        });

        // Show the form for editing
        setShowForm(true);

        // Store the ID of the blog being updated to be used in the update request
        setConfirmUpdate(id);
    };

    // confirmupdate 
    const ConfirmUpdatePopup = async (info) => {
        try {
            // Define request headers
            const headers = {
                "Authorization": `Bearer ${TOKEN}`,
                'Content-Type': 'application/json'
            };
            console.log("Info:", info);

            // Extract data from info
            const { popupMessage, uploadFile } = info;
            const id = confirmUpdate;

            // Make PUT request using Axios
            let response = await axios.put(`${API_END_POINT}popUp/update?id=${id}&message=${popupMessage}`, {}, {
                headers
            });

            // console.log("ConfirmUpdateBlog:", response?.data?.data?.id);
            fileInputRef.current.files[0] && uploadPopupFile(response?.data?.data?.id);
            setShowForm(false);
            toast.success("Popup Updated successfully");
            setRows(await getBannerListingByLanguage());
            setRows(await getBannerListingByLanguage());
        } catch (error) {
            console.error("Error:", error);
            toast.error("Unable To Update Popup");
        }
    };

    const uploadPopupFile = async (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${TOKEN}`);

        const formdata = new FormData();
        // console.log("Id in Popup.jsx is", id);
        // console.log("file in Popup.jsx is ", fileInputRef.current.files[0]);
        formdata.append("File", fileInputRef.current.files[0]);
        formdata.append("id", id);
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        try {
            await fetch(`${API_END_POINT}popUp/upload`, requestOptions);
        } catch (error) {
            console.log(error)
        }
    }

    // ----- this is for close confirm delete popup -----
    const closeConfirmDeletePopUp = () => {
        setIsConfirmationDelete(false)
    }

    useEffect(() => {
        getBannerListingByLanguage();
    }, []);

    const columns = [
        {
            name: "PopUp Id",
            cell: (row) => row?.popUpId,
        },
        {
            name: "PopUp Message",
            cell: (row) => row?.popUpMessage,
        },
        // {
        //     name: <h3>Update PopUp Message</h3>,
        //     selector: (row, index) =>
        //     (<div key={index}>
        //         <input
        //             type="text"
        //             placeholder="Enter message"
        //             value={row.popUpMessage || ''}
        //             onChange={(e) => handleUserMessageChange(e, row?.popUpId)}
        //         />
        //     </div>)
        // },
        // {
        //     name: 'Upload Icon',
        //     selector: (row, index) =>
        //     (<div key={index}>
        //         <button
        //             className='uploadbtn flex align-center gap-5'>
        //             <svg width="16" height="16" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        //                 <path fillRule="evenodd" d="M3.6 20.4a1.2 1.2 0 0 1 1.2-1.2h14.4a1.2 1.2 0 1 1 0 2.4H4.8a1.2 1.2 0 0 1-1.2-1.2ZM7.552 8.048a1.2 1.2 0 0 1 0-1.696l3.6-3.6a1.2 1.2 0 0 1 1.696 0l3.6 3.6a1.2 1.2 0 0 1-1.696 1.696L13.2 6.497V15.6a1.2 1.2 0 1 1-2.4 0V6.497L9.248 8.048a1.2 1.2 0 0 1-1.696 0Z" clipRule="evenodd"></path>
        //             </svg>
        //             <span>Upload PopUp Icon</span>
        //             <span className="spinner" id={`spinner${row?.popUpId}`}></span>
        //             <input
        //                 style={{ display: "none" }}
        //                 type="file"
        //                 onChange={(e) => uploadBanner(e, row?.popUpId)}
        //             />
        //         </button>
        //     </div>)
        // },
        {
            name: "PopUp Icon",
            cell: (row) => <img
                title='Click to see Image'
                src={`${API_END_POINT}${row?.popUpImgLink}`}
                width={"auto"}
                height={"70"}
                alt="Blog Image"
            />
        },
        {
            name: 'Actions',
            cell: (row, index) => (
                <div className='flex align-center gap-10'>
                    <button className='flex align-center edit-btn' onClick={() => updatePopup(row?.popUpId)}>
                        <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m7 17.011 4.413-.015 9.632-9.54c.378-.378.586-.88.586-1.414 0-.534-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.581v4.43ZM18.045 4.456l1.589 1.583-1.597 1.582-1.586-1.585 1.594-1.58ZM9 13.416l6.03-5.974 1.586 1.586L10.587 15 9 15.004v-1.589Z"></path>
                            <path d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2Z"></path>
                        </svg>
                    </button>
                    <button className='flex align-center delete-btn-icon' onClick={() => deletePopup(row?.popUpId)}>
                        <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2v12ZM9 4h6v2H9V4ZM8 8h9v12H7V8h1Z"></path>
                            <path d="M9 10h2v8H9v-8Zm4 0h2v8h-2v-8Z"></path>
                        </svg>
                    </button>
                </div>
            ),
        },
    ];

    return (
        <main className='main'>
            <section className='section'>
                <h3 className='heading' style={{ marginBottom: "10px" }}>PopUp Icon Upload</h3>
                <div style={{ marginTop: "10px" }} className='flex align-center justify-end '>
                    <button onClick={addRow} className='common-btn flex align-center'>
                        {!showForm ? <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6v-2Z"></path>
                        </svg> :
                            <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242-1.414-1.414Z"></path>
                            </svg>}
                    </button>
                </div>
                {showForm && (
                    <form onSubmit={handleSubmit} className='flex flex-wrap aign-center gap-5 magazinesConfigurationForm form '>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="popupMessage">Popup Message</label>
                            <input
                                id='popupMessage'
                                type="text"
                                name="popupMessage"
                                value={values.popupMessage}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="enter popup message"
                                className={`${(errors.popupMessage && touched.popupMessage) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="uploadFile">Select Image</label>
                            {/* Hidden file input */}
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                onChange={(e) => {
                                    setFieldValue("uploadFile", e?.target?.files[0]?.name);
                                }}
                            />
                            <input
                                id='uploadFile'
                                type="text"
                                name="uploadFile"
                                value={values.uploadFile}
                                onClick={() => {
                                    fileInputRef.current.click();
                                }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder='select Image'
                                readOnly
                                className={`${(errors.uploadFile && touched.uploadFile) && 'error'}`}
                            />
                        </div>
                        <div className='buttons flex flex-col' style={{ alignItems: "flex-end" }}>
                            <label htmlFor="btn" style={{ visibility: "hidden", marginBottom: "5px" }}>hidden</label>
                            <button type="submit" id='btn' className='btn' style={{ width: "100%" }}>{btnName}</button>
                        </div>
                    </form>
                )}
                <div style={{ marginTop: "10px" }}>
                    <CommonTable data={rows} columns={columns} />
                </div>
            </section>

            {/* ----- confirmation Delete popup --- */}
            <Confirmation text={"Do you want to delete this Blog"} confirmAction={confirmActionDelete} closeConfirmPopUp={closeConfirmDeletePopUp} isConfirmationOpen={isConfirmationDelete} />

            {/* ----- confirmation Edit popup --- */}
            <Confirmation text={"Do you want to Save this Blog"} confirmAction={ConfirmUpdatePopup} closeConfirmPopUp={closeConfirmEditPopUp} isConfirmationOpen={isConfirmationEdit} data={values} />

            {/* ------- loader here ------- */}
            {loading && <Loader />}
        </main>
    );
};

export default PopUp;
