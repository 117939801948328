import React, { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { getYear, getMonth } from "date-fns"; // Import necessary date-fns functions
import "react-big-calendar/lib/css/react-big-calendar.css";
import enUS from "date-fns/locale/en-US";
import { API_END_POINT, TOKEN } from "../../constant";
import axios from "axios";
import './ConsultationCalendar.css'

// Set up date localization
const locales = {
    "en-US": enUS,
};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const initialEvents = [];

function ConsultationCalendar() {
    const [events, setEvents] = useState(initialEvents);
    const [currentView, setCurrentView] = useState('month');
    const [currentDate, setCurrentDate] = useState(new Date());

    // Handle view change
    const handleViewChange = (view) => {
        setCurrentView(view);
    };

    // Handle navigation (next/prev month, etc.)
    const handleNavigate = (date) => {
        setCurrentDate(date);
    };

    // Extract month number, name, and year from the current date
    const getMonthYear = () => {
        const monthNumber = getMonth(currentDate) + 1; // getMonth returns 0-based month, so add 1
        const monthName = format(currentDate, 'MMMM'); // Get full month name
        const year = getYear(currentDate); // Get year
        return { monthNumber, monthName, year };
    };

    // Function to parse date in "dd/MM/yyyy" format
    const parseDateString = (dateString) => {
        const [day, month, year] = dateString.split("/").map(Number); // Split the string and convert to numbers
        return new Date(year, month - 1, day); // Create new Date object (months are 0-based in JS)
    };
    useEffect(() => {
        fetchEvents();
    }, [getMonthYear().year, getMonthYear().monthNumber]);

    const fetchEvents = async () => {
        const headers = {
            Authorization: `Bearer ${TOKEN}`,
        };

        // Reset events to default (empty) before fetching new data
        setEvents(initialEvents);

        try {
            const response = await axios.get(
                `${API_END_POINT}consultationDateUserMapper/getConsultationCalendar?month=${getMonthYear().monthNumber}&year=${getMonthYear().year}`,
                { headers }
            );

            // console.log("API Response:", response); // Debug: log the API response

            // Check if response.data is an array before proceeding
            if (Array.isArray(response.data)) {
                const apiEvents = response.data.map((event) => {
                    const date = parseDateString(event.date); // Parse the date correctly

                    // Set the event to span the whole day
                    const startOfDay = new Date(date.setHours(0, 0, 0, 0)); // Start at 00:00
                    const endOfDay = new Date(date.setHours(23, 59, 59, 999)); // End at 23:59
                    // console.log(startOfDay, "and", endOfDay);

                    return {
                        // title: "Event   ", // Customize the title as needed
                        start: startOfDay,
                        end: endOfDay,
                        eventIsThere: event.eventIsThere, // Include the eventIsThere flag

                    };
                });

                // console.log("apiEvents :", apiEvents);

                // Prevent duplicate events
                setEvents((prevEvents) => {
                    const existingEventTimes = new Set(
                        prevEvents.map((event) => event.start.getTime())
                    );

                    const newUniqueEvents = apiEvents.filter(
                        (event) => !existingEventTimes.has(event.start.getTime())
                    );

                    return [...prevEvents, ...newUniqueEvents];
                });
            } else {
                console.error("Expected an array but received:", typeof response.data);
            }
        } catch (error) {
            console.error("Error fetching events:", error);
        }
    };

    useEffect(() => {
        // console.log("Events:", events);
    }, [events]);


    // Customize event styles
    const eventStyleGetter = (event) => {
        // Determine if the event is on a Sunday
        const isSunday = getDay(event.start) === 0;

        // Apply different background colors based on whether the event is on a Sunday
        const backgroundColor = isSunday ? (event.eventIsThere ? '#5a022e' : '#a7a2a2') : (event.eventIsThere ? '#5a022e' : '#f4f4f4');

        const style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'black',
            border: '0px',
            display: 'block',
        };

        return {
            style: style,
        };
    };

    // Function to handle slot selection
    const handleSelectSlot = async (slotInfo) => {
        const headers = {
            Authorization: `Bearer ${TOKEN}`,
        };

        const selectedDate = slotInfo.start; // Get the selected date from the slotInfo
        // console.log("Selected date:", selectedDate);

        try {
            const response = await axios.get(
                `${API_END_POINT}consultationDateUserMapper/getConsultationCalendar?month=${getMonthYear().monthNumber}&year=${getMonthYear().year}`,
                { headers }
            );
            // console.log(response.data);

            let updatedEventIsThere = false; // Initialize the variable to store the updated state

            response.data.forEach((item) => {
                if (item?.date === format(selectedDate, "dd/MM/yyyy")) {
                    // console.log(item?.eventIsThere);
                    updatedEventIsThere = !item?.eventIsThere; // Toggle the event state
                }
            });

            // console.log("New event state (toggled):", updatedEventIsThere); // Correctly log the toggled state

            await axios.put(
                `${API_END_POINT}consultationDateUserMapper/edit-calendar`,
                {
                    date: format(selectedDate, "dd/MM/yyyy"), // Format the date as needed
                    eventIsThere: updatedEventIsThere, // Use the toggled value
                },
                { headers }
            );

            // Refresh events after updating
            fetchEvents();
        } catch (error) {
            console.error("Error updating calendar:", error);
        }
    };

    return (
        <div className="main">
            <div className="flex justify-between">
                <h3 className="heding">Consultation Calendar</h3>
                <div className="flex" style={{ gap: '25px' }}>
                    <div className="flex gap-5 align-center ">
                        <div className="true"></div>
                        <span >Availble Slots</span>
                    </div>
                    <div className="flex gap-5 align-center ">
                        <div className="false"></div>
                        <span>Unavilable Slots</span>
                    </div>
                </div>
            </div>
            {/* <h3 className="heding">
                {`Current View: Month: ${getMonthYear().monthName} (${getMonthYear().monthNumber}) | Year: ${getMonthYear().year}`}
            </h3> */}
            {/* <form className="flex flex-wrap align-center gap-5 form justify-between">
                <div className="flex gap-10">
                    <div className="input-field flex flex-col">
                        <label htmlFor="blogName">Add Title</label>
                        <input
                            type="text"
                            placeholder="Add Title"
                            value={newEvent.title}
                            onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
                        />
                    </div>
                    <div className="input-field flex flex-col">
                        <label htmlFor="blogName">Date</label>
                        <input
                            type="date"
                            placeholder="Date"
                            value={newEvent.date}
                            onChange={(e) => setNewEvent({ ...newEvent, date: e.target.value })}
                        />
                    </div>
                </div>
                <div className="buttons flex flex-col">
                    <button type="button" onClick={handleAddEvent} style={{ width: "100%" }}>
                        Add Event
                    </button>
                </div>
            </form> */}
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500, margin: "50px" }}
                eventPropGetter={eventStyleGetter}  // Apply custom styles
                views={['month', 'day']}
                selectable={true}
                onView={handleViewChange}
                onNavigate={handleNavigate}
                onSelectEvent={handleSelectSlot}
                onSelectSlot={handleSelectSlot}
            />
        </div>
    );
}

export default ConsultationCalendar;
