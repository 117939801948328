import React, { useState, useEffect, useRef } from 'react'
import "./GemstoneConfiguration.css"
import CommonTable from '../../components/Table/CommonTable'
import { gemstoneConfigurationSchema } from '../../schema/Index';
import { useFormik } from 'formik';
import axios from 'axios';
import { TOKEN, API_END_POINT } from '../../constant';
import Popup from 'reactjs-popup';
import { toast } from "react-toastify";
import Loader from '../../components/Loader/Loader';
import Confirmation from '../../components/ConfirmationPopup/Confirmation';

const GemstoneConfiguration = () => {
    const [initialValues, setInitialValues] = useState({
        dropdown: "",
        name: "",
        IndianName: "",
        size: "",
        price: "",
        discription: "",
        purpose: "",
        howToWear: "",
        symbolizes: "",
        image: "",
    })
    const [showForm, setShowForm] = useState(false);
    const [gemstoneList, setGemstoneList] = useState(null);
    const [gemstoneCategories, setGemstoneCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isConfirmationDelete, setIsConfirmationDelete] = useState(false);
    const [isConfirmationEdit, setIsConfirmationEdit] = useState(false);
    const [deleteContentId, setDeleteContentId] = useState(0)
    const [btnName, setBtnName] = useState("Add");
    const [confirmUpdateId, setConfirmUpdateId] = useState(0);
    const [isImagePreviewPopUpOpen, setIsImagePreviewPopUpOpen] = useState(false);
    const [previewImageUrl, setPreviewImageUrl] = useState("");
    const fileInputRef = useRef(null)
    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: gemstoneConfigurationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (btnName === "Add") {
                await createNewGemstone(values);
                setShowForm(false);
                resetForm();
            } else if (btnName === "Save") {
                setIsConfirmationEdit(true)
            }
        },
        enableReinitialize: true
    })

    const columns = [
        {
            name: 'Category Name',
            cell: (row) => row?.gemstoneCategory?.gemStoneTypeName,
        },
        {
            name: 'Name',
            cell: (row) => row?.gemstoneName,
        },
        {
            name: 'Indian Name',
            cell: (row) => row?.gemstoneIndianName,
        },
        {
            name: 'Size',
            cell: (row) => row?.gemstoneSize,
        },
        {
            name: 'Price',
            cell: (row) => row?.price,
        },
        {
            name: 'Discription',
            cell: (row) => row?.discription,
        },
        {
            name: 'Purpose',
            cell: (row) => row?.purpose,
        },
        {
            name: 'How To Wear',
            cell: (row) => row?.howToWear,
        },
        {
            name: 'Symbolizes',
            cell: (row) => row?.symbolizes,
        },
        {
            name: 'Image',
            cell: (row) => {
                // Check if fileAttachment is present
                const imageUrl = row?.fileAttachment?.fileLinkString;
                if (!imageUrl) {
                    return <span>No Image</span>; // Return a placeholder if there's no image
                }
                return (
                    <img
                        title='Click to see Image'
                        onClick={() => showImagePreviewPopUp(imageUrl)}
                        src={`${API_END_POINT}${imageUrl}`}
                        width={50}
                        height={50}
                        alt='gemstone'
                    />
                );
            },
        },
        {
            name: 'Actions',
            cell: (row) => (
                <div className='flex align-center gap-10'>
                    <button className='flex align-center edit-btn' onClick={() => updateGemstone(row.id)}>
                        <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m7 17.011 4.413-.015 9.632-9.54c.378-.378.586-.88.586-1.414 0-.534-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.581v4.43ZM18.045 4.456l1.589 1.583-1.597 1.582-1.586-1.585 1.594-1.58ZM9 13.416l6.03-5.974 1.586 1.586L10.587 15 9 15.004v-1.589Z"></path>
                            <path d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2Z"></path>
                        </svg>
                    </button>
                    <button className='flex align-center delete-btn-icon' onClick={() => deleteGemstone(row.id)}>
                        <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2v12ZM9 4h6v2H9V4ZM8 8h9v12H7V8h1Z"></path>
                            <path d="M9 10h2v8H9v-8Zm4 0h2v8h-2v-8Z"></path>
                        </svg>
                    </button>
                </div>
            ),
        },
    ];

    const addRow = () => {
        btnName === "Save" && setBtnName("Add");
        setShowForm(!showForm);
        setInitialValues({
            dropdown: "",
            name: "",
            IndianName: "",
            size: "",
            price: "",
            discription: "",
            purpose: "",
            howToWear: "",
            symbolizes: "",
            image: "",
        })
    };


    const uploadImage = async (id,) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${TOKEN}`);

        const formdata = new FormData();
        formdata.append("file", fileInputRef.current.files[0], fileInputRef.current.files[0].name);
        formdata.append("gemStoneId", id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        try {
            await fetch(`${API_END_POINT}gemstone/uploadFile`, requestOptions);
        } catch (error) {
            console.log(error)
        }

    }

    // Retrieves the list of gemstones.
    const getGemstoneList = async () => {
        try {
            setLoading(true);
            // Define request headers
            const headers = {
                Authorization: `Bearer ${TOKEN}`
            };

            // Define request parameters
            const params = {
                pageSize: 1,
                pageNo: 0
            };

            // Make GET request using Axios
            const response = await axios.get(`${API_END_POINT}gemstone/getAllGemstoneList`, {
                headers
            });

            setLoading(false)
            console.log("Gemstone data :", response?.data?.data)
            return response?.data?.data;
        } catch (error) {
            setGemstoneList([])
            setLoading(false)
        }
    };

    //Retrieves the list of gemstone categories. 
    const getGemstoneCategories = async () => {
        try {
            // Define request headers
            const headers = {
                Authorization: `Bearer ${TOKEN}`
            };

            // Make GET request using Axios
            const response = await axios.get(`${API_END_POINT}gemstoneCategory/getGemstoneCategories`, {
                headers
            });

            return response?.data?.data; // Log the response data
        } catch (error) {
            console.error(error); // Log any errors that occur
        }
    };

    // Creates a new gemstone.
    const createNewGemstone = async (values) => {
        let gemstoneCategoriesId = gemstoneCategories.find((data) => data?.gemStoneTypeName?.toLowerCase() === values?.dropdown?.toLowerCase())

        try {
            // Define request headers
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`
            };

            // Define request body
            const data = {
                actionInfo: {
                    actionId: "String"
                },
                formData: {
                    gemstoneName: values?.name,
                    gemstoneIndianName: values?.IndianName,
                    price: values?.price,
                    gemstoneSize: values?.size,
                    discription: values?.discription,
                    purpose: values?.purpose,
                    howToWear: values?.howToWear,
                    symbolizes: values?.symbolizes,
                    gemstoneCategoryId: gemstoneCategoriesId?.id
                },
                sessionData: {
                    userDetails: {
                        isAdmin: 0,
                        isAllAccess: 0,
                        langCode: "String",
                        userEmailId: "String",
                        userId: 0,
                        userName: "String"
                    }
                }
            };

            // Make POST request using Axios
            let response = await axios.post(`${API_END_POINT}gemstone/create`, data, {
                headers
            });

            fileInputRef.current.files[0] && uploadImage(response?.data?.data?.id);
            setGemstoneList(await getGemstoneList())
            toast.success("Gemstone Added Successfully!");
        } catch (error) {
            toast.error("Unable To Add Gemstone");
        }
    };

    // Deletes a gemstone by its ID.
    const deleteGemstone = async (id) => {
        setIsConfirmationDelete(true)
        setDeleteContentId(id);
    };

    // Updates a gemstone by its ID.
    const updateGemstone = async (id) => {
        setBtnName("Save");
        let previousData = gemstoneList.find((data) => data.id === id);
        setInitialValues({
            dropdown: previousData?.gemstoneCategory?.gemStoneTypeName,
            name: previousData?.gemstoneName,
            IndianName: previousData?.gemstoneIndianName,
            size: previousData?.gemstoneSize,
            price: previousData?.price,
            discription: previousData?.discription,
            purpose: previousData?.purpose,
            howToWear: previousData?.howToWear,
            symbolizes: previousData?.symbolizes,
            image: "",
        })
        setShowForm(true);
        setConfirmUpdateId(id);

    };

    // confirmupdate 
    const ConfirmUpdateGemstone = async (values) => {
        let gemstoneCategoriesId = gemstoneCategories.find((data) => data?.gemStoneTypeName?.toLowerCase() === values?.dropdown?.toLowerCase());
        try {
            // Define request headers
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`
            };

            // Define request body
            const data = {
                actionInfo: {
                    actionId: "String"
                },
                formData: {
                    gemstoneName: values?.name,
                    gemstoneIndianName: values?.IndianName,
                    price: values?.price,
                    gemstoneSize: values?.size,
                    discription: values?.discription,
                    purpose: values?.purpose,
                    howToWear: values?.howToWear,
                    symbolizes: values?.symbolizes,
                    gemstoneCategoryId: gemstoneCategoriesId?.id
                },
                sessionData: {
                    userDetails: {
                        isAdmin: 0,
                        isAllAccess: 0,
                        langCode: "String",
                        userEmailId: "String",
                        userId: 0,
                        userName: "String"
                    }
                }
            };

            // Make PUT request using Axios
            let response = await axios.put(`${API_END_POINT}gemstone/updateGemstone?gemStoneId=${confirmUpdateId}`, data, {
                headers
            });

            fileInputRef.current.files[0] && await uploadImage(response?.data?.data?.id);
            setShowForm(false)
            setGemstoneList(await getGemstoneList())
            toast.success("Gemstone Updated Successfully!");
        } catch (error) {
            toast.error("Unable To update Gemstone");
        }
    }

    // ----- this is for close confirm delete popup -----
    const closeConfirmDeletePopUp = () => {
        setIsConfirmationDelete(false)
    }

    // ----- this is for close confirm Edit popup -----
    const closeConfirmEditPopUp = () => {
        setIsConfirmationEdit(false)
    }

    // Function to handle confirmation of an action.
    const confirmActionDelete = async (data) => {
        try {
            // Define request headers
            const headers = {
                Authorization: `Bearer ${TOKEN}`
            };

            // Make DELETE request using Axios
            await axios.delete(`${API_END_POINT}gemstone/deleteGemstoneById?gemstoneId=${deleteContentId}`, {
                headers
            });
            setGemstoneList(await getGemstoneList())
            toast.success("Gemstone deleted Successfully!");
        } catch (error) {
            toast.error("Unable To delete Gemstone");
        }
    }

    // this function fro show image preview
    const showImagePreviewPopUp = (imageUrl) => {
        setPreviewImageUrl(`${API_END_POINT}${imageUrl}`);
        setIsImagePreviewPopUpOpen(true)
    }

    // this function for close image preview popup
    const closePopup = () => {
        setIsImagePreviewPopUpOpen(false);
    }

    useEffect(() => {
        async function fetchData() {
            setGemstoneList(await getGemstoneList())
            setGemstoneCategories(await getGemstoneCategories());
        }
        fetchData();

    }, [])

    return (
        <main className='main'>
            {gemstoneList && <section className='section'>
                <h3 className='heding'>Gemstone Configuration</h3>
                <div style={{ marginTop: "10px" }} className='flex align-center justify-end'>
                    <button onClick={addRow} className='common-btn flex align-center'>
                        {!showForm ? <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6v-2Z"></path>
                        </svg> :
                            <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242-1.414-1.414Z"></path>
                            </svg>}
                    </button>
                </div>
                {showForm && (
                    <form onSubmit={handleSubmit} className='flex flex-wrap aign-center gap-5 gemstoneConfigurationForm form'>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="dropdown">Gemstone Category</label>
                            <select name="dropdown"
                                id='dropdown'
                                value={values.dropdown}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }} className={`${(errors.dropdown && touched.dropdown) && 'error'}`}>
                                <option value="">Select category</option>
                                {gemstoneCategories?.map((data, index) => {
                                    return (
                                        <option value={data?.gemStoneTypeName} key={index}>{data?.gemStoneTypeName}</option>
                                    )
                                })}

                            </select>
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="name">Gemstone Name</label>
                            <input
                                id='name'
                                type="text"
                                name="name"
                                value={values.name}
                                onBlur={handleBlur}
                                onChange={(e) => {

                                    handleChange(e);
                                }}
                                placeholder="enter gemstone name"
                                className={`${(errors.name && touched.name) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="IndianName">Gemstone Indian Name</label>
                            <input
                                id='IndianName'
                                type="text"
                                name="IndianName"
                                value={values.IndianName}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                placeholder='enter gemstone indian name'
                                className={`${(errors.IndianName && touched.IndianName) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="size">Gemstone Size</label>
                            <input
                                id='size'
                                type="number"
                                name="size"
                                value={values.size}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                placeholder='enter gemstone size'
                                className={`${(errors.size && touched.size) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="price">Gemstone Price</label>
                            <input
                                id='price'
                                type="number"
                                name="price"
                                value={values.price}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                className={`${(errors.price && touched.price) && 'error'}`}
                                placeholder="enter gemstone price"
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="discription">Gemstone Discription</label>
                            <input
                                id='discription'
                                type="text"
                                name="discription"
                                value={values.discription}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                placeholder='enter gemstone discription'
                                className={`${(errors.discription && touched.discription) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="purpose">Gemstone Purpose</label>
                            <input
                                id='purpose'
                                type="text"
                                name="purpose"
                                value={values.purpose}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                placeholder='enter gemstone purpose'
                                className={`${(errors.purpose && touched.purpose) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="howToWear">how To Wear</label>
                            <input
                                id='howToWear'
                                type="text"
                                name="howToWear"
                                value={values.howToWear}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                placeholder='enter how to wear gemstone'
                                className={`${(errors.howToWear && touched.howToWear) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="symbolizes">Symbolizes</label>
                            <input
                                id='symbolizes'
                                type="text"
                                name="symbolizes"
                                value={values.symbolizes}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                placeholder='enter gemstone symbolizes'
                                className={`${(errors.symbolizes && touched.symbolizes) && 'error'}`}
                            />
                        </div>
                        <div className='input-field flex flex-col'>
                            <label htmlFor="image">Gemstone Image</label>
                            {/* Hidden file input */}
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => {
                                    setFieldValue("image", e?.target?.files[0]?.name);
                                }}
                                ref={fileInputRef}
                            />

                            <input
                                id='image'
                                type="text"
                                name="image"
                                value={values.image}
                                onClick={() => {
                                    fileInputRef.current.click();
                                }}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                placeholder='select Image'
                                readOnly
                                className={`${(errors.image && touched.image) && 'error'}`}
                            />

                        </div>
                        <div className='buttons flex flex-col' style={{ alignItems: "flex-end" }}>
                            <label htmlFor="btn" style={{ visibility: "hidden", marginBottom: "5px" }}>hidden</label>
                            <button type="submit" className='btn' id='btn' style={{ width: "100%" }}>{btnName}</button>
                        </div>
                    </form>
                )}
                <div style={{ marginTop: "10px" }}>
                    <CommonTable columns={columns} data={gemstoneList} />
                </div>
            </section>}

            {/* ----- confirmation Delete popup --- */}
            <Confirmation text={"Do you want to delete this Gemstone"} confirmAction={confirmActionDelete} closeConfirmPopUp={closeConfirmDeletePopUp} isConfirmationOpen={isConfirmationDelete} />

            {/* ----- confirmation Edit popup --- */}
            <Confirmation text={"Do you want to Save this Gemstone"} confirmAction={ConfirmUpdateGemstone} closeConfirmPopUp={closeConfirmEditPopUp} isConfirmationOpen={isConfirmationEdit} data={values} />

            {/* ------ popup for image preview --------- */}
            <Popup open={isImagePreviewPopUpOpen} onClose={closePopup} modal>
                <div>
                    <div className="flex align-center justify-between gap-5 image-preview-popup-header">
                        <span>Preview</span>
                        <svg onClick={closePopup} width="30" height="30" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242-1.414-1.414Z"></path>
                        </svg>
                    </div>
                    <div className="image-preview-popup-content">
                        <img src={previewImageUrl} alt="Gemstone" />
                    </div>
                </div>
            </Popup>

            {/* ------- loader here ------- */}
            {loading && <Loader />}
        </main>
    )
}

export default GemstoneConfiguration








