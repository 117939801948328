import React, { useEffect, useState } from 'react'
import CommonTable from '../../components/Table/CommonTable';
import { API_END_POINT, TOKEN } from '../../constant';
import axios from 'axios';
import Loader from '../../components/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import './Report.css'

function Report() {
    const [loading, setLoading] = useState(false);
    const [listUsers, setUsersList] = useState([]);
    const [subusers, setSubUsers] = useState([])
    const [hideTable, setHideTable] = useState(true)
    const navigate = useNavigate();
    const [report, setReport] = useState()
    const [openPopup, setOpenPopup] = useState(false)
    const [option, setOption] = useState(null)
    const [id, setId] = useState(null)
    const [subId, setSubId] = useState(null)
    const [totalRows, setTotalRows] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [subUserLength, setSubUserLength] = useState(null)
    const [reportLength, setReportLength] = useState(null)

    useEffect(() => {
        getList(currentPage);
    }, [currentPage])

    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const getList = async (page) => {
        try {
            setLoading(true);
            const headers = {
                Authorization: `Bearer ${TOKEN}`
            };
            // const response = await axios.get(`${API_END_POINT}reportSubscriptionMaster/allUsersWithSubUsers`, { headers });
            const response = await axios.get(`${API_END_POINT}reportSubscriptionMaster/allUsersSubUsersWithSearch?page=${page - 1}&size=10&search=`, { headers });
            setLoading(false)
            // console.log("Response through Report.jsx is :", response)
            console.log("Main List:", response?.data.content)
            setUsersList(response?.data.content)
            setTotalRows(response?.data.totalElements)
        } catch (error) {
            setLoading(false);
        }
    }

    //when click on subuser button in user table
    const handleIdClick = (prevUserId) => {
        console.log(listUsers)
        // console.log("wiwi", prevUserId)
        const selectedUser = listUsers.find((item) => item.userId === prevUserId);
        // console.log(selectedUser)
        if (selectedUser) {
            setSubUsers(selectedUser.subUsers);
            setSubUserLength(selectedUser.subUsers.length)
            console.log(selectedUser.subUsers.length)
        }
        // console.log("Subusers :", selectedUser.subUsers);
        setId(prevUserId)
        setHideTable(false)
        setOption(null)
    };

    //when click on subuser table row
    const handleSubidClick = (subid) => {
        console.log(id)
        // console.log(subusers)
        const reportSelectedUser = subusers.find((item) => item.subUserId === subid);
        // console.log(reportSelectedUser)
        setSubId(reportSelectedUser.subUserId)
        if (reportSelectedUser) {
            setReport(reportSelectedUser.reportList);
            setReportLength(reportSelectedUser.reportList.length)
            console.log(reportSelectedUser.reportList.length)
        }
        // console.log("Report :", report)
        setHideTable('report');
    }

    //when click on report button in user table
    const handleReportClick = (id) => {
        // console.log(listUsers)
        // console.log(id)
        setId(id)
        const reportUser = listUsers.find((item) => item.userId === id);
        // console.log(reportUser)
        if (reportUser) {
            setReport(reportUser.userReportList);
            setReportLength(reportUser.userReportList.length)
            console.log(reportUser.userReportList.length)
        }
        // console.log("Report User:", reportUser.userReportList)
        setHideTable('report2');
    }

    //User Table
    const columns = [
        {
            name: 'Sr. No.',
            cell: (row, index) => index + 1,
        },
        {
            name: "Name",
            cell: (row) => row?.userName
        },
        {
            name: "Mobile No.",
            cell: (row) => row?.userMobileNo
        },
        {
            name: "Email Id",
            cell: (row) => row?.userEmail
        },
        {
            name: "D.O.B.",
            cell: (row) => row?.userDateOfBirth
        },
        {
            name: "Place Of Birth",
            cell: (row) => row?.userBirthPlace
        },
        {
            name: "Gender",
            cell: (row) => row?.userGender
        },
        {
            name: "Options",
            cell: (row) => (
                <div className='btn'>
                    <button onClick={() => { handleIdClick(row.userId); }}>Subuser</button>
                    <button onClick={() => { handleReportClick(row.userId); }}>Report</button>
                </div>
            )
        },
    ];

    //subUser Table
    const columns2 = [
        {
            name: 'Sr. No.',
            cell: (row, index) => index + 1,
        },
        {
            name: "Name",
            cell: (row) => row?.name
        },
        {
            name: "Mobile No.",
            cell: (row) => row?.mobileNo
        },
        {
            name: "Email Id",
            cell: (row) => row?.email
        },
        {
            name: "D.O.B.",
            cell: (row) => row?.dateOfBirth
        },
        {
            name: "Place Of Birth",
            cell: (row) => row?.birthPlace
        },
        {
            name: "Gender",
            cell: (row) => row?.gender
        },
    ];

    //Report Table
    const columns3 = [
        {
            name: 'Sr. No.',
            cell: (row, index) => index + 1,
        },

        {
            name: "Report Name",
            cell: (row) => row?.reportName
        },
        {
            name: "Purchase",
            cell: (row) => {
                return (
                    < div className="button r" id="button-1" >
                        <input type="checkbox" className="checkbox" checked={row.reportPurchased}
                            onChange={() => (handleCheckboxChange(row))} />
                        <div className="knobs"></div>
                        <div className="layer"></div>
                    </div >
                )
            }
        }
    ];

    const handleCheckboxChange = async (value) => {
        // console.log(value)
        console.log(id)
        // console.log(value.reportPurchased)
        // console.log("Report item toggled:", value);
        const newAccessStatus = !value.reportPurchased; // Toggle the current status
        // console.log("New access status:", newAccessStatus);

        const data = {
            userId: id,
            subUserId: hideTable === 'report2' ? 0 : subId,
            packageId: value.reportId,
            access: newAccessStatus, // Correctly toggle the boolean
        }
        try {
            // console.log("Access updated successfully:", response);
            const updatedReport = report.map((item) =>
                item.reportId === value.reportId
                    ? { ...item, reportPurchased: newAccessStatus }
                    : item
            );
            setReport(updatedReport);
            const response = await axios.post(`${API_END_POINT}reportSubscriptionMaster/manageReportAccess`, data, {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${TOKEN}`
                }
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleBackClick = () => {
        setHideTable(true);
    };

    const handleBackClick2 = () => {
        setHideTable(false)
    }

    const handleBackClick3 = () => {
        setHideTable(true)
    }

    const SearchBar = async (e) => {
        console.log(e.target.value)
        try {
            const resp = await axios.get(`${API_END_POINT}reportSubscriptionMaster/allUsersSubUsersWithSearch?page=0&size=10&search=${e.target.value}`, {
                headers: {
                    'Authorization': `Bearer ${TOKEN}`
                }
            });
            console.log("Response from search bar is ", resp)
            setUsersList(resp?.data.content)
            // if (resp.data.size )
            // if (e.target.value !== "") {
            //     setUsersList(resp?.data.content)
            // }
            // else {
            //     // getList()
            //     setUsersList(resp?.data.content)
            // }
        }
        catch (error) {
            console.log(error)
        }
    }

    return (
        <main className='main'>
            <h3 className='heding' style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                {
                    hideTable == true ? 'User Table' : (
                        hideTable === false ? 'Subuser Table' : (
                            hideTable === 'report' ? 'Report Table' : 'Report Table'
                        )
                    )
                }
            </h3>

            <div id='search-div' style={{ marginBottom: '10px' }}>
                {
                    hideTable === true ? <form className='flex flex-wrap aign-center gap-5 magazinesConfigurationForm form'>
                        <div className='input-field flex ' style={{ alignItems: 'center', gap: '8px' }} >
                            <label htmlFor="searchName">Search Bar</label>
                            <input type="text" id='searchName' placeholder='Search Here' onChange={SearchBar} />
                        </div>
                    </form> : null
                }
                {
                    hideTable === true ? null :
                        (hideTable === false ?
                            <div className='flex align-center justify-end'>
                                <button className='common-btn flex align-center' onClick={handleBackClick}>
                                    <svg width="27" height="27" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="m15 18-6-6 6-6"></path>
                                    </svg>
                                </button>
                            </div> :
                            (hideTable !== 'report2' ?
                                <div className='flex align-center justify-end'>
                                    <button className='common-btn flex align-center' onClick={handleBackClick2}>
                                        <svg width="27" height="27" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m15 18-6-6 6-6"></path>
                                        </svg>
                                    </button>
                                </div> : <div className='flex align-center justify-end'>
                                    <button className='common-btn flex align-center' onClick={handleBackClick3}>
                                        <svg width="27" height="27" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m15 18-6-6 6-6"></path>
                                        </svg>
                                    </button>
                                </div>
                            )
                        )
                }
            </div>

            <CommonTable
                columns={hideTable === true ? columns : (hideTable === false ? columns2 : columns3)}
                data={hideTable === true ? listUsers : (hideTable === false ? subusers : report)}
                handleSubidClick={handleSubidClick}
                hideTable={hideTable}
                handlePageChange={handlePageChange}
                // totalRows={totalRows}
                totalRows={hideTable === true ? totalRows : (hideTable === false ? subUserLength : reportLength)}
                value={true}
            />

            {/* ------- loader here ------- */}
            {loading && <Loader />}
        </main>
    )
}

export default Report