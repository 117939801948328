import React, { useEffect, useRef, useState } from 'react';
import { TOKEN } from '../../constant';
import axios from 'axios';
import { API_END_POINT } from '../../constant';
import { useFormik } from 'formik';
import { poojaSchema } from '../../schema/Index'
import CommonTable from '../../components/Table/CommonTable';
import { toast } from 'react-toastify';
import Confirmation from '../../components/ConfirmationPopup/Confirmation';
import Popup from 'reactjs-popup';
import Loader from '../../components/Loader/Loader';

function PoojaList() {
    const [initialValues, setInitialValues] = useState({
        poojaName: "",
        poojaInfo: "",
        process: "",
        benefits: "",
        imageLink: "",
        poojaTime: "",
        price: "",
    });

    const [showForm, setShowForm] = useState(false);
    const [poojaList, setPoojaList] = useState(null);
    const [btnName, setBtnName] = useState("Add");
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);
    const [isConfirmationEdit, setIsConfirmationEdit] = useState(false);
    const [deleteContentId, setDeleteContentId] = useState(0)
    const [isConfirmationDelete, setIsConfirmationDelete] = useState(false);
    const [confirmUpdate, setConfirmUpdate] = useState(0);
    const [isInfoPreviewPopUpOpen, setIsInfoPreviewPopUpOpen] = useState(false);
    const [previewInfoUrl, setPreviewInfoUrl] = useState("");

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        validationSchema: poojaSchema,
        onSubmit: async (values, { resetForm }) => {
            console.log(btnName);
            console.log(values);
            if (btnName === "Save") {
                setIsConfirmationEdit(true)
            } else if (btnName === "Add") {
                await addNewPooja(values);
                setShowForm(false);
                resetForm();
            }
        },
        enableReinitialize: true,
    });

    // console.log(errors)

    const addRow = () => {
        if (btnName === "Save") {
            setBtnName("Add");
        }
        setInitialValues({
            poojaName: "",
            poojaInfo: "",
            process: "",
            benefits: "",
            imageLink: "",
            poojaTime: "",
            price: "",
        });
        setShowForm(!showForm);
    };

    const columns = [
        {
            name: 'Sr. No.',
            cell: (row, index) => index + 1,
        },
        {
            name: "Pooja Name",
            cell: (row) => row?.poojaName,
        },
        {
            name: "Pooja Info",
            cell: (row) => (
                <div>
                    {row?.poojaInfo?.slice(0, 100)}
                    {row?.poojaInfo?.length > 100 && (
                        <>
                            ... <button onClick={() => showInfoPreviewPopUp(row?.poojaInfo)} style={{ padding: '0px 3px' }}>Show More</button>
                        </>
                    )}
                </div>
            )
        },
        {
            name: "Pooja Image",
            cell: (row) => <img
                title='Click to see Image'
                src={`${API_END_POINT}${row?.imageLink}`}
                width={"auto"}
                height={"70"}
                alt="Blog Image"
            />
        },
        {
            name: "Pooja Price",
            cell: (row) => row?.planPrice,
        },
        {
            name: "Pooja Time (in hrs)",
            cell: (row) => `${row?.poojaTime}`,
        },
        {
            name: "Pooja Benefits",
            cell: (row) => (
                <div>
                    {row?.benefits?.slice(0, 100)}
                    {row?.poojaInfo?.length > 100 && (
                        <>
                            ... <button onClick={() => showInfoPreviewPopUp(row?.benefits)} style={{ padding: '0px 3px' }}>Show More</button>
                        </>
                    )}
                </div>
            )
        },
        {
            name: "Process",
            cell: (row) =>
            (<div>
                {row?.process?.slice(0, 100)}
                {row?.process?.length > 100 && (
                    <>
                        ... <button onClick={() => showInfoPreviewPopUp(row?.process)} style={{ padding: '0px 3px' }}>Show More</button>
                    </>
                )}
            </div>
            )

        },
        {
            name: 'Actions',
            cell: (row, index) => (
                <div className='flex align-center gap-10'>
                    <button className='flex align-center edit-btn' onClick={() => updatePooja(row.poojaId)}>
                        <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m7 17.011 4.413-.015 9.632-9.54c.378-.378.586-.88.586-1.414 0-.534-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.581v4.43ZM18.045 4.456l1.589 1.583-1.597 1.582-1.586-1.585 1.594-1.58ZM9 13.416l6.03-5.974 1.586 1.586L10.587 15 9 15.004v-1.589Z"></path>
                            <path d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2Z"></path>
                        </svg>
                    </button>
                    <button className='flex align-center delete-btn-icon' onClick={() => deletePooja(row.poojaId)}>
                        <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2v12ZM9 4h6v2H9V4ZM8 8h9v12H7V8h1Z"></path>
                            <path d="M9 10h2v8H9v-8Zm4 0h2v8h-2v-8Z"></path>
                        </svg>
                    </button>
                </div>
            ),
        },
    ];

    // this function fro show information preview
    const showInfoPreviewPopUp = (infoUrl) => {
        setPreviewInfoUrl(infoUrl);
        setIsInfoPreviewPopUpOpen(true)
    }

    // this function for close information preview popup
    const closePopup1 = () => {
        setIsInfoPreviewPopUpOpen(false);
    }

    //Retrieves the list of blogs.
    const getPoojaList = async () => {
        try {
            setLoading(true);
            // Define request headers
            const headers = {
                Authorization: `Bearer ${TOKEN}`
            };
            const response = await axios.get(`${API_END_POINT}pooja/list`, { headers });
            setLoading(false);
            console.log("Response through pooja.jsx is :", response)
            return response?.data.data;
        } catch (error) {
            setPoojaList([]);
            setLoading(false);
        }
    };

    // Adds a new pooja.
    const addNewPooja = async (info) => {
        try {
            // Define request headers
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`
            };

            // Make POST request using Axios
            let response = await axios.post(`${API_END_POINT}pooja/createPooja`, {
                "poojaDuration": info.poojaTime,
                "poojaCost": info.price,
                "poojaName": info.poojaName,
                "benefits": info.benefits,
                "process": info.process,
                "about": info.poojaInfo
            }, {
                headers
            });

            console.log("alalalall", response)
            fileInputRef.current.files[0] && await uploadPoojaFile(response?.data.data.id);
            setPoojaList(await getPoojaList());
            toast.success("Pooja Added Successfully!");
        } catch (error) {
            console.log(error);
            toast.error("Unable To Add Pooja");
        }
    };

    const uploadPoojaFile = async (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${TOKEN}`);

        const formdata = new FormData();
        console.log("Id in Pooja.jsx is", id);
        console.log("file in Pooja.jsx is ", fileInputRef.current.files[0]);
        formdata.append("file", fileInputRef.current.files[0], fileInputRef.current.files[0].name);
        formdata.append("poojaId", id);
        // formdata.append("gujaratiName", null);
        // formdata.append("hindiName", null);
        // formdata.append("marathiName", null);

        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        try {
            await fetch(`${API_END_POINT}pooja/updatePoojaBanner`, requestOptions);
        } catch (error) {
            console.log(error)  
        }
    }

    // Deletes a pooja by its ID.
    const deletePooja = async (id) => {
        console.log("jjjjjj", id);
        setIsConfirmationDelete(true);
        setDeleteContentId(id);
    };

    // Delete Action confirm popup
    const confirmActionDelete = async (data) => {
        console.log(deleteContentId)
        try {
            // Define request headers
            const headers = {
                Authorization: `Bearer ${TOKEN}`
            };

            // Make DELETE request using Axios
            await axios.delete(`${API_END_POINT}pooja/delete/${deleteContentId}`, {
                headers
            });
            setPoojaList(await getPoojaList())
            toast.success("Pooja Deleted successfully");

        } catch (error) {
            toast.error("Unable To Delete Pooja");
        }
    }

    // ----- this is for close confirm delete popup -----
    const closeConfirmDeletePopUp = () => {
        setIsConfirmationDelete(false)
    }


    const updatePooja = async (id) => {
        setBtnName("Save");
        // Find the magazine in the list based on its ID
        let previousData = poojaList.find((data) => data.poojaId === id);
        // Set initial form values for updating
        setInitialValues({
            poojaName: previousData.poojaName,
            poojaInfo: previousData.poojaInfo,
            process: previousData.process,
            benefits: previousData.benefits,
            imageLink: previousData.imageLink,
            poojaTime: previousData.poojaTime,
            price: previousData.planPrice,
        });
        // Show the form for editing
        setShowForm(true);
        // Set the ID of the item being updated
        setConfirmUpdate(id);
    };

    // ----- this is for close confirm Edit popup -----
    const closeConfirmEditPopUp = () => {
        setIsConfirmationEdit(false)
    }

    // confirmupdate 
    const ConfirmUpdatePooja = async (info) => {
        try {
            // Define request headers
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`
            };

            // Define request body
            const data = {
                "poojaDuration": info?.poojaTime,
                "poojaCost": info?.price,
                "poojaName": info?.poojaName,
                "benefits": info?.benefits,
                "process": info?.process,
                "about": info?.poojaInfo
            };

            // Make PUT request using Axios
            let response = await axios.put(`${API_END_POINT}pooja/updatePooja/${confirmUpdate}`, data, {
                headers
            });
            console.log("ConfirmUpdateBlog:", response?.data);
            fileInputRef.current.files[0] && uploadPoojaFile(response?.data.data.id);
            setShowForm(false);
            toast.success("Blog Updated successfully");
            setPoojaList(await getPoojaList());
            setPoojaList(await getPoojaList());
        } catch (error) {
            toast.error("Unable To Update Blog");
        }
    }

    useEffect(() => {
        async function fetchData() {
            setPoojaList(await getPoojaList());
        }
        fetchData();
    }, []);

    return (
        <main className='main'>
            {
                poojaList && <section>
                    <h3 className='heding'>Online Pooja</h3>
                    <div style={{ marginTop: "10px" }} className='flex align-center justify-end '>
                        <button onClick={addRow} className='common-btn flex align-center'>
                            {!showForm ? <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6v-2Z"></path>
                            </svg> :
                                <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242-1.414-1.414Z"></path>
                                </svg>}
                        </button>
                    </div>
                    {showForm && (
                        <form onSubmit={handleSubmit} className='flex flex-wrap aign-center gap-5 magazinesConfigurationForm form'>
                            <div className='input-field flex flex-col'>
                                <label htmlFor="poojaName">Pooja Name</label>
                                <input
                                    id='poojaName'
                                    type="text"
                                    name="poojaName"
                                    value={values.poojaName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="enter pooja name"
                                    className={`${(errors.poojaName && touched.poojaName) && 'error'}`}
                                />
                            </div>
                            <div className='input-field flex flex-col'>
                                <label htmlFor="poojaTime">Pooja Time</label>
                                <input
                                    id='poojaTime'
                                    type="text"
                                    name="poojaTime"
                                    value={values.poojaTime}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className={`${(errors.poojaTime && touched.poojaTime) && 'error'}`}
                                    placeholder="enter pooja time"
                                />
                            </div>
                            <div className='input-field flex flex-col'>
                                <label htmlFor="poojaInfo">Pooja Info</label>
                                <input
                                    id='poojaInfo'
                                    type="text"
                                    name="poojaInfo"
                                    value={values.poojaInfo}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className={`${(errors.poojaInfo && touched.poojaInfo) && 'error'}`}
                                    placeholder="enter pooja info"
                                />
                            </div>
                            <div className='input-field flex flex-col'>
                                <label htmlFor="process">Process</label>
                                <input
                                    id='process'
                                    type="text"
                                    name="process"
                                    value={values.process}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className={`${(errors.process && touched.process) && 'error'}`}
                                    placeholder="enter pooja process"
                                />
                            </div>
                            <div className='input-field flex flex-col'>
                                <label htmlFor="blogLink">Benefits</label>
                                <input
                                    id='benefits'
                                    type="text"
                                    name="benefits"
                                    value={values.benefits}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className={`${(errors.benefits && touched.benefits) && 'error'}`}
                                    placeholder="enter pooja benfits"
                                />
                            </div>
                            <div className='input-field flex flex-col'>
                                <label htmlFor="blogLink">Price</label>
                                <input
                                    id='price'
                                    type="text"
                                    name="price"
                                    value={values.price}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className={`${(errors.price && touched.price) && 'error'}`}
                                    placeholder="enter pooja price"
                                />
                            </div>
                            <div className='input-field flex flex-col'>
                                <label htmlFor="imageLink">Select Image</label>
                                {/* Hidden file input */}
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    ref={fileInputRef}
                                    onChange={(e) => {
                                        setFieldValue("imageLink", e?.target?.files[0]?.name);
                                    }}
                                />
                                <input
                                    id='imageLink'
                                    type="text"
                                    name="imageLink"
                                    value={values.imageLink}
                                    onClick={() => {
                                        fileInputRef.current.click();
                                    }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder='select Image'
                                    readOnly
                                    className={`${(errors.imageLink && touched.imageLink) && 'error'}`}
                                />
                            </div>
                            <div className='buttons flex flex-col' style={{ alignItems: "flex-end" }}>
                                <label htmlFor="btn" style={{ visibility: "hidden", marginBottom: "5px" }}>hidden</label>
                                <button type="submit" id='btn' className='btn' style={{ width: "100%" }}>{btnName}</button>
                            </div>
                        </form>
                    )}
                    <div style={{ marginTop: "10px" }}>
                        <CommonTable columns={columns} data={poojaList} />
                    </div>
                </section>}

            {/* ----- confirmation Delete popup --- */}
            <Confirmation text={"Do you want to delete this Pooja"} confirmAction={confirmActionDelete} closeConfirmPopUp={closeConfirmDeletePopUp} isConfirmationOpen={isConfirmationDelete} />

            {/* ----- confirmation Edit popup --- */}
            <Confirmation text={"Do you want to Save this Pooja"} confirmAction={ConfirmUpdatePooja} closeConfirmPopUp={closeConfirmEditPopUp} isConfirmationOpen={isConfirmationEdit} data={values} />

            <Popup open={isInfoPreviewPopUpOpen} onClose={closePopup1} modal>
                <div>
                    <div className="flex align-center justify-between gap-5 image-preview-popup-header">
                        <span>Information</span>
                        <svg onClick={closePopup1} width="30" height="30" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242-1.414-1.414Z"></path>
                        </svg>
                    </div>
                    <div className="image-preview-popup-content">
                        {previewInfoUrl}
                    </div>
                </div>
            </Popup>


            {/* ------- loader here ------- */}
            {loading && <Loader />}
        </main>
    );
}

export default PoojaList;
