import React, { useEffect, useRef, useState } from 'react';
import "./BulkNotification.css";
import { useFormik } from "formik";
import { messageSchema } from '../../schema/Index';
import { toast } from "react-toastify";
import { API_END_POINT, TOKEN } from '../../constant';
import axios from 'axios';

const BulkNotification = () => {
    const spinnerRef = useRef(null);
    // const [imageFile, setImageFile] = useState(null);
    const fileInputRef = useRef(null);
    const [moduleName, setModuleName] = useState([]);
    const [dropDownId, setDropDownId] = useState("");

    const initialValues = {
        title: "",
        body: "",
        file: ""
    };

    const uploadImage = async (file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post(`${API_END_POINT}fileAttachment/uploadImages`, formData, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (error) {
            throw new Error(`Error uploading image: ${error.message}`);
        }
    }

    const sendNotification = async (values, fileId) => {
        try {
            const response = await axios.post(`${API_END_POINT}send/notificationToAll`, {
                title: values.title,
                message: values.body,
                fileId: fileId,
                redirectionPageId: dropDownId
            }, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                },
            });

            return response.data;
        } catch (error) {
            throw new Error(`Error fetching data from API: ${error.message}`);
        }
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: messageSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                spinnerRef.current.style.display = "inline-block";

                let fileId = '';
                if (fileInputRef.current.files[0]) {
                    const imageResponse = await uploadImage(fileInputRef.current.files[0]);
                    fileId = imageResponse?.data?.fileId; // Adjust based on your API response
                    console.log('fileId:', fileId)
                }

                const response = await sendNotification(values, fileId);
                spinnerRef.current.style.display = "none";
                toast.success(response?.message);
                resetForm();
                // setImageFile(null);
            } catch (error) {
                spinnerRef.current.style.display = "none";
                toast.error("Unable To Send Notification.");
            }
        }
    });


    const getModuleName = () => {
        const headers = {
            "Authorization": `Bearer ${TOKEN}`,
        }

        axios.get(`${API_END_POINT}notification/get-redirection-point`, { headers })
            .then((response) => {
                setModuleName(response.data.data)
                // console.log("Response in getModuleName :", response)
            })
            .catch((error) => {
                console.error(error);
            });
    }


    function handleModuleChange(event) {
        // getPayments(event.target.value, currentPage)
        setDropDownId(event.target.value)
        console.log(event.target.value)
    }
    useEffect(() => {
        getModuleName()
    }, [])

    return (
        <main className='main'>
            <section className='section'>
                <h3 className='heding'>Bulk Notification</h3>
                <div className="form">
                    <form onSubmit={handleSubmit}>
                        <div className="input-field flex flex-col">
                            <label htmlFor="MessageTitle">Notification Title:</label>
                            <input type="text" id='MessageTitle' value={values.title} name='title' onChange={handleChange} onBlur={handleBlur} />
                        </div>
                        {errors.title && touched.title && (
                            <div className="error">{errors.title}</div>
                        )}
                        <div className="input-field flex flex-col">
                            <label htmlFor="MessageBody">Notification Body:</label>
                            <textarea id="MessageBody" value={values.body} name='body' onChange={handleChange} onBlur={handleBlur}></textarea>
                        </div>
                        {errors.body && touched.body && (
                            <div className="error">{errors.body}</div>
                        )}
                        <div className="input-field flex flex-col">
                            <label htmlFor="MessageImage">Upload Image:</label>
                            {/* <input
                                type="file"
                                id="MessageImage"
                                name="image"
                                accept="image/*"
                                onChange={handleImageChange}
                            /> */}
                            {/* Hidden file input */}
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                onChange={(e) => {
                                    setFieldValue("file", e?.target?.files[0]?.name);
                                    console.log("File :", e?.target?.files[0]?.name)
                                }}
                            />
                            <input
                                id='file'
                                type="text"
                                name="file"
                                value={values.file}
                                onClick={() => {
                                    fileInputRef.current.click();
                                }}
                                // onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder='select Image'
                                readOnly
                            />
                        </div>
                        {/* {errors.file && touched.file && (
                            <div className="error">{errors.file}</div>
                        )} */}

                        <div className='flex justify-between ' style={{ marginTop: '15px' }}>
                            <div className='input-field flex flex-col' style={{ maxWidth: "350px", width: "100%" }}>
                                <label htmlFor="dropdown">Select Notification Name</label>
                                <select name="dropdown" id='dropdown' value={dropDownId} onChange={handleModuleChange}>
                                    <option value="" disabled>Select a module</option>
                                    {
                                        moduleName.length > 0 ? moduleName.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.landingPage}
                                            </option>
                                        )) : <option>Loading...</option>
                                    }
                                </select>
                            </div>
                            <div className='buttons flex align-center gap-5'>
                                <button className='reset' type='reset' onClick={() => resetForm()}>Reset</button>
                                <button type='submit'>Send <span className="spinner" id="spinner" ref={spinnerRef}></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </main>
    )
}

export default BulkNotification;
