export const ShowWhenAPIFail = [
    {
      "customisedReports": [
        {
          "name": "medical Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "financial Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "match Making Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "ashtak Varg Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "varg Kundali Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "numerology Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "sadi Sati Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "daily Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "yearly Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "weekly Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "month Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "sun Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "mars Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "mercury Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "jupiter Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "venus Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "saturn Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "rahu Ketu Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "career Report",
          "total": "-",
          "today": "-"
        },
        {
          "name": "full Analysis",
          "total": "-",
          "today": "-"
        }
      ],
      "course": [
        {
          "name": "basic Astrology",
          "total": "-",
          "today": "-"
        },
        {
          "name": "numerology",
          "total": "-",
          "today": "-"
        },
        {
          "name": "vastu Course",
          "total": "-",
          "today": "-"
        },
        {
          "name": "medical Astrology",
          "total": "-",
          "today": "-"
        },
        {
          "name": "basic Astrophyisics",
          "total": "-",
          "today": "-"
        },
        {
          "name": "advanced Astrology",
          "total": "-",
          "today": "-"
        },
        {
          "name": "basic Astronomy",
          "total": "-",
          "today": "-"
        },
        {
          "name": "palmistry",
          "total": "-",
          "today": "-"
        }
      ],
      "doubtSession": [
        {
          "name": "doubt Session",
          "total": "-",
          "today": "-"
        }
      ],
      "magzine": [
        {
          "name": "navratri Vishesh",
          "total": "-",
          "today": "-"
        },
        {
          "name": "navratri Special",
          "total": "-",
          "today": "-"
        },
        {
          "name": "jyotish Vigyan",
          "total": "-",
          "today": "-"
        },
        {
          "name": "makar Sankranti",
          "total": "-",
          "today": "-"
        }
      ],
      "gemstone": [
        {
          "name": "gemstone",
          "total": "-",
          "today": "-"
        }
      ],
    }
];

// --------- sectionNames here ------
export const sectionName = ["customised Reports","courses Enrolled","doubt Session Booking summary","magazines","gemstone","publication"];

// -------- gemstonePurchasedList ------
export const gemstonePurchasedList = {
  "data": [
    {
      "id": 1,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 684,
        "userName": "9607496849",
        "email": null,
        "mobile": "9607496849",
        "address": "dsgh"
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 2,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 684,
        "userName": "9607496849",
        "email": null,
        "mobile": "9607496849",
        "address": "dsgh"
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 3,
      "gemstone": {
        "id": null,
        "gemstoneName": null
      },
      "user": {
        "userId": 684,
        "userName": "9607496849",
        "email": null,
        "mobile": "9607496849",
        "address": "dsgh"
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 4,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 684,
        "userName": "9607496849",
        "email": null,
        "mobile": "9607496849",
        "address": "dsgh"
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 5,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 671,
        "userName": "7066604568",
        "email": "Devendra012@gmail.comf",
        "mobile": "7066604568",
        "address": "dsgh"
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 6,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 671,
        "userName": "7066604568",
        "email": "Devendra012@gmail.comf",
        "mobile": "7066604568",
        "address": "dsgh"
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 7,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 671,
        "userName": "7066604568",
        "email": "Devendra012@gmail.comf",
        "mobile": "7066604568",
        "address": "dsgh"
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 8,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 671,
        "userName": "7066604568",
        "email": "Devendra012@gmail.comf",
        "mobile": "7066604568",
        "address": "dsgh"
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 9,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 671,
        "userName": "7066604568",
        "email": "Devendra012@gmail.comf",
        "mobile": "7066604568",
        "address": "dsgh"
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 10,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 671,
        "userName": "7066604568",
        "email": "Devendra012@gmail.comf",
        "mobile": "7066604568",
        "address": "Uffhdhbsbbd"
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 11,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 684,
        "userName": "9607496849",
        "email": null,
        "mobile": "9607496849",
        "address": "Fwgsgsg"
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 12,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 684,
        "userName": "9607496849",
        "email": null,
        "mobile": "9607496849",
        "address": "Twga"
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 13,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 684,
        "userName": "9607496849",
        "email": null,
        "mobile": "9607496849",
        "address": "dsgh"
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 14,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 684,
        "userName": "9607496849",
        "email": null,
        "mobile": "9607496849",
        "address": "Govind nagar"
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 15,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 684,
        "userName": "9607496849",
        "email": null,
        "mobile": "9607496849",
        "address": ""
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 16,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 684,
        "userName": "9607496849",
        "email": null,
        "mobile": "9607496849",
        "address": ""
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 17,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 684,
        "userName": "9607496849",
        "email": null,
        "mobile": "9607496849",
        "address": "Govind nagar "
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 18,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 684,
        "userName": "9607496849",
        "email": null,
        "mobile": "9607496849",
        "address": "dsgh"
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 19,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 684,
        "userName": "9607496849",
        "email": null,
        "mobile": "9607496849",
        "address": "dsgh"
      },
      "paymentDetailsId": 123,
      "orderDate": null
    },
    {
      "id": 20,
      "gemstone": {
        "id": 1,
        "gemstoneName": "Yellow Sapphire"
      },
      "user": {
        "userId": 684,
        "userName": "9607496849",
        "email": null,
        "mobile": "9607496849",
        "address": "dsgh"
      },
      "paymentDetailsId": 123,
      "orderDate": null
    }
  ],
  "response": {
    "type": "SUCCESS",
    "responseCode": "200",
    "responseMessage": "GemstonePurchased  List"
  },
  "errors": {
    "userMessage": null,
    "internalMessage": null,
    "code": null
  },
};

// ----------- doubt session booked list --------
export const coursesDoubtSessionBookedList = [
  {
    "userId": 704,
    "userName": "8862093230",
    "email": null,
    "mobile": "8862093230"
  },
  {
    "userId": 704,
    "userName": "8862093230",
    "email": null,
    "mobile": "8862093230"
  },
  {
    "userId": 671,
    "userName": "7066604568",
    "email": "Devendra012@gmail.comf",
    "mobile": "7066604568"
  }
]